import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'process-card',
  templateUrl: './process-card.component.html',
  styleUrls: ['./process-card.component.css']
})
export class ProcessCardComponent implements ICellRendererAngularComp {

  constructor( private _router:Router, private _activatedRoute: ActivatedRoute ) {
    //
  }

  //Stores the card label
  public label: string;

  //Stores the process id to redirect to
  public processId: number;

  //Stores the process tags
  public tags: string[] = [];

  //Stores the url name (id)
  public currentUrlId: string = '';

  //Stores the header width
  public width: number = 0;


  ngOnInit(): void {
    //
  }

  /**
   * Ag-grid onInit
   * @param params 
   */
  agInit(params: any): void {
    this.label = params.label;
    this.processId = params.processId;

    try {
      this.tags = JSON.parse( params.tags );
    } catch (error) {
      this.tags = [];
    }
  }

  /**
   * Ag-grid refresh
   * @param params 
   * @returns 
   */
  refresh(params: any): boolean {
    return false;
  }

  /**
   * This function toggles the visibility of a modal, as well as toggles the body class of the document.
   */
  public openSection() {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `dashboard/general-results/${ processName }/${ this.processId }` ] );
  }

}
