import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralFormComponent } from './general-form.component';
import { FormsModule } from '@angular/forms';



@NgModule( {
  declarations: [
    GeneralFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    GeneralFormComponent,
  ]

} )
export class GeneralFormModule { }
