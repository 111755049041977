import { Component, ElementRef, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ProcessNameEnum } from 'src/app/models/selfAssessment/enums/processEnum';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { SwitchComponent } from '../shared/switch/switch.component';
import * as XLSX from 'xlsx';
import { XlsxService } from 'src/app/services/xlsx.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'directiva-general-results',
  templateUrl: './general-results.component.html',
  styleUrls: [ './general-results.component.css' ]
} )
export class GeneralResultsComponent implements OnInit {

  constructor (
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _xlsxService: XlsxService,
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService
  ) { }

  @ViewChild( 'tableInfo' ) tableInfo!: ElementRef;

  @ViewChildren( 'modalSwitch' ) modalSwitch: QueryList<SwitchComponent>;

  //Stores the current intcall
  public intCall: IntcallI = {} as IntcallI;

  //Stores the section list
  public sectionList: any[] = [];

  //Stores the filtered answer list
  public answerList: any[] = [];

  //Stores the filtered question list as columns
  public columnList: any[] = [];

  public hideData: any[] = [];

  public currentSectionName: string = '';

  //Toggles the settings modal visibility
  public showSettingsModal: boolean = false;

  //Gets the url names enum
  private _processName: any = ProcessNameEnum;

  private _hideData: any[] = [];

  private _defaultColumns: any[] = [
    { header: 'Puntaje', field: 'score' },
    { header: 'Fecha de inicio', field: 'startDate' },
    { header: 'Última modificación', field: 'updatedAt' }
  ];

  //Stores the intcallFields (questions)
  private _intcallFieldList: any[] = [];

  //Stores the userIntcallAnswer (users)
  private _userIntcallAnswer: any[] = [];

  //Stores the userIntcallFieldAnswers (answers)
  private _userIntcallFieldAnswers: any[] = [];

  //Stores the drag start position
  private _dragStart: number = 0;

  //Stores the end drag position
  private _dragEnd: number = 0;

  //Checks if it is dragging
  private _isDragging = false;

  ngOnInit(): void {
    this._selfAssessmentAlertService.swalLoading( 'Cargando...', 'Estamos cargando el contenido, por favor, espera un momento.' );

    const processName = this._activatedRoute.snapshot.params.process;
    if ( this._processName[ processName ] === undefined ) {
      this._router.navigate( [ '/landing' ] );
      return;
    }

    forkJoin( {
      intcall: this.getCurrentIntcall(),
      intcallModules: this.getIntcallModules(),
      userIntcallAnswer: this.getUserIntcallAnswer()
    } ).subscribe( {
      next: ( { intcall, userIntcallAnswer, intcallModules }: any ) => {
        this.intCall = intcall[ 0 ];

        //Gets all users
        this._userIntcallAnswer = userIntcallAnswer
          .filter( ( { Userapp }: any ) => Userapp ) //It is no necessary but just in case 
          .map( ( { id, value, updatedAt, createdAt, Userapp }: any ) => ( {
            intcallAnswerID: id,
            Userapp,
            value,
            updatedAt,
            createdAt
          } ) );

        //Gets all answers
        userIntcallAnswer.forEach( ( { UserIntcallFieldAnswers }: any ) => {
          if ( UserIntcallFieldAnswers )
            this._userIntcallFieldAnswers.push( ...UserIntcallFieldAnswers );
        } )

        //Gets all questions
        intcallModules.forEach( ( { intcallFields } ) => {
          if ( intcallFields )
            this._intcallFieldList.push( ...intcallFields );
        } );

        //Gets all sections
        this.sectionList = intcallModules.map( ( { id, nameModule }: any ) => ( { moduleID: id, nameModule } ) );

        if ( userIntcallAnswer && intcallModules && this.sectionList.length > 0 ) {
          this.onSelectSection( this.sectionList[ 0 ].moduleID );
          this._selfAssessmentAlertService.swalClose();
        } else {
          this._selfAssessmentAlertService.swalWarning( '',
            'Lo sentimos, no encontramos información para mostrar en esta sección. Por favor, vuelve a intentarlo más tarde.', 
            () => {
              const processName = this._activatedRoute.snapshot.params.process;
              this._router.navigate( [ '/dashboard', processName ] );
            } );
        }
      },
      error: ( err ) => {
        this.onError();
        console.log( err )
      }
    } )
  }


  /**
   * Filters and retrieves the questions and answers for the selected section using a more efficient algorithm for filtering.
   *
   * @param  moduleID - The ID of the selected section.
   * @returns void
   */
  public onSelectSection( moduleID: any ) {
    this.currentSectionName = this.sectionList.find( e => e.moduleID === moduleID ).nameModule;

    //Filter only the questions for the selected section
    const questions = this._intcallFieldList.filter( e => e.moduleID === Number( moduleID ) );

    //Filter only the answers for the selected section
    const answers = this._userIntcallFieldAnswers.filter( answer => questions.some( ( field: any ) => field.id === answer.intcallFieldID ) );

    let result: any[] = [];

    this._userIntcallAnswer.forEach( intcallAnswer => {
      let userAnswers = { intcallAnswer, answers: [] }
      answers.forEach( fieldAnswers => {
        questions.forEach( ( question: any ) => {
          if ( fieldAnswers.intcallFieldID === question.id )
            Object.assign( fieldAnswers, { columnField: question.nameField.split( ' ' ).join( '' ) } );
        } )

        if ( intcallAnswer.intcallAnswerID === fieldAnswers.UserIntcallAnswerID )
          userAnswers.answers.push( fieldAnswers );
      } );

      result.push( userAnswers );
    } );

    //Sets the columns and answers
    const columns = questions.map( e => ( {
      header: e.nameField,
      field: e.nameField.split( ' ' ).join( '' )
    } ) );

    this.answerList = result;
    this.columnList = this._defaultColumns.concat( ...columns );
  }


  private getCurrentIntcall() {
    const processId = this._activatedRoute.snapshot.params.id;
    const filter: string = JSON.stringify( {
      where: { id: processId }
    } );


    return this._selfAssessmentService.getIntcalls( filter );
  }

  private getIntcallModules() {
    const processId = this._activatedRoute.snapshot.params.id;
    const filter: string = JSON.stringify( {
      where: { intcallID: processId },
      include: [ 'intcallFields' ]
    } );

    return this._selfAssessmentService.getIntcallModules( filter );
  }

  private getUserIntcallAnswer() {
    const processId = this._activatedRoute.snapshot.params.id;
    const filter: string = JSON.stringify( {
      where: { intcallID: processId },
      include: [ { UserIntcallFieldAnswers: 'UserIntcallFieldDocumentss' }, 'Userapp' ]
    } );

    return this._selfAssessmentService.getUserIntCallAnswers( filter );
  }


  public toggleData( field: string ) {
    const index = this._hideData.indexOf( field );
    if ( index !== -1 ) {
      this._hideData.splice( index, 1 );
    } else {
      this._hideData.push( field );
    }
  }

  public applySettings() {
    this.showSettingsModal = false;
    this.hideData = [ ...this._hideData ];
  }

  public resetSettings() {
    this.showSettingsModal = false;

    this._hideData = [];
    this.hideData = [];

    for ( const item of this.modalSwitch ) {
      if ( !item.checkboxValue )
        item.checkboxValue = true;
    }
  }

  /**
   * Redirects to the previous page
   */
  public goBack(): void {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `/dashboard/${ processName }` ] );
  }

  /**
   * Redirect to edit process page
   */
  public editProcess(): void {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `/dashboard/edit-process/${ processName }/${ this.intCall.id }` ] );
  }

  /**
   * Exports an excel file
   */
  public downloadExcel() {
    this._selfAssessmentAlertService.swalLoading( '', 'Esto puede tardar un momento, por favor, espera' );

    const data = this.answerList.map( e => {
      const answersObj = e.answers.map( answer => {
        const header = this.columnList.find( col => col.field === answer.columnField ).header;
        const value = answer.UserIntcallFieldDocumentss.length === 0
          ? answer.value
          : environment.fileBaseUrl + answer.UserIntcallFieldDocumentss[ 0 ].documentLink;

        return { [ header ]: value };
      } );

      return Object.assign( {
        'Usuario': `${ e.intcallAnswer.Userapp.Name1 } ${ e.intcallAnswer.Userapp.LastName1 }`,
        'Puntaje': e.intcallAnswer.value,
        'Fecha de inicio': e.intcallAnswer.createdAt,
        'Última modificación': e.intcallAnswer.updatedAt,
      }, ...answersObj );
    } );

    this._xlsxService.exportToExcel( data, 'Lista de usuarios' );

    this._selfAssessmentAlertService.swalClose();
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    const processName: string = this._activatedRoute.snapshot.params.process;
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
        () => this._router.navigate( [ `dashboard/${ processName }` ] ) );
  }

  /**
   * Adds the 'table-info__col--grab' CSS class and removes the 'table-info__col--grabbing' class to the 'tableInfo' native element
   */
  public onMouseOver() {
    if ( !this._isDragging ) {
      this.tableInfo.nativeElement.classList.add( 'table-info__col--grab' );
      this.tableInfo.nativeElement.classList.remove( 'table-info__col--grabbing' );
    }
  }

  /**
   * Initializes the dragging behavior and updates the 'tableInfo' native element's CSS classes
   * @param event - The mouse event that triggered the function call
   */
  public onMouseDown( event: MouseEvent ) {
    this._dragStart = event.clientX;
    this._isDragging = true;
    this.tableInfo.nativeElement.classList.add( 'table-info__col--grabbing' );
    this.tableInfo.nativeElement.classList.remove( 'table-info__col--grab' );
  }

  /**
   * Ends the dragging behavior and updates the 'tableInfo' native element's CSS classes
   */
  public onMouseUp() {
    this._isDragging = false;
    this.tableInfo.nativeElement.classList.remove( 'table-info__col--grabbing' );
    this.tableInfo.nativeElement.classList.add( 'table-info__col--grab' );
  }

  /**
   * Implements the horizontal scrolling behavior while dragging and updates the 'tableInfo' native element's scroll position
   * @param event - The mouse event that triggered the function call
   */
  public onMouseMove( event: MouseEvent ) {
    if ( this._isDragging ) {
      this._dragEnd = event.clientX;
      const delta = this._dragEnd - this._dragStart;
      this.tableInfo.nativeElement.scrollLeft -= delta;
      this._dragStart = this._dragEnd;

      this.tableInfo.nativeElement.classList.add( 'table-info__col--grabbing' );
    }
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }
}
