import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;

  constructor() { }

  public UrlAPI: string = environment.baseUrl;

  getToken() {
    let token = localStorage.getItem('accessToken');
    this.token = null;
    if (token) {
      this.token = token;
    }
    return this.token;
  }

  getCurrentUserID(): string | null {
    let userIDString = localStorage.getItem( "currentUser" );
    return userIDString;
  }

}
