import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSearchComponent } from './user-search.component';
import { FormsModule } from '@angular/forms';



@NgModule( {
  declarations: [
    UserSearchComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ], exports: [
    UserSearchComponent
  ]
} )
export class UserSearchModule { }
