import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyBarSurveyModule } from '../shared/sticky-bar-survey/sticky-bar-survey.module';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { EvaluateComponent } from './evaluate.component';



@NgModule({
  declarations: [
    EvaluateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    StickyBarSurveyModule,
    AgGridModule
  ], exports: [
    EvaluateComponent,
    StickyBarSurveyModule
  ]
})
export class EvaluateModule { }
