export enum enumTypeOfView {
  diligenciar,
  calificar,

  evaluando,
  calificando
}

export enum enumTypeGeneralView {
  formulario,
  tabla
}
