import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DirectivasService } from 'src/app/services/directivas.service';
import { SharedService } from 'src/app/services/shared.service';

import { Router } from '@angular/router';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Modality } from 'src/app/models/modality';
import { Category } from 'src/app/models/category';
import { CourseOffer } from 'src/app/models/courseOffer';
import { TypesCourse } from 'src/app/models/typesCourse';
import { forkJoin } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { Force } from 'src/app/models/force';
import { Range } from 'src/app/models/range';
import { OFERSTATES, TypeOferState } from 'src/app/models/oferState';
import { Departament } from 'src/app/models/department';
import { TypeTraining } from 'src/app/models/typeTraining';
import { XlsxService } from 'src/app/services/xlsx.service';
import { environment } from 'src/environments/environment';
moment.locale('es');

@Component({
  selector: 'details-course-offer-component',
  templateUrl: './detailsCourseOfer.component.html',
  styleUrls: ['./detailsCourseOfer.component.sass']
})
export class DetailsCourseOferComponent implements OnInit {
  @Input() public course: CourseOffer;
  @Input() public userProfiles: any
  @Output() outputEvent = new EventEmitter();  // { typeEvent:  edit|closeWindow|  , data: Object      }

  public myInfo: any;

  public isCedoc: boolean = false
  public enableEdit: boolean = false
  public showForms: boolean = false
  public stateWindow = "MOSTRANDO DETALLES"

  public isActiva: boolean = true;
  public isTraining: boolean = false;
  public place: Departament;

  public categories: Category[] = [new Category()];
  public typeCourses: TypesCourse[] = [new TypesCourse()];
  public typeCoursesAll: TypesCourse[] = [new TypesCourse()];
  public modalities: Modality[] = [new Modality()];
  public ranges: Range[] = [new Range()];
  public forces: Force[] = [new Force()];
  public deptos: Departament[];
  public typeTrainings: TypeTraining[];

  public categoriesSelected: Category[] = [];
  public typeCoursesSelected: TypesCourse[] = [];
  public modalitiesSelected: Modality[] = [];
  public rangesSelected: Range[] = [];
  public forcesSelected: Force[] = [];

  public scrollTop: number = 0;
  
  public temporalItemText: any = "CMS1092";
  public temporalItemNumber: any = 1234;


  public SCHOOL_DYNAMIC_LOGO:any = (id:number)=> environment.SCHOOL_DYNAMIC_LOGO(id);

  constructor(
    public sharedService: SharedService,
    private courseService: CourseService,
    private _directivasService: DirectivasService,
    private _router: Router,
    private _xlsxService: XlsxService,
  ) {
    this.myInfo = this.userProfiles
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.getRoles();
    this.getFixedFilters();

    console.log(this.course)

  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  scrollEvent = (event: any): void => {
    const n = event.srcElement.scrollingElement.scrollTop;
    if (n > 380 && !this.showForms)
      this.scrollTop = n - 380
  }

  getRoles() {
    let filter = `{"include":["role"]}`
    this._directivasService.getMyRoleMappings(filter).pipe(take(1)).subscribe(rolemapp => {
      rolemapp.forEach(role => {
        if (role.role.name == "Diedu") {
          this.isCedoc = true;
        }
        if (role.role.name == "Admin" || role.role.name == "Directiva") {
          this.enableEdit = true
        }
      });
    });
  }

  public getFixedFilters() {

    const filterRange = { where: { ForceID: 1 } }

    forkJoin([
      this.courseService.getModalities(),
      this.courseService.getForces(),
      this.courseService.getRanges(JSON.stringify(filterRange)),
      this.courseService.getDepartments(),
      this.courseService.getTypeTrainings()
    ]).subscribe(Val => {
      [this.modalities, this.forces, this.ranges, this.deptos, this.typeTrainings] = Val;
    });
  }

  closeWindow() {
    this.outputEvent.emit({ typeEvent: 'closeWindow' })
  }

  onPlaceSelected() {
    this.course.DepartamentID = this.place.id;
    this.course.Altitud = this.place.Latitude;
    this.course.Longitud = this.place.Longitude;
  }

  getModalityByID(id): Modality {
    return this.modalities.find(x => x.id == id)
  }
  getTypeTrainByID(id):any{
    return this.typeTrainings.find(x => x.id == id)
  }

  getDepartmentById(id):any{
    return this.deptos.find(x => x.id == id)
  }

  gotoHistorical(courseID: number) {
    this._router.navigate(['/dashboard/matriz/' + courseID], { state: { enableToLoad: true } });
  }

  activeOffer(state){
    this.course.IsActive=state; 
    this.updateData()
  }

  aprobeOffer(state){
    if(state)
    {
      this.course.OferState=TypeOferState.aprobado;
      this.course.oferState = OFERSTATES[TypeOferState.aprobado]; 
      this.course.IsActive=true;
    }
    else{
      this.course.OferState=TypeOferState.cancelado;
      this.course.oferState = OFERSTATES[TypeOferState.cancelado]; 
      this.course.IsActive=false;
    }
    this.updateData()
  }

  updateOffer(f){

    if( this.isCedoc && (!f.Since?.pristine || !f.Until?.pristine))
    {
      this.course.OferState = TypeOferState.reprogramado //REprogramado
      this.course.oferState = OFERSTATES[TypeOferState.reprogramado]; 
    }
    else if( !this.isCedoc && this.course.OferState!=TypeOferState.pendiente ){ 
      if(!f.RealSince?.pristine || !f.RealUntil?.pristine)
      {
        this.course.OferState = TypeOferState.atraso //en atraso
        this.course.oferState = OFERSTATES[TypeOferState.atraso]; 
      }
    }

    if (!f.ranges?.pristine)
      this.course.Profiles = this.rangesSelected.map(x => x.Sigla);
    if (!f.forces?.pristine)
      this.course.Forces = this.forcesSelected.map(x => x.Sigla);


    this.updateData()
  }


  private updateData() {
    
    this.sharedService.swalLoading("Actualizando datos")
    this._directivasService.updateCourseOfer(this.course).pipe(take(1)).subscribe(data => {

      this.outputEvent.emit({ typeEvent: 'edit', data: this.course })
      this.showForms = false;
      this.sharedService.sleep(1000).then(() => {
        this.sharedService.swalSuccess("Actualizado", "Programa Actualizado")
      })

    }, err => {
      this.sharedService.sleep(1000).then(() => {
        this.sharedService.swalError("Error", "Ocurrio un error al actualizar programa")
      })
    })

  }

  deleteOffer(){
    if(this.isCedoc)
    {
        Swal.fire({
        title: 'Eliminar',
        text: 'Desea eliminar la oferta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          
          this._directivasService.deleteCourseOfer(this.course.id).pipe(take(1)).subscribe(data => {
            this.showForms = false;
            this.sharedService.swalSuccess("Eliminado", "Oferta Eliminada")
            this.closeWindow()
          }, err => {
            this.sharedService.sleep(1000).then(() => {
              this.sharedService.swalError("Error", "Ocurrio un error al eliminar oferta")
            })
          })

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // );
        }
      }); 
    }
  }

  public exportStudentList(){
    const filter = '{"include":[{"militarInfo":"Range"},{"userapp":"UserDocuments"}]}'
    this.courseService.getStudentListByCoursesOfers(this.course.id,filter).subscribe(data=>{
      const studentList = data.map(d=>{
        let studenInfo = {
          Rango: (d.militarInfo?.Range?.Sigla) ? d.militarInfo.Range.Sigla : "",
          Nombres: d.userapp.Name1 + " " + d.userapp.Name2,
          Apellidos: d.userapp.LastName1 + " " + d.userapp.LastName2,
          CedocEmail: d.userapp.CedocEmail,
          Documento: (d.userapp?.UserDocuments?.[0]?.Document) ? d.userapp.UserDocuments[0].Document : '',
          IsDocument: d.IsDocument,
          IsPaied: d.IsPaied,
          IsAcepted: d.IsAcepted,
          GPA: d.GPA
        }
        return studenInfo
      })
      this._xlsxService.exportRawToFile('Estudiantes.xlsx', null, studentList);
    })
 
    
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFront(urlComplement: number ): string {
    let url = `${environment.appFrontUrl}/admin/newcoursemallas/${urlComplement}`;
    return url;
  }

}
