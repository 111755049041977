import { Component, OnInit } from '@angular/core';
import { CourseOffer } from 'src/app/models/courseOffer';
import { DirectivasService } from 'src/app/services/directivas.service';
import { distinct, map, switchMap, take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { School } from 'src/app/models/school';
import { Category } from 'src/app/models/category';

import { Course } from 'src/app/models/course';

import { Modality } from 'src/app/models/modality';
import { Range } from 'src/app/models/range';
import { Force } from 'src/app/models/force';
import { TypesCourse } from 'src/app/models/typesCourse';
import { forkJoin, Subscription } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { OFERSTATES, TypeOferState } from 'src/app/models/oferState';
import { Departament } from 'src/app/models/department';
import { TypeTraining } from 'src/app/models/typeTraining';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'payment-order',
  templateUrl: './paymentOrderHistory.component.html',
  styleUrls: [
    './paymentOrderHistory.component.css',
    './paymentOrderHistory.component.sass',
  ],
} )
export class PaymentOrderHistoryComponent implements OnInit {
  public orderList: any[] = [];

  public isLoading: boolean = true;

  constructor ( private _courseService: CourseService, private _router: Router, private _sharedService: SharedService ) { }

  ngOnInit(): void {
    this._sharedService.sharedyearSchoolFilter.pipe( distinct() ).subscribe( {
      next: ( data ) => {
        const tempSchools: any[] = data.schools;
        if ( tempSchools.length === 0 ) return;
        this.getSubtypeRequests( tempSchools );
      },
      error: ( err ) => console.log( err )
    } )
  }

  getSubtypeRequests( tempSchools: any[] ) {
    const filter = JSON.stringify( {
      include: [
        'subTypeReqSchool',
        'subTypeReqTypeRequest',
        'subTypeReqTypeCourse',
        'subTypeReqCourse',
        'subTypeReqCourseOffer',
        'subTypeReqUser',
      ],
    } );

    this._courseService.getSubtypeRequests( filter ).subscribe( {
      next: ( resp ) => {
        const schoolIds = tempSchools.map( s => s.id );
        this.orderList = resp.filter( e => schoolIds.includes( e.schoolID ) );
        this.isLoading = false;
      },
      error: ( err ) => console.log( err ),
    } );
  }

  editItem( id: number ) {
    this._router.navigate( [ `/dashboard/paymentOrder/edit/${ id }` ], {
      state: { enableToLoad: true },
    } );
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }
}
