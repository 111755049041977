import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessActionsComponent } from './process-actions.component';




@NgModule( {
  declarations: [
    ProcessActionsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ProcessActionsComponent
  ]
} )
export class ProcessActionsModule { }
