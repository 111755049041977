import { Course } from './course';
import { OferState, TypeOferState } from './oferState';
import { RCourseOfeUpdate } from './r-course-ofe-update';
import { School } from './school';
import { TypesCourse } from './typesCourse';
import { UserCourse } from './userCourse';

export class CourseOffer {
    public id:number;
    public NameCourseOfer:string;
    public CourseID:number;
    public IsActive: boolean;
    public Since: Date;
    public Until: Date;
    public RealSince:Date;
    public RealUntil: Date;
    public SchoolID:number;
    public Price:number;
    public TypeCourseID:number;
    public IsSemestralize: boolean;
    public DepartamentID: number;
    public TypeTrainingID:string;
    public Altitud:number;
    public Longitud: number;
    public InscriptStart:Date;
    public InscriptEnd:Date;
    public SemesterNumber:string;
    public ModalityID: number;
    public TotalPeople: number;
    public OferState:TypeOferState;
    public Profiles:Array<any>;
    public Forces:Array<any>;
    public ForeignPeople: number;
    public IsDirectiva: boolean;z

    public EditSince?:boolean;
    public EditUntil?:boolean;
    public EditRealUntil?:boolean;
    public EditRealSince?:boolean;



    // Usado en agunos formularios para hacer calculos automaticos
    public NumberWeeks?:number;
    public NumberDays?:number;

    // INCLUDES ENPOINT
    public course?: Course;
    public school?: School;
    public UserCourses?: UserCourse[];
    public TypeCourseOFer?: TypesCourse;
    public oferState?: OferState;
    public rCourseOfeUpdates?: RCourseOfeUpdate[];


    // Para graficas
    public totalMatriculados?:number;
    public totalInscritos?:number;
    public totalGraduados?:number;
  userapp: any;

}
