import { AfterViewInit, Component, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ProcessIdEnum, ProcessNameEnum } from 'src/app/models/selfAssessment/enums/processEnum';
import { SectionNameEnum, SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { GeneralFormComponent } from '../shared/general-form/general-form.component';

@Component( {
  selector: 'edit-process-component',
  templateUrl: './edit-process.component.html',
  styleUrls: [ './edit-process.component.css' ]
} )
export class EditProcessComponent implements OnInit, AfterViewInit {

  constructor (
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService,
  ) { }


  //Stores the general section data
  public getGeneralData: any[] = [];

  //Stores the files section data
  public getFilesData: any[] = [];

  //Stores the files to delete from database
  public getDbFilesToDelete: any[] = [];

  //Stores the main form data
  public getMainFormData: any = {};

  //It is used to store the reference to a specific section.
  public currentSection: SectionToShowEnum = SectionToShowEnum.publishSection;

  //Gets the SectionToShow enum data
  public sectionToShow: any = SectionToShowEnum;

  //Stores the type section id
  public typeSectionId: number = 0;

  //Stores the current url name
  public processName: string = '...';

  //Stores the current section name
  public sectionName: string = '...';

  //If true, the main form validations will be activated
  public checkMainFormErrors: boolean = false;

  //Stores the current user info
  public currentUserInfo: any = {};

  //Stores the data of the current intcall to be modified
  public intcallToEdit:any = {};

  //Gets the url names enum
  private _processName: any = ProcessNameEnum;

  //Gets the section names enum
  private _sectionNameEnum: any = SectionNameEnum;

  //Gets the url ids enum
  private _processId: any = ProcessIdEnum;

  ngOnInit(): void {
    this._selfAssessmentAlertService.
      swalLoading( 'Cargando...', 'Estamos cargando el contenido, por favor, espera un momento.' );

    const processName = this._activatedRoute.snapshot.params.process;
    this.processName = this._processName[ processName ]; //sets the url name

    if ( this.processName === undefined ) {
      this._router.navigate( [ '/landing' ] );
      return;
    }

    this.sectionName = this._sectionNameEnum[ processName ]; //sets the section name
    this.typeSectionId = Number( this._processId[ processName ] );

    forkJoin( {
      currentUser: this._selfAssessmentService.getCurrentUser(),
      intcall: this.getIntcalls()
    } ).subscribe( {
      next: ( { currentUser, intcall  }: any ) => {
        this.intcallToEdit = {
          dateEnd: intcall[0].dateEnd || '',
          dateStart: intcall[0].dateStart || '',
          judgeIntcalls: intcall[0].judgeIntcalls || [],
        };
        
        this.currentUserInfo = currentUser;

        this.getMainFormData = {
          nameIntCall: intcall[0].nameIntCall,
          descript: intcall[0].descript,
          tags: JSON.parse( intcall[0].tags ).toString(),
        } 

        this._selfAssessmentAlertService.swalClose();
      },
      error: ( err ) => {
        this.onError();
        console.log( err );
      }
    } )
  }

  private getIntcalls() {
    const intcallID = this._activatedRoute.snapshot.params.id;
    const filter: string = JSON.stringify( {
      where: { id: Number( intcallID ) },
      include: [ { judgeIntcalls: 'Userapp'} ]
    } );

    return this._selfAssessmentService.getIntcalls( filter );
  }

  ngAfterViewInit(): void {
    //
  }

  /**
   * Redirects to the dashboard
   */
  public goBack() {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `/dashboard/${ processName }` ] );
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    const processName: string = this._activatedRoute.snapshot.params.process;
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
      () => this._router.navigate( [ `dashboard/${ processName }` ] ) );
  }
}