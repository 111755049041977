import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CommentsIntcallFieldAnswer } from 'src/app/models/selfAssessment/commentsIntcallFieldAnswer';
import { GradeIntcallFieldAnswer } from 'src/app/models/selfAssessment/gradeIntcallFieldAnswer';
import { IntcallFieldsI } from 'src/app/models/selfAssessment/intCallFields';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import { UserIntcallFieldAnswer } from 'src/app/models/selfAssessment/userIntcallFieldAnswer';
import { UserIntcallFieldDocuments } from 'src/app/models/selfAssessment/userIntcallFieldDocuments';
import { AuthService } from 'src/app/services/auth.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'se-question-form-section',
  templateUrl: './se-question-form-section.component.html',
  styleUrls: ['./se-question-form-section.component.css']
})
export class SeQuestionFormSectionComponent implements OnInit {

  @Input() intcfields: IntcallFieldsI;
  @Input() myUserIntcallAnswer: UserIntcallAnswer;
  @Input() allTypesOfView;
  @Input() typeOfView;
  @Input() loguedUserID: number;
  @Input() disabledEdit: boolean;

  showComments: boolean = false;
  myUserIntcallFieldAnswer: UserIntcallFieldAnswer;
  myGrade: GradeIntcallFieldAnswer;
  evaluadorID: number;
  containerCertsName: string = environment.containerCertsName;

  private subscriptionSavePartialData: Subscription
  private subscriptionSavePartialDataEvaluador: Subscription

  constructor(
    public _selfAssessmentService: SelfAssessmentService,
    public _authService: AuthService,
  ) {
    let localUserID = this._authService.getCurrentUserID();
    this.evaluadorID = +localUserID;
    this.subscriptionSavePartialDataMethod();
  }

  ngOnInit(): void {
    this.findResponseData();
  }
  ngOnDestroy() {
    this.subscriptionSavePartialData.unsubscribe();
    this.subscriptionSavePartialDataEvaluador.unsubscribe();
  }
  
  findResponseData(): void {
    this.myUserIntcallAnswer?.UserIntcallFieldAnswers?.forEach(element => {
      if (element.intcallFieldID == this.intcfields.id) {
        this.myUserIntcallFieldAnswer = element;
        this.myUserIntcallFieldAnswer.GradeIntcallFieldAnswers.forEach(gradesData => {
          if (gradesData.userID == this.loguedUserID) {
            this.myGrade = gradesData;
            this.calificacionIngresada = this.myGrade.grade;
          }
        });
      }
    });
  }

  isBtnVisible(typeOfView: number): boolean {
    return this.typeOfView == typeOfView;
  }

  patchUserIntcallFieldAnswer(): void {
    this._selfAssessmentService.patchUserIntcallFieldAnswer(this.myUserIntcallFieldAnswer).pipe(take(1)).subscribe(
      data => {
        console.log('patchUserIntcallFieldAnswer:',data);
      }, err => {
        console.log('patchUserIntcallFieldAnswer err:',err);
      }
    )
  }

  public commentString = '';
  postCommentsIntcallFieldAnswers(): void {
    if (this.commentString.trim() == '') return
    let data: CommentsIntcallFieldAnswer = {
      userIntCallFieldAnswerID: this.myUserIntcallFieldAnswer.id,
      userID: this.loguedUserID,
      comment: this.commentString,
      createdAt: new Date(),
      updatedAt: new Date()
    }
    this._selfAssessmentService.postCommentsIntcallFieldAnswers(data).pipe(take(1)).subscribe(
      data => {
        this.commentString = '';
        this.getCommentsIntcallFieldAnswersByID(data.id);
        console.log('postCommentsIntcallFieldAnswers:',data);
      }, err => {
        console.log('postCommentsIntcallFieldAnswers err:',err);
      }
    )
  }
  getCommentsIntcallFieldAnswersByID(id: number): void {
    this._selfAssessmentService.getCommentsIntcallFieldAnswersByID(id).pipe(take(1)).subscribe(
      data => {
        this.myUserIntcallFieldAnswer.CommentsIntcallFieldAnswers.push(data);
        console.log('getCommentsIntcallFieldAnswersByID:',data);
      }, err => {
        console.log('getCommentsIntcallFieldAnswersByID err:',err);
      }
    )
  }

  subscriptionSavePartialDataMethod():void {
    this.subscriptionSavePartialData = this._selfAssessmentService.sharedSaveEvent.subscribe(data => {
      if (data) this.patchUserIntcallFieldAnswer();
    });
    this.subscriptionSavePartialDataEvaluador = this._selfAssessmentService.sharedSaveEvaluador.subscribe(data => {
      if (data) this.patchGradeIncallFieldAnswer();
    });
  }

  calificacionIngresada: number = 0;
  patchGradeIncallFieldAnswer(): void {
    if (this.calificacionIngresada < 0) return
    let data: GradeIntcallFieldAnswer = {
      userIntCallFieldAnswerID2: this.myUserIntcallFieldAnswer.id,
      userID: this.loguedUserID,
      grade: this.calificacionIngresada,
      createdAt: new Date(),
      updatedAt: new Date()
    }
    if (this.myGrade?.id) data['id'] = this.myGrade.id;
    this._selfAssessmentService.patchGradeIntcallFieldAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('patchGradeIncallFieldAnswer:',data);
      }, err => {
        console.log('patchGradeIncallFieldAnswer err:',err);
      }
    )
  }
  public fileUrl: string = '';
  public nameFile: string = '';
  public descript: string = '';
  onFileInput( event: any ) {
    this.fileUrl = event.urlFile;
    this.nameFile = event.nameFile;
    this.descript = event.descript;
    this.getDataFromUploadFileComponent();
  }

  public fileReqTemp: UserIntcallFieldDocuments;
  getDataFromUploadFileComponent(): void {
    let data = {
      intcallID: this.myUserIntcallAnswer.intcallID,
      UserIntcallFieldAnswerID: this.myUserIntcallFieldAnswer.id,
      documentLink: this.fileUrl,
      name: this.nameFile,
      description: this.descript,
    }
    this.createUserIntcallFieldDocuments(data);
  }
  createUserIntcallFieldDocuments(data: object): void {
    this._selfAssessmentService.postUserIntcallFieldDocuments(data).pipe(take(1)).subscribe(
      data => {
        console.log('createUserIntcallFieldDocuments success', data);
        this.myUserIntcallFieldAnswer.UserIntcallFieldDocumentss.push(data)
      },
      err => {
        console.log('createUserIntcallFieldDocuments fail', err);
      }
    );
  }

  test() {
    console.log(this.myUserIntcallFieldAnswer);
    
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }

}
