import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class RoleManagerService {

  constructor( private _httpService: HttpService  ) { 
    //
  }

  public getCurrentUser() {
    const filter = JSON.stringify( {
      include: [ 'roleMappings', 'roles' ]
    } );
    return this._httpService.httpGet( `/Userapps/me?filter=${ filter }` );
  }

  public getUsers(filter?:string) {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/Userapps${ newFilter }` );
  }

  public getAllRoles() {
    return this._httpService.httpGet( '/Roles' );
  }

  public getRoleMappings( filter?:string ) {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/roleMappings${ newFilter }` );
  }

  public deleteRoleMappings( roleMappingId?:string ) {
    return this._httpService.httpDelete( `/roleMappings/${ roleMappingId }` );
  }

  public newRoleMappings( data:any ){
    return this._httpService.httpPost( '/roleMappings', data );
  }

  public patchUserDocuments(id:number, data:any ){
    return this._httpService.httpPatch( `/UserDocuments/${ id }`, data );
  }
}
