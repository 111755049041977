import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, IDoesFilterPassParams, AgPromise } from 'ag-grid-community';

@Component( {
  selector: 'directiva-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: [ './state-select.component.css' ]
} )
export class StateSelectComponent implements IFilterAngularComp {

  //stores information about available filters
  public filters: any[] = [
    {
      title: 'En proceso',
      color: 'yellow',
      value: 'ongoing',
    },
    {
      title: 'Terminado',
      color: 'green',
      value: 'ended'
    },
    {
      title: 'Programado',
      color: 'gray',
      value: 'programmed'
    }
  ];

  //represents the filter parameters
  private _params: IFilterParams = undefined;

  //represents the filter selected by the user
  private _selectedFilter: string = 'all';

  constructor () {
    //
  }

  /**
   * ag2 init function
   * @param params 
   */
  agInit( params: IFilterParams<any> ): void {
    this._params = params;
  }

  /**
   * ag2 FilterActive function
   * @param params 
   */
  isFilterActive(): boolean {
    return this._selectedFilter !== 'all';
  }

  /**
   * ag2 FilterActive function
   * @param params 
   */
  doesFilterPass( params: IDoesFilterPassParams<any> ): boolean {
    return params.data.state == this._selectedFilter;
  }

  getModel() {
    //
  }

  setModel( model: any ): void | AgPromise<void> {
    //
  }

  /**
   * is invoked when the user selects an item from the filter list. The function receives a value representing the selected item.
   * @param value 
   * @returns 
   */
  public onSelectedItem( value ) {
    if ( value === this._selectedFilter )
      return;

    this._selectedFilter = value;
    this._params.api.refreshHeader();//hides popup
    this._params.filterChangedCallback();//updates the table rows
  }
}
