
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { concat, of } from 'rxjs';
import { catchError, switchMap, tap, toArray } from 'rxjs/operators';
import { DOCUMENTS } from 'src/app/constants/payments';
import { PaymentOrderService } from 'src/app/services/payment-order.service';
import { XlsxService } from 'src/app/services/xlsx.service';
import { swalClose, swalError, swalLoading, swalSuccess, swalUpdateLoading } from 'src/utils/alerts';

@Component({
  selector: "upload-celic-file",
  templateUrl: "./upload-celic-file.component.html",
  styleUrls: ["./upload-celic-file.component.css"],
})
export class UploadCelicFileComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;
  @Output("onSubmit") onSubmit: EventEmitter<void> = new EventEmitter();
  @Input("loadingReceipts") loadingReceipts: boolean = false;
  @Input("itemsPerPage") itemsPerPage: number = 0;
  @Input("schools") schools: any[] = [];
  @Input("currentUser") currentUser: any = undefined;

  private _data: any[]  = [];
  get data(): any[] {
    return this._data;
  }

  public previewData: any[] = [];
  public previewTableHeader: string[] = [];
  public currentPage: number = 0;

  private _defaultFields = [
    "UsrIdType",
    "UsrId",
    "UsrFullName",
    "InvKey",
    "InvoiceValue",
    "InvDiscount",
    "InvPayment",
    "InvRefund",
    "InvTax",
    "InvPeriodMonth",
    "InvDueDate1",
    "InvDueDate2",
    "InvPrefix",
    "InvNumber",
    "StdId",
    "SFullName",
    "SrvName"
  ];

  public fileName = "";
  public fileToUpload?: File;
  public fileHasErrors: boolean = false;
  public uploadFileText: string = "Elija el archivo";
  public showTablePreview: boolean = false;
  public pageErrors: any[] = [];
  public previewTableTotalPages: number = 0;
  private _groupedData: { [key: string]: any[] } = {};

  constructor(
    private _xlsxService: XlsxService,
    private _paymentOrder: PaymentOrderService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  ngOnInit(): void { }

  onFileInput(event: any) {
    this.fileToUpload = <File>event.target.files[0];
    if (this.fileToUpload.size > 5000000) {
      this.uploadFileText = "Error de Archivo";
      this.fileHasErrors = true;
      return;
    }

    this.uploadFileText = this.fileToUpload.name;
    this.fileHasErrors = false;
    this.fileReader(event);
  }

  public fileReader(event: any) {
    const reader: FileReader = new FileReader();

    swalLoading({
      title: "Cargando archivo",
      message: "Esto puede tomar unos minutos.",
    });
    setTimeout(() => {
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const rawData = this._xlsxService.importFromFileInBatches(bstr);
        this.processFile(rawData);
      };

      reader.readAsBinaryString(event.target.files[0]);
    }, 1000);
  }

  /**
   * Create batches
   * @param rawData
   */
  private processFile(rawData: any[]) {
    //Create batches
    this.previewTableHeader = rawData[0].filter(
      (header) => header.trim() !== ""
    );

    //Validate headers
    const isHeaderValid: boolean =
      this._defaultFields.length === this.previewTableHeader.length &&
        this._defaultFields.every((field, i) => field === this.previewTableHeader[i]);

    if (!isHeaderValid) {
      const missingFields = this._defaultFields.map((e) => `"${e}"`).join("<br>");
      swalError({
        html: `La cabecera del documento parece incorrecta. Verifica el siguiente orden de campos:<br><br> ${missingFields}`,
        confirmCallback: () => this.resetFileInfo(),
      });
      return;
    }

    //Filters rows containing more than 8 non-empty cells
    rawData = rawData.filter(
      (row: any[]) =>
        row.filter((cell) => cell.toString().trim() !== "").length >= 8
    );
    rawData.shift(); //Delete first column

    this.pageErrors = [];

    this._groupedData = {};

    for (let i = 0; i < rawData.length; i++) {
      const pos = i + 1;
      const data = rawData[i];
      const invKey = data[3]; 
      const invNumber = data[13];
      const key = `${invKey}_${invNumber}`;

      if(!this._groupedData[key])
        this._groupedData[key] = [];

      //Validate excel fields

      //TODO: Algunas campos vienen en null string
      const validatedFields = {
        UsrIdType:  this.validateFields("documentType", data[0], "UsrIdType"),
        UsrId: this.validateFields("string", data[1], "UsrId"),
        UsrFullName: this.validateFields("string", data[2], "UsrFullName"),
        InvKey: this.validateFields("number", data[3], "InvKey"),
        InvoiceValue: this.validateFields("number", data[4], "InvoiceValue"),
        InvDiscount: this.validateFields("number", data[5], "InvDiscount"),
        InvPayment: this.validateFields("number", data[6], "InvPayment"),
        InvRefund: this.validateFields("number", data[7], "InvRefund"),
        InvTax: this.validateFields("number", data[8], "InvTax"),
        InvPeriodMonth: this.validateFields("number", data[9], "InvPeriodMonth"),
        InvDueDate1: this.validateFields("date", data[10], "InvDueDate1"),
        InvDueDate2: this.validateFields("date", data[11], "InvDueDate2"),
        InvPrefix: this.validateFields("string", data[12], "InvPrefix"),
        InvNumber: this.validateFields("number", data[13], "InvNumber"),
        StdId: this.validateFields("string", data[14], "StdId"),
        SFullName: this.validateFields("string", data[15], "SFullName"),
        SrvName: this.validateFields("string", data[16], "SrvName"),
      };

      let hasError = false;
      const errorColumns = {};

      Object.keys(validatedFields).forEach((key) => {
        if (validatedFields[key] && validatedFields[key].error) {
          hasError = true;
          if (!errorColumns[pos])
            errorColumns[pos] = [];

          const defaultFieldKey = this._defaultFields.find(
            (e) => e === validatedFields[key].colName
          );

          if (defaultFieldKey) 
            errorColumns[pos].push(defaultFieldKey);
        }
      });

      if (hasError)
        this.pageErrors.push({ row: pos + 1, columns: errorColumns[pos] });

      this._groupedData[key].push(validatedFields);
    }

    const flattenedRows = ([] as any[]).concat(...Object.values(this._groupedData));
    
    this._data = flattenedRows;
    this.previewTableTotalPages = this._data.length;
    this.previewData = flattenedRows.slice(0, this.itemsPerPage);

    swalClose();
    this.showTablePreview = true;
  }

  /**
   * Searches whether or not the records in the table are already in the database.
   */
  public submitReceipt() {
    if (this._data.length === 0 || this.loadingReceipts) return;

    swalLoading({
      title: "Cargando datos",
      message: "Tiempo estimado: 0 minutos",
    });
    this.loadingReceipts = true;

    //Shows table with errors
    if (this.pageErrors.length > 0 && this.pageErrors.length <= 10) {
      const content = `
        <p style="margin-bottom: 10px !important; color:#514d6a; ">Tienes errores en las siguientes columnas</p>

        <table style="width:100%;">
          <thead>
            <tr style="color:#514d6a;">
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Fila</th>
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Columnas</th>
            </tr>
          </thead>
          <tbody style="color:#514d6a;">
            ${this.pageErrors
          .map(
            (e, i) => `
            <tr style="${i % 2 === 0 ? "background-color: #1e438c0f;" : ""}">
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.row
              }</td>
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.columns.join(
                ", "
              )}</td>
            </tr>`
          )
          .join("")}
          </tbody>
        </table>`;

      swalError({
        title: "¡Error!",
        html: content,
      });

      this.loadingReceipts = false;
      return;
    }

    //Download txt errors file
    if (this.pageErrors.length > 10) {
      const content = `
        <p style="margin-bottom: 10px !important; color:#514d6a; ">Tienes múltiples errores. Para visualizarlos, por favor descarga el archivo adjunto.</p>
        <a href="#" style="color: #0190FE" id="downloadErrors">Descargar errores ↘</a>
        `;
      swalError({
        title: "¡Error!",
        html: content,
      });

      this.document.getElementById("downloadErrors").onclick = () =>
        this.downloadErrors();
      this.loadingReceipts = false;
      return;
    }

    const receipts = this._data.map((e, i) => {
      const concepts:any[] = this._groupedData[`${e.InvKey}_${e.InvNumber}`];

      const { InvoiceValue, InvDiscount, InvPayment } = concepts.reduce((acc, obj) => {
        acc.InvoiceValue += obj.InvoiceValue;
        acc.InvDiscount += obj.InvDiscount;
        acc.InvPayment += obj.InvPayment;
        return acc;
      }, { InvoiceValue: 0, InvDiscount: 0, InvPayment: 0 });
      
      const TotalPayment = InvoiceValue - InvDiscount - InvPayment //TODO: -invoicePayment;

      const uniqueConceptsSet = new Set<string>();
      concepts.forEach(obj => {
        uniqueConceptsSet.add(obj.SrvName);
      });

      //TODO: LIMIT TO 2000
      //PROBLEMA CON LAS FECHAS, FORMATO ERRONEO,
      // EL ID ES MUY GRANDE
      const result = {
        id:/*  `${e.InvPrefix} ${e.InvNumber} - ${e.InvKey}` */   (new Date().valueOf() + i + 1).toString(),
        DueDate: new Date(e.InvDueDate1).toISOString(), //TODO:
        DueDate2:  new Date(e.InvDueDate2).toISOString(), //TODO:
        Code: 1,
        documentType: e.UsrIdType,
        document: e.UsrId,
        Telephone: '0000000',  //TODO:

        FirstName: e.UsrFullName,
        lasName: 'N/A',
        TotalPayment,
        Description: Array.from(uniqueConceptsSet).join(' - '),
        extraData: JSON.stringify( e ),
        UserId: this.currentUser.id,
        lastEmail: 'N/A',  //TODO:
        fileAdminName: this.fileName,
        AssetsAndServicesCatalog: 'N/A', //TODO:
        paidTo: JSON.stringify(concepts.map(e => e.StdId)),
        massiveConcepts: concepts, //TODO: TEST
        PaymentState: false,
      }

      return this._paymentOrder.createOrUpdateReceipt(result).pipe(
        catchError((error) => {
          console.log(error);
          return of([]);
        })
      );
    });

    const startTime = performance.now();
    let completedRequests = 0;

    concat(...receipts)
      .pipe(
        tap(() => {
          completedRequests++;
          const remainingTime = this.calculateWaitTime(
            receipts.length,
            completedRequests,
            startTime
          );
          swalUpdateLoading({
            title: "Cargando datos",
            message: `Tiempo estimado: ${remainingTime} minutos`,
          });
        }),
        toArray(),
        switchMap(() => {
          const emails = this._data.map((e) => e.firsEmail);
          return this.sendNotification(emails);
        })
      )
      .subscribe({
        complete: () => {
          this.loadingReceipts = false;
          swalSuccess({ message: "Archivo cargado con éxito" });
          this.resetFileInfo();
          this.onSubmit.emit();
        },
        error: (err) => {
          this.loadingReceipts = false;
          swalError({
            message: `Error: ${err.message ||
              "Error interno del servidor, por favor comuníquese con un administrador"
              }`,
          });
          console.log(err);
        },
      });
  }

  /**
   * reset the file upload button
   */
  public resetFileInfo() {
    this.showTablePreview = false;
    this.previewData = [];
    this.fileInput.nativeElement.value = "";
    this.uploadFileText = "Elija el archivo";
    this.fileToUpload = undefined;
    this.fileName = "";
  }

  //Validate fields
  private validateFields(valueType: string, value: any, colName: string) {
    if (value === null || value === undefined || value === "")
      return { colName, error: "Por favor, completa este campo, es obligatorio." };

    switch (valueType) {
      case "string":
        //Remove double spaces
        const finalValue = value.toString().replace(/\s{2,}/g, " ").trim();
        if(!finalValue || finalValue.toLowerCase() === 'null' )
          return {
            colName,
            error: 'Por favor, completa este campo, es obligatorio.',
          };

        return finalValue
      case "number":
        if (!isNaN(value)) 
          return value;

        return {
          colName,
          error: `${value} no es un número válido. Asegúrate de ingresar un valor numérico para este campo.`,
        };
      case "date":
        const date = new Date(value);
        if (!isNaN(date.getTime())) return date.toISOString();

        return {
          colName,
          error: "La fecha ingresada no es válida. Por favor, asegúrate de ingresar una fecha válida.",
        };
      case "school":
        const schoolAcronym = value.toString().trim().toUpperCase();
        const school = this.schools.find(
          (e) => e.schoolAcronym == schoolAcronym
        );

        if (school) 
          return school.id;

        return {
          colName,
          error: `No se encontró ninguna escuela con el acrónimo "${value}". Por favor, verifica el acrónimo e inténtalo de nuevo.`,
        };
      case "documentType":
        const documentAcronym = value.toString().trim().toUpperCase();
        const document = DOCUMENTS.find(
          (e) => e.documentAcronym == documentAcronym
        );
        if (document) 
          return document.id;

        return {
          colName,
          error: `No se encontró ningún documento con el acrónimo "${value}". Por favor, verifica el acrónimo e inténtalo de nuevo.`,
        };
      default:
        return "";
    }
  }

  /**
   * Sends a notification to the users selected
   */
  private sendNotification(emailList: string[]) {
    const date: Date = new Date();
    date.setMinutes(date.getMinutes() + 1);

    const notificationData = {
      namesTO: JSON.stringify(["Notificación de nuevo recibo"]),
      emailsTo: JSON.stringify(emailList),
      msg: JSON.stringify([
        { message: "¡Tienes un nuevo recibo pendiente de revisión!" },
      ]),
      timeToSend: date.toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: "email",
    };

    return this._paymentOrder.setNotificationQueue(notificationData);
  }

  /**
   * Calculate elapsed time
   */
  private calculateWaitTime(
    totalRequests,
    completedRequests: number,
    startTime: DOMHighResTimeStamp
  ) {
    const currentTime = performance.now();
    const elapsedTime = currentTime - startTime;
    const elapsedTimeInMinutes = elapsedTime / 60000;
    const averageTimePerRequest = elapsedTimeInMinutes / completedRequests;
    const remainingTime = Math.max(
      0,
      averageTimePerRequest * (totalRequests - completedRequests)
    );
    return remainingTime.toFixed(2);
  }

  //Download text file with errors
  private downloadErrors() {
    let content = "FILA\tCOLUMNAS\n";
    this.pageErrors.forEach(
      (error) => (content += `${error.row}\t${error.columns}\n`)
    );

    //-----------------------------------<a></a>
    const element = this.document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    element.setAttribute("download", "errores.txt");

    element.style.display = "none";
    this.document.body.appendChild(element);
    element.click();
    this.document.body.removeChild(element);
  }

  public renderField(value: any, valueType: string = "default") {
    switch (valueType) {
      case "school":
        const school = this.schools.find((e) => e.id == value);
        return school ? school.schoolAcronym : value;
      case "documentType":
        const document = DOCUMENTS.find((e) => e.id == value);
        return document ? document.documentAcronym : value;
      default:
        return value;
    }
  }

  public showFieldError(error: any) {
    swalError({
      title: "",
      message: error.error,
    });
  }
}