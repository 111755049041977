
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

export type AOA = any[][];
export type Header = { header: string, property: string, default?: string }

@Injectable( {
  providedIn: 'root'
} )

export class XlsxService {

  // == Properties for deferred loading

  // Number of records per batch
  private batchSize: number = 10000;

  // Current batch index
  private batchIndex: number = 0; 

  // Data batch size for each sheet
  private _dataChunkSize = 1000; 

  constructor () {
    // 
  }


  public importFromFile( bstr: string ): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read( bstr, { type: 'binary' } );

    /* grab first sheet */
    const wsname: string = wb.SheetNames[ 0 ];
    const ws: XLSX.WorkSheet = wb.Sheets[ wsname ];

    /* save data */
    const data = <XLSX.AOA2SheetOpts>( XLSX.utils.sheet_to_json( ws, { header: 1 } ) );

    return data;
  }

  public importFromFileInBatches(bstr: string )  {
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    return XLSX.utils.sheet_to_json(ws, { header: 1 });
}

  public exportToFile( fileName: string, headers: Header[], data: any[] ) {
    let fomatedData: AOA = [];

    fomatedData.push( headers.map( e => e.header ) );

    data.forEach( arr => {
      const obj = [];
      for ( let i = 0; i < headers.length; i++ ) {
        const k = headers[ i ].property;
        obj[ i ] = arr.hasOwnProperty( k ) ? arr[ k ] : headers[ i ].default;
      }
      fomatedData.push( obj );
    } )

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet( fomatedData );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet( wb, ws, 'Sheet1' );
    XLSX.writeFile( wb, fileName );
  }

  public exportRawToFile( fileName: string, headers: any[], data: any[] ) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet( data );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa( ws, [ headers ] );
    XLSX.utils.book_append_sheet( wb, ws, 'Sheet1' );
    XLSX.writeFile( wb, fileName );
  }


  //== EXPORTS HUGE EXCEL DATA USING DEFERRED LOADING  ==//

  /**
 * Download an Excel file with the provided data
 * @param data 
 * @param fileName
 */
  public exportToExcel( data: any[], fileName: string ): void {

    // Split the data into smaller batches
    const chunks = this.splitIntoChunks( data, this._dataChunkSize );

    // Create one worksheet for each batch of data
    const sheets = [];
    chunks.forEach( ( chunk, index ) => {
      const sheetName = `Hoja ${ index + 1 }`;
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet( chunk );
      sheets.push( { name: sheetName, sheet: ws } );
    } );

    // Create an Excel workbook with worksheets
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    sheets.forEach( ( sheet ) => {
      XLSX.utils.book_append_sheet( wb, sheet.sheet, sheet.name );
    } );

    // Download the Excel file
    XLSX.writeFile( wb, `${ fileName }.xlsx` );
  }

  /**
   * Dividing an array into smaller batches
   * @param arr 
   * @param chunkSize 
   * @returns
   */
  private splitIntoChunks( arr: any[], chunkSize: number ): any[][] {
    const chunks = [];
    let i = 0;
    while ( i < arr.length ) {
      chunks.push( arr.slice( i, i + chunkSize ) );
      i += chunkSize;
    }
    return chunks;
  }

}


