import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'general-form',
  templateUrl: './general-form.component.html',
  styleUrls: [ './general-form.component.css' ]
} )
export class GeneralFormComponent implements OnInit {

  @Output( 'formInputValues' ) formInputValues: EventEmitter<object> = new EventEmitter();

  //If true, the validations will be activated
  @Input('checkErrors') checkErrors:boolean = false;

  @Input('setInputValues') setInputValues:any = {};

  constructor () { 
    //
  }

  ngOnInit(): void {
    //
  }
}
