import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CardId } from '../models/card-id';


@Injectable({
  providedIn: 'root'
})
export class CardIdService {

  constructor(
    private _httpService: HttpService
  ) { }

  patchCardIdByUserID(cardID: number, data: object): Observable<CardId>{
    let url: string = `/cardids/${cardID}`
    return this._httpService.httpPatch(url, data).pipe(take(1));
  }
  postCardId(data: object): Observable<CardId>{
    let url: string = `/cardids`
    return this._httpService.httpPost(url, data).pipe(take(1));
  }
  
}
