import { TypesCourse } from './typesCourse';



export class Category {
    public id:number;
    public NameCategoryCourse:string;

    public TypeCourses:TypesCourse[];

      /** Variables para graficas */
      public totalOffers?: number = 0;

      public totalQuota?:number = 0;
      public totalForeign?:number = 0;

      public totalRegistered?:number = 0;
      public totalAcepted?:number = 0;
      public totalEnrolled?:number = 0;
      public totalGraduated?:number = 0;
      public totalCanceled?:number = 0;
}
