import { Injectable, Type } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/school';
import { RoleMapping } from '../models/roleMapping';
import { Category } from '../models/category';
import { Course } from '../models/course';
import { CourseOffer } from '../models/courseOffer';
import { Modality } from '../models/modality';
import { Force } from '../models/force';
import { Range } from '../models/range';
import { OferState } from '../models/oferState';
import { TypesCourse } from '../models/typesCourse';
import { RCourseOfeUpdate } from '../models/r-course-ofe-update';

@Injectable({
  providedIn: 'root'
})
export class DirectivasService {

  constructor(
    private _httpService: HttpService
  ) { }

  getMyRoleMappings(filter?: string): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings`
    if (filter)
      url = `/Userapps/me/roleMappings?filter=${filter}`
    return this._httpService.httpGet(url);
  }


  getSchoolInfo(filter?: string): Observable<School[]> {
    let url: string = `/Schools`
    if (filter)
      url = `/Schools?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSchoolInfoByID(SchoolID: number, filter?: string): Observable<School> {
    let url: string = `/Schools/${SchoolID}`
    if (filter)
      url = `/Schools/${SchoolID}?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getCategories(filter?: string): Observable<Category[]> {
    let url: string = `/Categories`
    if (filter)
      url = `/Categories?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getOferStates(): Observable<OferState[]>{
    let url: string = `/OferStates`
    return this._httpService.httpGet(url);
  }
  getRanges(filter?: string): Observable<Range[]> {
    let url: string = `/Ranges`
    if (filter)
      url = `/Ranges?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getForces(filter?: string): Observable<Force[]> {
    let url: string = `/Forces`
    if (filter)
      url = `/Forces?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getTypeCourses(filter?: string): Observable<TypesCourse[]> {
    let url: string = `/TypeCourses`
    if (filter)
      url = `/TypeCourses?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getModalities(filter?: string): Observable<Modality[]> {
    let url: string = `/Modalities`
    if (filter)
      url = `/Modalities?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getCourses(filter?: string): Observable<Course[]> {
    let url: string = `/Courses`
    if (filter)
      url = `/Courses?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  
  getCourseOferUpdate(filter?: string): Observable<RCourseOfeUpdate[]> {
    let url: string = `/RCourseOfeUpdates`
    if (filter)
      url = `/RCourseOfeUpdates?filter=${filter}`
    return this._httpService.httpGet(url);
  }


  newCourseOfer(data: CourseOffer): Observable<CourseOffer> {
    let url: string = `/Coursesofers`
    return this._httpService.httpPost(url, data);
  }
  getCoursesOfers(filter: string): Observable<CourseOffer[]> {
    let url: string = `/CoursesOfers`
    if (filter)
      url = `/CoursesOfers?filter=${filter}`
    return this._httpService.httpGet(url);
  }


  updateCourseOfer(data: CourseOffer): Observable<CourseOffer> {
    let url: string = `/Coursesofers`
    return this._httpService.httpPatch(url, data);
  }

  deleteCourseOfer(id: number): Observable<CourseOffer> {
    let url: string = `/Coursesofers/${id}`
    return this._httpService.httpDelete(url);
  }

  updateCourse(data: Course): Observable<Course> {
    let url: string = `/Courses`
    return this._httpService.httpPatch(url, data);
  }
  createMassiveOffers(data: CourseOffer[]): Observable<any> {
    let url: string = `/CoursesOfers`
    return this._httpService.httpPost(url, data);
  }
  

  countCourseOffers(where:string): Observable<any> {
    let url: string = `/CoursesOfers/count?where=${where}`
    return this._httpService.httpGet(url);
  }
  
  countUserCourse(where:string): Observable<any> {
    let url: string = `/UserCourses/count?where=${where}`
    return this._httpService.httpGet(url);
  }



}


