'use strict';
import { Component, OnInit } from '@angular/core';

import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { School } from 'src/app/models/school';
import { Category } from 'src/app/models/category';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';

import { CourseOffer } from 'src/app/models/courseOffer';

import { PieData } from 'src/app/models/pieData';
import { UserCourse } from 'src/app/models/userCourse';
import { forkJoin, Subscription } from 'rxjs';
import { OferState } from 'src/app/models/oferState';
import { CourseService } from 'src/app/services/course.service';
import { Range } from 'src/app/models/range';
import { Header, XlsxService } from 'src/app/services/xlsx.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'directiva-home',
  templateUrl: './directivas.component.html',
  styleUrls: ['./directivas.component.sass']
})
export class DirectivasComponent implements OnInit {

  public year: any;
  public schools: School[] = [];

  public categories: Category[] = [];
  public oferStates: OferState[] = [];
  public typeSchedules = [{ name: "Directiva", id: true, totalCourse: 0 }, { name: "ExtraDirectiva", id: false, totalCourse: 0 }]
  public ranges: Range[] = [];

  public isCedoc: boolean = false;

  public dataPieChartCategories: PieData[] = [];
  public dataPieChartStates: PieData;

  public students = {
    register: 0, quotas: 0, foreign: 0, acepted: 0, enrolled: 0, graduated: 0, canceled: 0
  }

  public totalGeneral = 0;

  public exportHeaders: Header[] = []
  private exportData: CourseOffer[];
  /** VARIABLES PARA GRAFICA DE ESTUDIANTES */

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    legend: {
      display: false
    },
    cutoutPercentage: 80,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return '';
        },
      },
    },
    tooltips: {
      bodyFontSize: 8,
      displayColors: false,
      callbacks: {
        label: ((tooltipItem) => {
          let index = tooltipItem.index;
          let label = this.pieChartLabels[index];
          return label;
        })
      }
    }
  };

  public pieChartLabels: Label[] = ['Cupos', 'Activos']
  public pieChartDataStudent: SingleDataSet = [0, 0];
  public chartColors =
    [
      { backgroundColor: ["#FC5F6B", "#2194B5"], borderColor: '#FFF', borderWidth: 0 },
    ];

  //Barchart
  public barChartOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 3,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',

      }
    }
  };


  public barChartLabels: Label[] = [];
  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0], label: 'Cupos Total' },
    { data: [0, 0, 0], label: 'Inscritos' },
    { data: [0, 0, 0], label: 'Aceptados/OAP' },
    { data: [0, 0, 0], label: 'Matriculados/Presentados' },
    { data: [0, 0, 0], label: 'Graduados/Certificados' },
    { data: [0, 0, 0], label: 'Retirados' },
    { data: [0, 0, 0], label: 'Cupos Extranjeros' },
  ];

  public barChartColorsDetails =
    [
      { backgroundColor: ["#C6541C"], borderColor: '#FFF', borderWidth: 0 },
      { backgroundColor: ["#FFB13A"], borderColor: '#FFF', borderWidth: 0 },
      { backgroundColor: ["#BE7B16"], borderColor: '#FFF', borderWidth: 0 },
    ];



  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  showYAxisLabel: boolean = false;

  public multi = [
    { name: "OAP", series: [{ "name": "x", "value": 0 }] },
    { name: "Certificados", series: [{ "name": "x", "value": 0 }] },
  ];

  public isData = false






  // google maps zoom level
  public zoom: number = 6;

  // Posicion inicial del mapa
  public lat: number = 4.60971;
  public lng: number = -74.08175;
  public markers: CourseOffer[] = []

  private isReadyGraph = false
  private subscriptionCourseList: Subscription
  private subscriptionSchool: Subscription

  public statisticsTabSelected: number = 1;

  public statisticsTabs: {id: number, tabName: string}[] = [
    {
      id: 1,
      tabName: 'Estadísticas Ofertas'
    },
    {
      id: 2,
      tabName: 'Estadísticas Alumnos'
    },
    {
      id: 3,
      tabName: 'Estadísticas Derechos pecuniarios'
    }
  ]


  public SCHOOL_DYNAMIC_PHOTO:any = (id:number) => environment.SCHOOL_DYNAMIC_PHOTO(id);
  public SCHOOL_NAME: any = environment.SCHOOL_NAME;


  constructor(
    private courseService: CourseService,
    public sharedService: SharedService,
    private _xlsxService: XlsxService,
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    this.getLabels();
  }

  ngOnInit(): void {
    this.subscriptionCourseList = this.sharedService.sharedcourseOfferlist.subscribe(list => {
      if (this.isReadyGraph) {
        this.exportData=list;
        this.fillCategoryData(list);
      }
    });
    this.subscriptionSchool = this.sharedService.sharedyearSchoolFilter.subscribe(data => {
      this.year = data.year;
      this.schools = data.schools;
    });
  }

  ngOnDestroy() {
    this.subscriptionCourseList.unsubscribe()
    this.subscriptionSchool.unsubscribe()
  }

  private resetData() {
    this.isData = false

    this.barChartData[0].data = [];
    this.barChartData[1].data = [];
    this.barChartData[2].data = [];
    this.barChartData[3].data = [];
    this.barChartData[4].data = [];

    this.markers = [];

    this.totalGeneral = 0;

    this.oferStates = this.oferStates.map(d => {
      d.totalCourse = 0
      return d
    });

    this.typeSchedules = this.typeSchedules.map(d => {
      d.totalCourse = 0
      return d
    })

    this.ranges = this.ranges.map(d => {
      d.totalAcepted = 0
      d.totalGraduated = 0
      d.totalCanceled = 0
      return d
    })

    this.categories = this.categories.map(d => {
      d.totalQuota = 0;
      d.totalForeign = 0;
      d.totalRegistered = 0;
      d.totalAcepted = 0;
      d.totalEnrolled = 0;
      d.totalGraduated = 0;
      d.totalCanceled = 0;
      d.totalOffers = 0;
      d.TypeCourses = d.TypeCourses.map(e => {
        e.totalOffers = 0;
        return e
      })
      return d
    });
  }

  private drawData() {


    this.dataPieChartCategories = this.categories.map(category => {

      const newData: PieData = {
        title: category.NameCategoryCourse,
        data: category.TypeCourses.map(typeCourse => {
          return { name: typeCourse.NameTypeCourse, value: typeCourse.totalOffers }
        })
      }

      this.barChartData[0].data.push(category.totalQuota)
      this.barChartData[1].data.push(category.totalRegistered)
      this.barChartData[2].data.push(category.totalAcepted)
      this.barChartData[3].data.push(category.totalEnrolled)
      this.barChartData[4].data.push(category.totalGraduated)
      this.barChartData[5].data.push(category.totalCanceled)
      this.barChartData[6].data.push(category.totalForeign)

      return newData

    });

    const newDatastate: PieData = {
      title: "",
      data: this.oferStates.map(state => {
        return { name: state.nameState, value: state.totalCourse }
      })
    }

    const newDataSchedule = {
      title: "",
      data: this.typeSchedules.map(schedule => {
        return { name: schedule.name, value: schedule.totalCourse }
      })
    }

    this.dataPieChartCategories.push(newDatastate)
    this.dataPieChartCategories.push(newDataSchedule)

    this.pieChartDataStudent = [this.students.quotas, this.students.acepted]


    this.multi = [
      {
        name: "OAP",
        series: this.ranges.map(r => { return { name: r.Sigla + ` ${r.totalAcepted}/${r.totalGraduated}`, value: r.totalAcepted } })
      },
      {
        name: "Certificados",
        series: this.ranges.map(r => { return { name: r.Sigla + ` ${r.totalAcepted}/${r.totalGraduated}`, value: r.totalGraduated } })
      }
    ];

    this.isData = true;
  }


  private getLabels(): void {

    const filterRange = { where: { ForceID: 1 } }
    forkJoin([
      this.courseService.getCategories(),
      this.courseService.getRanges(JSON.stringify(filterRange)),
      this.courseService.getOferStates(),
    ]).subscribe(Val => {
      [this.categories, this.ranges, this.oferStates] = Val;

      this.barChartLabels = this.categories.map(x => x.NameCategoryCourse)
      this.barChartColorsDetails[0].backgroundColor = new Array(this.categories.length).fill("#C6541C")
      this.barChartColorsDetails[1].backgroundColor = new Array(this.categories.length).fill("#FFB13A")
      this.barChartColorsDetails[2].backgroundColor = new Array(this.categories.length).fill("#BE7B16")

      // Recall UserList
      this.isReadyGraph = true
      this.sharedService.nextcategoryNameFilter({ filterName: "Recall", value: "" })

    })


  }

  private fillCategoryData(courseList: CourseOffer[]) {

    this.resetData()

    courseList.forEach(course => {

      this.totalGeneral++;

      if (!!course.Altitud)
        this.markers.push(course)

      for (let category of this.categories) {

        const indexTypeCourse = category.TypeCourses.findIndex(typeCourse => typeCourse.id == course.TypeCourseID);
        if (!(indexTypeCourse < 0)) {

          category.TypeCourses[indexTypeCourse].totalOffers++
          category.totalOffers++
          category.totalQuota += course.TotalPeople;
          category.totalForeign += course.ForeignPeople;
          this.students.quotas += course.TotalPeople;
          this.students.foreign += course.ForeignPeople;

          course.UserCourses.forEach(userCourse => {
            let indexRange = -1
            if (userCourse.militarInfo) {
              indexRange = this.ranges.findIndex(r => r.OtanID == userCourse.militarInfo.OtanID);
            }

            if (userCourse.IsAproved) {
              if (!(indexRange < 0))
                this.ranges[indexRange].totalGraduated++
              category.totalGraduated++
              this.students.graduated++
            }
            if (userCourse.IsAcepted) {
              if (!(indexRange < 0))
                this.ranges[indexRange].totalAcepted++
              category.totalAcepted++
              this.students.acepted++
            }
            if (userCourse.IsPaied) {
              category.totalEnrolled++
              this.students.enrolled++
            }
            if (userCourse.IsCancel) {
              if (!(indexRange < 0))
                this.ranges[indexRange].totalCanceled++
              category.totalCanceled++
              this.students.canceled++
            }

            category.totalRegistered++
            this.students.register++


          });

          break;
        }
      }

      const indexSchedule = this.typeSchedules.findIndex(sch => sch.id == course.IsDirectiva)
      if (!(indexSchedule < 0))
        this.typeSchedules[indexSchedule].totalCourse++

      const indexState = this.oferStates.findIndex(os => os.id == course.OferState);
      if (!(indexState < 0))
        this.oferStates[indexState].totalCourse++


    });

    this.drawData();

  }

  public downloadData() {
    this._xlsxService.exportRawToFile('ofertas.xlsx', this.exportHeaders, this.exportData);
  }
}
