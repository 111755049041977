import { Component, Input, OnInit } from '@angular/core';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';

@Component({
  selector: 'se-header-details',
  templateUrl: './se-header-details.component.html',
  styleUrls: ['./se-header-details.component.css']
})
export class SeHeaderDetailsComponent implements OnInit {

  @Input('intcall') intcall: IntcallI;

  constructor() {/** */}

  ngOnInit(): void {
    /** */
  }

}
