import { Component, ElementRef, OnInit, QueryList, ViewChildren } from "@angular/core";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { Subscription, forkJoin } from "rxjs";
import { School } from "src/app/models/school";
import { User } from "src/app/models/user";
import { CourseService } from "src/app/services/course.service";
import { RoleMappingService } from "src/app/services/role-mapping";
import { SharedService } from "src/app/services/shared.service";
import { XlsxService } from "src/app/services/xlsx.service";
import {
  columnsSelected,
  columnDefs__personalData,
  columnDefs__academicData,
  columnDefs__clasificationData,
  columnDefs__carnetData,
  columnsSkeleton,
} from "./user-list.types";
import { UserDocument } from "src/app/models/userDocuments";
import { environment } from "src/environments/environment";

@Component({
  selector: "directiva-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.css"],
})
export class UsersListComponent implements OnInit {
  rowDataComplete: any[] = [];
  rowData: any[] = [];

  /** *************** Begin Table Data *************** */
  private gridApi!: GridApi<User>;
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    resizable: true,
    sortable: true,
  };
  columnDefsSelected: ColDef[] = [];
  columnDefs: ColDef[] = [
    ...columnDefs__personalData,
    ...columnDefs__academicData,
    ...columnDefs__clasificationData,
  ];
  optionsSelected: any = columnsSelected;
  columnsSelectedFiltered = { ...columnsSkeleton };
  /** *************** End Table Data *************** */
  public schools: School[] = [];
  public year: number;
  private subscriptionCourseList: Subscription;
  private subscriptionSchool: Subscription;
  public showModalColumnsSelector: boolean = false;
  public showModalUserWithout: boolean = false;
  public isAdminCardId: boolean = false;
  public userCanFindAllStudents: boolean = false;

  //Set the columns to store in cookies
  private _columnsToStore: any[] = [];
  private _ranges: any[] = [];
  private _forces: any[] = [];

  public columnList: any = {
    personalData: false,
    academicData: false,
    clasificationData: false,
  }

  constructor(
    private _xlsxService: XlsxService,
    private courseService: CourseService,
    public sharedService: SharedService,
    public _roleMappingService: RoleMappingService
  ) {
    this.getRoles();
    this.modalShowAllOptions();
  }

  ngOnInit(): void {

    forkJoin({
      ranges: this.courseService.getRanges(),
      forces: this.courseService.getForces()
    }).subscribe({
      next: ({ ranges, forces }) => {
        this._ranges = ranges;
        this._forces = forces;
      },
      error: (err) => {
        console.log(err);
      }
    })

    this.subscriptionCourseList =
      this.sharedService.sharedcourseOfferlist.subscribe({
        next: (list) => {
          this.rowDataComplete = [];
          list.forEach((element) => {
            if (!(!!element.id && element.id != undefined && element.id > 0))
              return;
            const filter = {
              include: [
                { coursesOfer: "school" },
                {
                  userapp: [
                    "ProfesionalProfile",
                    "cardIds",
                    "ContactInfos",
                    {
                      relation: "accessTokens",
                      scope: {
                        order: "created DESC",
                        limit: 1,
                      },
                    },
                    {
                      MilitarInfos: ["Range", "Force"],
                    },
                    {
                      UserDocuments: "TypeDocuments",
                    },
                    {
                      ClassificationUser: [
                        "typeBiologicalSex",
                        "typeCivilStatus",
                        "typeEthnicalGroup",
                        "typeIndigeniusPeople",
                        "typeCommunities",
                        "typeHandicapped",
                        "typeExceptionalCapacity",
                        "typeZone",
                        "typeSisben",
                        "typeEstrato",
                        "typeRH",
                      ],
                    },
                  ],
                },
              ],
            };
            this.courseService
              .getStudentListByCoursesOfers(element.id, JSON.stringify(filter))
              .subscribe((data) => {
                const studentList = data.map((d) => {
                  let studenInfo = {
                    /** ******************************* userCourse ******************************* */
                    IsDocument: d.IsDocument,
                    IsPaied: d.IsPaied,
                    IsAcepted: d.IsAcepted,
                    GPA: d.GPA,
                    urlReciboDePago: d.Invoice,
                    nLibro: d.bookNumber,
                    nDiploma: d.diplomaNumber,
                    nActa: d.actaNumber,
                    nfolio: d.folio,
                    /** ******************************* coursesOfer ******************************* */
                    couseOfferName: `${d.coursesOfer.NameCourseOfer} (${d.coursesOfer.school.SchoolAcronim})`,
                    /** ******************************* personalData ******************************* */
                    id: d.UserID,
                    /** userapp */
                    Nombre: `${d.userapp.Name1} ${d.userapp.Name2}`,
                    Apellido: `${d.userapp.LastName1} ${d.userapp.LastName2}`,
                    EmailCEDOC: d.userapp.CedocEmail,
                    esMilitar: d.userapp.IsMilitar,
                    /** accessTokens */
                    UltimoAcceso: d.userapp.accessTokens[0]?.created
                      ? d.userapp.accessTokens[0]?.created
                        .split(".000")[0]
                        .replace("T", " ")
                      : "",
                    /** MilitarInfos */
                    CedulaMilitar: d.userapp?.MilitarInfos[0]?.CedulaMil,
                    Fuerza: this._forces.find(e => e.id === d.userapp.ClassificationUser[0]?.ForceType)?.NameForce || '',
                    /** ProfesionalProfile */
                    PerfilProfesional:
                      d.userapp.ProfesionalProfile[0]?.Description,
                    IcfesURL: d.userapp.ProfesionalProfile[0]?.IcfesID,
                    SaberProUrl: d.userapp.ProfesionalProfile[0]?.SaberProID,
                    Skills: d.userapp.ProfesionalProfile[0]?.Skills,
                    /** cardIds */
                    CarnetID: d.userapp.cardIds[0]?.id,
                    CarnetNum: d.userapp.cardIds[0]?.numberCode,
                    CarnetFecEmision: d.userapp.cardIds[0]?.dateIssue
                      ? d.userapp.cardIds[0]?.dateIssue.split("T")[0]
                      : "",
                    CarnetFecVencimiento: d.userapp.cardIds[0]?.dateExpiry
                      ? d.userapp.cardIds[0]?.dateExpiry.split("T")[0]
                      : "",
                    CarnetFecCambio: d.userapp.cardIds[0]?.dateChange
                      ? d.userapp.cardIds[0]?.dateChange.split("T")[0]
                      : "",
                    CarnetTipo: d.userapp.cardIds[0]?.type,
                    /** UserDocuments */
                    Documento: d.userapp.UserDocuments[0]?.Document,
                    FechaDeExpedicionDoc: d.userapp.UserDocuments[0]
                      ?.DateExpedition
                      ? d.userapp.UserDocuments[0]?.DateExpedition.split("T")[0]
                      : "",
                    LugarDeExpedicionDoc: d.userapp.UserDocuments[0]
                      ?.PlaceExpedition
                      ? d.userapp.UserDocuments[0]?.PlaceExpedition.split(
                        "T"
                      )[0]
                      : "",
                    TipoDocumento:
                      d.userapp.UserDocuments[0]?.TypeDocuments
                        ?.NameTypeDocument,
                    DocumentoLink: d.userapp.UserDocuments[0]?.DocumentFile,
                    /** ContactInfos */
                    FechaDeNacimiento: d.userapp.ContactInfos[0]?.birthDate
                      ? d.userapp.ContactInfos[0]?.birthDate.split("T")[0]
                      : "",
                    LugarDeNacimiento: d.userapp.ContactInfos[0]?.Birthplace,
                    ContactoDeEmergenciaNombre:
                      d.userapp.ContactInfos[0]?.EmergencyName,
                    ContactoDeEmergencia:
                      d.userapp.ContactInfos[0]?.EmergencyPhone,
                    EmailPersonal: d.userapp.ContactInfos[0]?.Email,
                    Telefono: d.userapp.ContactInfos[0]?.Phone,
                    TelefonoCel: d.userapp.ContactInfos[0]?.CellPhone,
                    Direccion: d.userapp.ContactInfos[0]?.Address,
                    Ciudad: d.userapp.ContactInfos[0]?.Ciudad,
                    /** ClassificationUser */
                    SexoBiologico:
                      d.userapp.ClassificationUser[0]?.typeBiologicalSex
                        ?.nameBiologicalSex,
                    EstadoCivil:
                      d.userapp.ClassificationUser[0]?.typeCivilStatus
                        ?.nameCivilStatus,
                    GrupoEtnico:
                      d.userapp.ClassificationUser[0]?.typeEthnicalGroup
                        ?.nameEthnicalGroup,
                    ComunidadIndigenaAdscrita:
                      d.userapp.ClassificationUser[0]?.typeIndigeniusPeople
                        ?.nameIndigeniusPeople,
                    ComunidadAdscrita:
                      d.userapp.ClassificationUser[0]?.typeCommunities
                        ?.nameCommunity,
                    Discapacidad:
                      d.userapp.ClassificationUser[0]?.typeHandicapped
                        ?.disabilityName,
                    PoseeCapacidadExcepcional:
                      d.userapp.ClassificationUser[0]?.typeExceptionalCapacity
                        ?.nameCapacity,
                    Sisben:
                      d.userapp.ClassificationUser[0]?.typeSisben?.nameSisben,
                    EstratoSocial:
                      d.userapp.ClassificationUser[0]?.typeEstrato?.nameEstrato,
                    zonaResidencia:
                      d.userapp.ClassificationUser[0]?.typeZone?.nameZone,
                    type_rh: d.userapp.ClassificationUser[0]?.typeRH?.nameRH,
                    EPS: d.userapp.ClassificationUser[0]?.eps,
                    CajaCompensacion: d.userapp.ClassificationUser[0]?.ers,
                    Edad: d.userapp.ClassificationUser[0]?.age,

                    FechaDeGraduacion:
                      d.userapp.ClassificationUser[0]?.graduationDate,
                    SeEncuentraLaborando:
                      d.userapp.ClassificationUser[0]?.currentOccupation,
                    EmpresaDondeTrabaja:
                      d.userapp.ClassificationUser[0]?.companyName,
                    SectorEmpresa:
                      d.userapp.ClassificationUser[0]?.businessSector,
                    EmailEmpresa:
                      d.userapp.ClassificationUser[0]?.businessEmail,
                    TelefonoEmpresa:
                      d.userapp.ClassificationUser[0]?.businessPhoneNumber,
                    CargoActual: d.userapp.ClassificationUser[0]?.chargeCompany,
                    RangoSalarial: d.userapp.ClassificationUser[0]?.salary,
                    ReconocimientoEspecial:
                      d.userapp.ClassificationUser[0]?.specialRecognition,
                    ProyectoImpactoSocial:
                      d.userapp.ClassificationUser[0]?.socialImpactProject,
                    PonenciasExterior:
                      d.userapp.ClassificationUser[0]?.externalPresentations,
                    GrupoDeInvestigacion:
                      d.userapp.ClassificationUser[0]?.researchGroup,
                    CiudadEmpresa: d.userapp.ClassificationUser[0]?.companyCity,
                    UltimoEstudio: d.userapp.ClassificationUser[0]?.lastStudy,
                    NivelEscolaridad:
                      d.userapp.ClassificationUser[0]?.schoolingLevel,

                    FactorHr: d.userapp.ClassificationUser[0]?.HrFactor,
                    HijoDeMiembroFuerzaPublica:
                      d.userapp.ClassificationUser[0]?.ChildOfPublicForceMember,
                    NumeroDeVecesPresentoProceso:
                      d.userapp.ClassificationUser[0]
                        ?.NumberOfTimesIPresentProcess,
                    Tattoo: d.userapp.ClassificationUser[0]?.Tattoo,
                    CantidadTattooUbicacionYDescripcion:
                      d.userapp.ClassificationUser[0]
                        ?.QuantityTattooLocationAndDescriptionOfTheFigure,
                    NombreDeLaInstitucionEducativaSecundaria:
                      d.userapp.ClassificationUser[0]
                        ?.NameOfHighSchoolEducationalInstitution,
                    DiaLaboral: d.userapp.ClassificationUser[0]?.WorkingDay,
                    Horario: d.userapp.ClassificationUser[0]?.Schedule,
                    NaturalezaPublicaOPrivada:
                      d.userapp.ClassificationUser[0]?.PublicOrPrivateNature,
                    MetodologiaPresencialODistancia:
                      d.userapp.ClassificationUser[0]
                        ?.PresentialOrDistanceMethodology,
                    TipoDeEscuelaSecundaria:
                      d.userapp.ClassificationUser[0]?.TypeOfHighSchool,
                    FechaAspiranteTituloBachiller:
                      d.userapp.ClassificationUser[0]
                        ?.DateAspirantBaccalaureateDegree,
                    EstudiosComplementarios:
                      d.userapp.ClassificationUser[0]?.ComplementaryStudies,
                    Lateralidad: d.userapp.ClassificationUser[0]?.Laterality,
                    NumeroDeZapato: d.userapp.ClassificationUser[0]?.NoOfShoe,
                    TallaDeGorra: d.userapp.ClassificationUser[0]?.CapSize,
                    TallaDeCamisa: d.userapp.ClassificationUser[0]?.ShirtSize,
                    TallaDePantalon: d.userapp.ClassificationUser[0]?.PantSize,
                    TallaDeCamuflaje:
                      d.userapp.ClassificationUser[0]?.CamouflageSize,
                    NombreDeLaMadre:
                      d.userapp.ClassificationUser[0]?.MothersName,
                    TarjetaDeLaMadre:
                      d.userapp.ClassificationUser[0]?.MothersCard,
                    MadreVivaOFinada:
                      d.userapp.ClassificationUser[0]?.MotherLivingOrDeceased,
                    TelefonoDeLaMadre:
                      d.userapp.ClassificationUser[0]?.MothersTelephone,
                    OcupacionDeLaMadre:
                      d.userapp.ClassificationUser[0]?.MothersOccupation,
                    CorreoDeLaMadre:
                      d.userapp.ClassificationUser[0]?.MotherEmailAddress,
                    NombreDelPadre:
                      d.userapp.ClassificationUser[0]?.FathersName,
                    CertificadoDelPadre:
                      d.userapp.ClassificationUser[0]?.FathersCertificate,
                    PadreVivoOFinado:
                      d.userapp.ClassificationUser[0]?.FatherLivingOrDeceased,
                    TelefonoDelPadre:
                      d.userapp.ClassificationUser[0]?.FathersTelephone,
                    OcupacionDelPadre:
                      d.userapp.ClassificationUser[0]?.FathersOccupation,
                    CorreoDelPadre:
                      d.userapp.ClassificationUser[0]?.ParentEmailAddress,
                    EstadoCivilDeLosPadres:
                      d.userapp.ClassificationUser[0]?.MaritalStatusOfParents,
                    VictimaDelConflicto:
                      d.userapp.ClassificationUser[0]?.ConflictVictim,
                    MunicipioDondeFueVictimaDelConflicto:
                      d.userapp.ClassificationUser[0]
                        ?.MunicipalityWhereHeWasAVictimOfTheConflict,
                    NumeroDePersonasEnElGrupoFamiliar:
                      d.userapp.ClassificationUser[0]
                        ?.NumberOfPeopleInTheFamilyGroup,
                    ContribuyendoEconomicamente:
                      d.userapp.ClassificationUser[0]?.FinanciallyContributing,
                    IngresosTotales:
                      d.userapp.ClassificationUser[0]?.TotalRevenue,
                    TipoDeVivienda:
                      d.userapp.ClassificationUser[0]?.HousingType,
                    Grado: this._ranges.find(e => e.OtanID === d.userapp.ClassificationUser[0]?.Grade)?.NameRange || '',

                    Barrio: d.userapp.ClassificationUser[0]?.Barrio,
                    CourseNumber: d.userapp.ClassificationUser[0]?.CourseNumber,
                    CourseType: d.userapp.ClassificationUser[0]?.CourseType,
                    RegistrationDate: d.userapp.ClassificationUser[0]?.RegistrationDate,
                    ProgramSelectionReason: d.userapp.ClassificationUser[0]?.ProgramSelectionReason,
                    CurrentStatus: d.userapp.ClassificationUser[0]?.CurrentStatus,
                    MilitaryIDNumber: d.userapp.ClassificationUser[0]?.MilitaryIDNumber,
                    MilitaryService: d.userapp.ClassificationUser[0]?.MilitaryService,
                    SoldierForceType: d.userapp.ClassificationUser[0]?.SoldierForceType,
                    UnitMilitaryService: d.userapp.ClassificationUser[0]?.UnitMilitaryService,
                    MilitaryDistrict: d.userapp.ClassificationUser[0]?.MilitaryDistrict,
                    Contingent: d.userapp.ClassificationUser[0]?.Contingent,
                    ContingentYear: d.userapp.ClassificationUser[0]?.ContingentYear,
                    SportsInterest: d.userapp.ClassificationUser[0]?.SportsInterest,
                    Displaced: d.userapp.ClassificationUser[0]?.Displaced,
                    DisplacingMunicipality: d.userapp.ClassificationUser[0]?.DisplacingMunicipality,
                    SecondaryEducationSpecialty: d.userapp.ClassificationUser[0]?.SecondaryEducationSpecialty,
                    InstitutionCode: d.userapp.ClassificationUser[0]?.InstitutionCode,
                    InstitutionCharacter: d.userapp.ClassificationUser[0]?.InstitutionCharacter,
                    Methodology: d.userapp.ClassificationUser[0]?.Methodology,
                    ObtainedDegree: d.userapp.ClassificationUser[0]?.ObtainedDegree,
                    GraduationRecordNo: d.userapp.ClassificationUser[0]?.GraduationRecordNo,
                    DiplomaFolioNo: d.userapp.ClassificationUser[0]?.DiplomaFolioNo,
                    TrainingType: d.userapp.ClassificationUser[0]?.TrainingType,
                    ICFESCode: d.userapp.ClassificationUser[0]?.ICFESCode,
                    ICFESScore: d.userapp.ClassificationUser[0]?.ICFESScore,
                    ICFESPresentationDate: d.userapp.ClassificationUser[0]?.ICFESPresentationDate,
                    YearICFESPruebasSaberReport: d.userapp.ClassificationUser[0]?.YearICFESPruebasSaberReport,
                    EnglishAreaResult: d.userapp.ClassificationUser[0]?.EnglishAreaResult,
                    FlexibleComponentResult: d.userapp.ClassificationUser[0]?.FlexibleComponentResult,
                    FamilyIncome: d.userapp.ClassificationUser[0]?.FamilyIncome,
                    NumberSiblings: d.userapp.ClassificationUser[0]?.NumberSiblings,
                    SiblingsHigherEducation: d.userapp.ClassificationUser[0]?.SiblingsHigherEducation,
                    SiblingPosition: d.userapp.ClassificationUser[0]?.SiblingPosition,
                    ArmedForcesFamily: d.userapp.ClassificationUser[0]?.ArmedForcesFamily,
                    ArmedForcesFamilyName: d.userapp.ClassificationUser[0]?.ArmedForcesFamilyName,
                    FatherAddress: d.userapp.ClassificationUser[0]?.FatherAddress,
                    MotherAddress: d.userapp.ClassificationUser[0]?.MotherAddress,
                    GuardianName: d.userapp.ClassificationUser[0]?.GuardianName,
                    GuardianIDNumber: d.userapp.ClassificationUser[0]?.GuardianIDNumber,
                    GuardianPhone: d.userapp.ClassificationUser[0]?.GuardianPhone,
                    GuardianAddress: d.userapp.ClassificationUser[0]?.GuardianAddress,
                    SecondLanguage: d.userapp.ClassificationUser[0]?.SecondLanguage,
                    SecondLanguageName: d.userapp.ClassificationUser[0]?.SecondLanguageName,
                    TransportationMethod: d.userapp.ClassificationUser[0]?.TransportationMethod,
                    SisbenSubgroup: d.userapp.ClassificationUser[0]?.SisbenSubgroup,
                    DocumentIssuingPlace: d.userapp.ClassificationUser[0]?.DocumentIssuingPlace,
                    NumberChildren: d.userapp.ClassificationUser[0]?.NumberChildren,
                    HealthInsurance: d.userapp.ClassificationUser[0]?.HealthInsurance,
                    InsuranceCompany: d.userapp.ClassificationUser[0]?.InsuranceCompany,
                    MilitaryID: d.userapp.ClassificationUser[0]?.MilitaryID,
                    DocumentIssuanceDate: d.userapp.ClassificationUser[0]?.DocumentIssuanceDate,
                    VeteransLaw: d.userapp.ClassificationUser[0]?.VeteransLaw,
                    recruitingZone: d.userapp.ClassificationUser[0]?.recruitingZone,
                  };
                  return studenInfo;
                });
                this.rowDataComplete.push(...studentList);
                this.rowData = this.rowDataComplete;
                this.gridApi.setRowData(this.rowData);
              });
          });
        },
      });
    this.subscriptionSchool =
      this.sharedService.sharedyearSchoolFilter.subscribe((data) => {
        this.year = data.year;
        this.schools = data.schools;
      });
  }

  ngOnDestroy() {
    this.subscriptionCourseList.unsubscribe();
    this.subscriptionSchool.unsubscribe();
  }

  getRoles(): void {
    this._roleMappingService.getRoles().subscribe({
      next: (data) => {
        data.forEach((element) => {
          if (element.roleId == 26) this.isAdminCardId = true; // Carnetizacion
          if (
            element.roleId == 1 ||
            element.roleId == 6 ||
            element.roleId == 15 ||
            element.roleId == 23
          )
            this.userCanFindAllStudents = true; // admin
        });
      },
    });
  }

  onSearch(query: string = "") {
    if (query !== "")
      this.rowData = this.rowDataComplete.filter((obj) =>
        obj.Documento?.startsWith(query) || obj.EmailCEDOC?.startsWith(query) || obj.EmailPersonal?.startsWith(query)
      );
    else
      this.rowData = this.rowDataComplete;

    if (this.userCanFindAllStudents && query !== "")
      this.getAllUsersToAdmin(query);
  }

  onSearchModal(query: string = "") {
    if (query !== "") {
      this.columnsSelectedFiltered.personalData =
        columnsSelected.personalData.filter((obj) =>
          obj.name?.toLowerCase().includes(query.toLowerCase())
        );
      this.columnsSelectedFiltered.academicData =
        columnsSelected.academicData.filter((obj) =>
          obj.name?.toLowerCase().includes(query.toLowerCase())
        );
      this.columnsSelectedFiltered.clasificationData =
        columnsSelected.clasificationData.filter((obj) =>
          obj.name?.toLowerCase().includes(query.toLowerCase())
        );
      this.columnsSelectedFiltered.carnetData =
        columnsSelected.carnetData.filter((obj) =>
          obj.name?.toLowerCase().includes(query.toLowerCase())
        );
    } else this.modalShowAllOptions();
  }

  modalShowAllOptions() {
    columnsSelected.personalData.forEach((element) => {
      this.columnsSelectedFiltered.personalData.push(element);
    });
    columnsSelected.academicData.forEach((element) => {
      this.columnsSelectedFiltered.academicData.push(element);
    });
    columnsSelected.clasificationData.forEach((element) => {
      this.columnsSelectedFiltered.clasificationData.push(element);
    });
    columnsSelected.carnetData.forEach((element) => {
      this.columnsSelectedFiltered.carnetData.push(element);
    });
  }

  exportUserList() {
    const dataToExport = this.rowData.map((d) => {
      let studentInfo = {};

      for (const category in this.optionsSelected) {
        if (this.optionsSelected.hasOwnProperty(category)) {
          const selectedData = this.optionsSelected[category];

          for (const element of selectedData) {
            if (element.value)
              studentInfo[element.keyValue] = d[element.keyValue];
          }
        }
      }
      return studentInfo;
    });
    this._xlsxService.exportRawToFile("Estudiantes.xlsx", null, dataToExport);
  }

  showAllColumns(): void {
    this.columnDefsSelected = [];
    this.columnDefsSelected = [
      ...columnDefs__personalData,
      ...columnDefs__academicData,
      ...columnDefs__clasificationData,
    ];
    this.gridApi.setColumnDefs(this.columnDefsSelected);
    this.showModalColumnsSelector = false;

    //Store selected columns in coockies
    this._columnsToStore = [];
    localStorage.setItem('schedule-columns', JSON.stringify(this._columnsToStore));
    this.getStoredColumns();
  }

  showColumnsSelected(): void {
    this.columnDefsSelected = [];
    this.optionsSelected.personalData.forEach((element) => {
      if (element.value)
        this.columnDefsSelected.push(columnDefs__personalData[element.id]);
    });
    this.optionsSelected.academicData.forEach((element) => {
      if (element.value)
        this.columnDefsSelected.push(columnDefs__academicData[element.id]);
    });
    this.optionsSelected.clasificationData.forEach((element) => {
      if (element.value)
        this.columnDefsSelected.push(columnDefs__clasificationData[element.id]);
    });
    this.optionsSelected.carnetData.forEach((element) => {
      if (element.value)
        this.columnDefsSelected.push(columnDefs__carnetData[element.id]);
    });

    //Store selected columns in coockies
    localStorage.setItem('schedule-columns', JSON.stringify(this._columnsToStore));

    this.gridApi.setColumnDefs(this.columnDefsSelected);
    this.showModalColumnsSelector = false;
  }

  onGridReady(params: GridReadyEvent<User>) {
    this.gridApi = params.api;
    this.getStoredColumns();

    if (this._columnsToStore.length > 0) this.showColumnsSelected();
  }

  getAllUsersToAdmin(documentReceived: string) {
    const filter2 = {
      include: [
        {
          userapp: [
            "ProfesionalProfile",
            "cardIds",
            "ContactInfos",
            {
              relation: "accessTokens",
              scope: {
                order: "created DESC",
                limit: 1,
              },
            },
            {
              MilitarInfos: ["Range", "Force"],
            },
            {
              UserDocuments: "TypeDocuments",
            },
            {
              ClassificationUser: [
                "typeBiologicalSex",
                "typeCivilStatus",
                "typeEthnicalGroup",
                "typeIndigeniusPeople",
                "typeCommunities",
                "typeHandicapped",
                "typeExceptionalCapacity",
                "typeZone",
                "typeSisben",
                "typeEstrato",
                "typeRH",
              ],
            },
          ],
        },
      ],
    };
    const filter = {
      where: {
        Document: `${documentReceived}`,
      },
      include: {
        User: [
          { roleMappings: ["role", "school"] },
          {
            UserCourses: [
              { coursesOfer: "school" },
              {
                userapp: [
                  "ProfesionalProfile",
                  "cardIds",
                  "ContactInfos",
                  {
                    relation: "accessTokens",
                    scope: {
                      order: "created DESC",
                      limit: 1,
                    },
                  },
                  {
                    MilitarInfos: ["Range", "Force"],
                  },
                  {
                    UserDocuments: "TypeDocuments",
                  },
                  {
                    ClassificationUser: [
                      "typeBiologicalSex",
                      "typeCivilStatus",
                      "typeEthnicalGroup",
                      "typeIndigeniusPeople",
                      "typeCommunities",
                      "typeHandicapped",
                      "typeExceptionalCapacity",
                      "typeZone",
                      "typeSisben",
                      "typeEstrato",
                      "typeRH",
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // {
      //   relation: 'CourseOfer',
      //   scope: {

      //   }
      // }
    };

    // this.courseService.getStudentListCoursesOfersByUserDocument(document, JSON.stringify(filter)).subscribe(data => {
    this.courseService
      .getStudentListCoursesOfersByUserDocument(JSON.stringify(filter))
      .subscribe((data) => {
        console.log("getAllUsersToAdmin", data);

        if (data[0].User.UserCourses.length == 0) {
          console.log("usuario sin cursos inscritos");
          this.userToModalWithoutCourses = data[0];
          this.showModalUserWithout = true;
        }

        const studentList = data[0].User.UserCourses.map((d) => {
          let studenInfo = {
            /** ******************************* userCourse ******************************* */
            IsDocument: d.IsDocument,
            IsPaied: d.IsPaied,
            IsAcepted: d.IsAcepted,
            GPA: d.GPA,
            urlReciboDePago: d.Invoice,
            nLibro: d.bookNumber,
            nDiploma: d.diplomaNumber,
            nActa: d.actaNumber,
            nfolio: d.folio,
            /** ******************************* coursesOfer ******************************* */
            couseOfferName: `${d.coursesOfer.NameCourseOfer} (${d.coursesOfer.school.SchoolAcronim})`,
            /** ******************************* personalData ******************************* */
            id: d.UserID,
            /** userapp */
            Nombre: `${d.userapp.Name1} ${d.userapp.Name2}`,
            Apellido: `${d.userapp.LastName1} ${d.userapp.LastName2}`,
            EmailCEDOC: d.userapp.CedocEmail,
            esMilitar: d.userapp.IsMilitar,
            /** accessTokens */
            UltimoAcceso: d.userapp.accessTokens[0]?.created
              ? d.userapp.accessTokens[0]?.created
                .split(".000")[0]
                .replace("T", " ")
              : "",
            /** MilitarInfos */
            CedulaMilitar: d.userapp?.MilitarInfos[0]?.CedulaMil,
            Fuerza: this._forces.find(e => e.id === d.userapp.ClassificationUser[0]?.ForceType)?.NameForce || '',
            /** ProfesionalProfile */
            PerfilProfesional: d.userapp.ProfesionalProfile[0]?.Description,
            IcfesURL: d.userapp.ProfesionalProfile[0]?.IcfesID,
            SaberProUrl: d.userapp.ProfesionalProfile[0]?.SaberProID,
            Skills: d.userapp.ProfesionalProfile[0]?.Skills,
            /** cardIds */
            CarnetID: d.userapp.cardIds[0]?.id,
            CarnetNum: d.userapp.cardIds[0]?.numberCode,
            CarnetFecEmision: d.userapp.cardIds[0]?.dateIssue
              ? d.userapp.cardIds[0]?.dateIssue.split("T")[0]
              : "",
            CarnetFecVencimiento: d.userapp.cardIds[0]?.dateExpiry
              ? d.userapp.cardIds[0]?.dateExpiry.split("T")[0]
              : "",
            CarnetFecCambio: d.userapp.cardIds[0]?.dateChange
              ? d.userapp.cardIds[0]?.dateChange.split("T")[0]
              : "",
            CarnetTipo: d.userapp.cardIds[0]?.type,
            /** UserDocuments */
            Documento: d.userapp.UserDocuments[0]?.Document,
            FechaDeExpedicionDoc: d.userapp.UserDocuments[0]?.DateExpedition
              ? d.userapp.UserDocuments[0]?.DateExpedition.split("T")[0]
              : "",
            LugarDeExpedicionDoc: d.userapp.UserDocuments[0]?.PlaceExpedition
              ? d.userapp.UserDocuments[0]?.PlaceExpedition.split("T")[0]
              : "",
            TipoDocumento:
              d.userapp.UserDocuments[0]?.TypeDocuments?.NameTypeDocument,
            DocumentoLink: d.userapp.UserDocuments[0]?.DocumentFile,
            /** ContactInfos */
            FechaDeNacimiento: d.userapp.ContactInfos[0]?.birthDate
              ? d.userapp.ContactInfos[0]?.birthDate.split("T")[0]
              : "",
            LugarDeNacimiento: d.userapp.ClassificationUser[0]?.Birthplace,
            ContactoDeEmergenciaNombre:
              d.userapp.ContactInfos[0]?.EmergencyName,
            ContactoDeEmergencia: d.userapp.ContactInfos[0]?.EmergencyPhone,
            EmailPersonal: d.userapp.ContactInfos[0]?.Email,
            Telefono: d.userapp.ContactInfos[0]?.Phone,
            TelefonoCel: d.userapp.ContactInfos[0]?.CellPhone,
            Direccion: d.userapp.ContactInfos[0]?.Address,
            Ciudad: d.userapp.ContactInfos[0]?.Ciudad,
            /** ClassificationUser */
            SexoBiologico:
              d.userapp.ClassificationUser[0]?.typeBiologicalSex
                ?.nameBiologicalSex,
            EstadoCivil:
              d.userapp.ClassificationUser[0]?.typeCivilStatus?.nameCivilStatus,
            GrupoEtnico:
              d.userapp.ClassificationUser[0]?.typeEthnicalGroup
                ?.nameEthnicalGroup,
            ComunidadIndigenaAdscrita:
              d.userapp.ClassificationUser[0]?.typeIndigeniusPeople
                ?.nameIndigeniusPeople,
            ComunidadAdscrita:
              d.userapp.ClassificationUser[0]?.typeCommunities?.nameCommunity,
            Discapacidad:
              d.userapp.ClassificationUser[0]?.typeHandicapped?.disabilityName,
            PoseeCapacidadExcepcional:
              d.userapp.ClassificationUser[0]?.typeExceptionalCapacity
                ?.nameCapacity,
            Sisben: d.userapp.ClassificationUser[0]?.typeSisben?.nameSisben,
            EstratoSocial:
              d.userapp.ClassificationUser[0]?.typeEstrato?.nameEstrato,
            zonaResidencia: d.userapp.ClassificationUser[0]?.typeZone?.nameZone,
            type_rh: d.userapp.ClassificationUser[0]?.typeRH?.nameRH,
            EPS: d.userapp.ClassificationUser[0]?.eps,
            CajaCompensacion: d.userapp.ClassificationUser[0]?.ers,
            Edad: d.userapp.ClassificationUser[0]?.age,

            FechaDeGraduacion: d.userapp.ClassificationUser[0]?.graduationDate,
            SeEncuentraLaborando:
              d.userapp.ClassificationUser[0]?.currentOccupation,
            EmpresaDondeTrabaja: d.userapp.ClassificationUser[0]?.companyName,
            SectorEmpresa: d.userapp.ClassificationUser[0]?.businessSector,
            EmailEmpresa: d.userapp.ClassificationUser[0]?.businessEmail,
            TelefonoEmpresa:
              d.userapp.ClassificationUser[0]?.businessPhoneNumber,
            CargoActual: d.userapp.ClassificationUser[0]?.chargeCompany,
            RangoSalarial: d.userapp.ClassificationUser[0]?.salary,
            ReconocimientoEspecial:
              d.userapp.ClassificationUser[0]?.specialRecognition,
            ProyectoImpactoSocial:
              d.userapp.ClassificationUser[0]?.socialImpactProject,
            PonenciasExterior:
              d.userapp.ClassificationUser[0]?.externalPresentations,
            GrupoDeInvestigacion:
              d.userapp.ClassificationUser[0]?.researchGroup,
            CiudadEmpresa: d.userapp.ClassificationUser[0]?.companyCity,
            UltimoEstudio: d.userapp.ClassificationUser[0]?.lastStudy,
            NivelEscolaridad: d.userapp.ClassificationUser[0]?.schoolingLevel,
            Grado: this._ranges.find(e => e.OtanID === d.userapp.ClassificationUser[0]?.Grade)?.NameRange || '',

            Barrio: d.userapp.ClassificationUser[0]?.Barrio,
            CourseNumber: d.userapp.ClassificationUser[0]?.CourseNumber,
            CourseType: d.userapp.ClassificationUser[0]?.CourseType,
            RegistrationDate: d.userapp.ClassificationUser[0]?.RegistrationDate,
            ProgramSelectionReason: d.userapp.ClassificationUser[0]?.ProgramSelectionReason,
            CurrentStatus: d.userapp.ClassificationUser[0]?.CurrentStatus,
            MilitaryIDNumber: d.userapp.ClassificationUser[0]?.MilitaryIDNumber,
            MilitaryService: d.userapp.ClassificationUser[0]?.MilitaryService,
            SoldierForceType: d.userapp.ClassificationUser[0]?.SoldierForceType,
            UnitMilitaryService: d.userapp.ClassificationUser[0]?.UnitMilitaryService,
            MilitaryDistrict: d.userapp.ClassificationUser[0]?.MilitaryDistrict,
            Contingent: d.userapp.ClassificationUser[0]?.Contingent,
            ContingentYear: d.userapp.ClassificationUser[0]?.ContingentYear,
            SportsInterest: d.userapp.ClassificationUser[0]?.SportsInterest,
            Displaced: d.userapp.ClassificationUser[0]?.Displaced,
            DisplacingMunicipality: d.userapp.ClassificationUser[0]?.DisplacingMunicipality,
            SecondaryEducationSpecialty: d.userapp.ClassificationUser[0]?.SecondaryEducationSpecialty,
            InstitutionCode: d.userapp.ClassificationUser[0]?.InstitutionCode,
            InstitutionCharacter: d.userapp.ClassificationUser[0]?.InstitutionCharacter,
            Methodology: d.userapp.ClassificationUser[0]?.Methodology,
            ObtainedDegree: d.userapp.ClassificationUser[0]?.ObtainedDegree,
            GraduationRecordNo: d.userapp.ClassificationUser[0]?.GraduationRecordNo,
            DiplomaFolioNo: d.userapp.ClassificationUser[0]?.DiplomaFolioNo,
            TrainingType: d.userapp.ClassificationUser[0]?.TrainingType,
            ICFESCode: d.userapp.ClassificationUser[0]?.ICFESCode,
            ICFESScore: d.userapp.ClassificationUser[0]?.ICFESScore,
            ICFESPresentationDate: d.userapp.ClassificationUser[0]?.ICFESPresentationDate,
            YearICFESPruebasSaberReport: d.userapp.ClassificationUser[0]?.YearICFESPruebasSaberReport,
            EnglishAreaResult: d.userapp.ClassificationUser[0]?.EnglishAreaResult,
            FlexibleComponentResult: d.userapp.ClassificationUser[0]?.FlexibleComponentResult,
            FamilyIncome: d.userapp.ClassificationUser[0]?.FamilyIncome,
            NumberSiblings: d.userapp.ClassificationUser[0]?.NumberSiblings,
            SiblingsHigherEducation: d.userapp.ClassificationUser[0]?.SiblingsHigherEducation,
            SiblingPosition: d.userapp.ClassificationUser[0]?.SiblingPosition,
            ArmedForcesFamily: d.userapp.ClassificationUser[0]?.ArmedForcesFamily,
            ArmedForcesFamilyName: d.userapp.ClassificationUser[0]?.ArmedForcesFamilyName,
            FatherAddress: d.userapp.ClassificationUser[0]?.FatherAddress,
            MotherAddress: d.userapp.ClassificationUser[0]?.MotherAddress,
            GuardianName: d.userapp.ClassificationUser[0]?.GuardianName,
            GuardianIDNumber: d.userapp.ClassificationUser[0]?.GuardianIDNumber,
            GuardianPhone: d.userapp.ClassificationUser[0]?.GuardianPhone,
            GuardianAddress: d.userapp.ClassificationUser[0]?.GuardianAddress,
            SecondLanguage: d.userapp.ClassificationUser[0]?.SecondLanguage,
            SecondLanguageName: d.userapp.ClassificationUser[0]?.SecondLanguageName,
            TransportationMethod: d.userapp.ClassificationUser[0]?.TransportationMethod,
            SisbenSubgroup: d.userapp.ClassificationUser[0]?.SisbenSubgroup,
            DocumentIssuingPlace: d.userapp.ClassificationUser[0]?.DocumentIssuingPlace,
            NumberChildren: d.userapp.ClassificationUser[0]?.NumberChildren,
            HealthInsurance: d.userapp.ClassificationUser[0]?.HealthInsurance,
            InsuranceCompany: d.userapp.ClassificationUser[0]?.InsuranceCompany,
            MilitaryID: d.userapp.ClassificationUser[0]?.MilitaryID,
            DocumentIssuanceDate: d.userapp.ClassificationUser[0]?.DocumentIssuanceDate,
            VeteransLaw: d.userapp.ClassificationUser[0]?.VeteransLaw,
            recruitingZone: d.userapp.ClassificationUser[0]?.recruitingZone,
          };
          return studenInfo;
        });
        this.rowDataComplete.push(...studentList);
        this.rowData = studentList;
        this.gridApi.setRowData(this.rowData);
      });
  }

  userToModalWithoutCourses: UserDocument;





  /**
   * Storage the filtered columns
   */
  public setColumnsToStore(option: any, category: string) {
    const name = option.name;
    const nameIndex = this._columnsToStore.indexOf(name);

    option.value ? this._columnsToStore.push(name) : this._columnsToStore.splice(nameIndex, 1)

    //If we are unchecking the current checkbox, then select all checkbox is no longer true
    if (!option.value && this._columnsToStore.indexOf(category) > -1) {
      this._columnsToStore.splice(this._columnsToStore.indexOf(category), 1);
      this.columnList[category] = false;
    }

    //If all cheeckbox are checked then ...
    const checkAll = this.columnsSelectedFiltered[category].every((e) => e.value);

    if (checkAll && this._columnsToStore.indexOf(category) === -1){
      this._columnsToStore.push(category);
      this.columnList[category] = true;
    }

  }

  /**
   * Get the columns stored in cookies
   */
  private getStoredColumns() {
    const storedItems = JSON.parse(localStorage.getItem('schedule-columns')) || [];
    this._columnsToStore = storedItems;

    for (const key in this.columnsSelectedFiltered) {
      if (this.columnsSelectedFiltered.hasOwnProperty(key)) {
        const array = this.columnsSelectedFiltered[key];
        for (const item of array) {
          let itemName = item.name;
          item.value = storedItems.includes(itemName);
        }
      }
    }

    for (const key in this.columnList) {
      console.log(key);
      
      if (this.columnList.hasOwnProperty(key)) {
        const storedItems = this._columnsToStore;
        const checkAll = storedItems.includes(key);
        this.columnList[key] = checkAll;
      }
    }
  }


  /**
   * Checks if all checkbox field should be checked
   * @param category
   * @returns
   */
  public toggleAllCheckboxes(category: string): void {
    this.columnsSelectedFiltered[category].forEach(option => {
      option.value = this.columnList[category];
      this.setColumnsToStore(option, category);
    });
  }
}
