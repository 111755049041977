export class Force{
    
        constructor(
    
        public id?:number,
        public NameForce?:string,
        public Sigla?:string,
    
        ){
    
        }
    }