import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'directiva-sticky-bar-survey',
  templateUrl: './sticky-bar-survey.component.html',
  styleUrls: ['./sticky-bar-survey.component.css']
})
export class StickyBarSurveyComponent implements OnInit {

  constructor() {/** */}

  ngOnInit(): void {/** */}

}
