import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralInfoSectionComponent } from './general-info-section/general-info-section.component';
import { FieldCardComponent } from './general-info-section/field-card/field-card.component';
import { UploadFileBoxModule } from 'src/app/components/shared/upload-file-box/upload-file-box.module';
import { SwitchModule } from '../shared/switch/switch.module';
import { WeightingInputModule } from '../shared/weighting-input/weighting-input.module';
import { CheckboxModule } from '../shared/checkbox/checkbox.module';

import { ProcessActionsModule } from '../shared/process-actions/process-actions.module';
import { GeneralFormModule } from '../shared/general-form/general-form.module';
import { FormsModule } from '@angular/forms';
import { NewModuleComponent } from './general-info-section/new-module/new-module.component';
import { UserSearchBarModule } from '../shared/user-search-bar/user-search-bar.module';
import { NewProcessComponent } from './new-process.component';
import { GeneralSectionPaginationModule } from '../shared/general-section-pagination/general-section-pagination.module';
import { UserCardModule } from '../shared/user-card/user-card.module';
import { PublishSectionComponent } from './publish-section/publish-section.component';
import { FilesSectionComponent } from './files-section/files-section.component';

@NgModule( {
  declarations: [
    NewProcessComponent,
    FieldCardComponent,
    FilesSectionComponent,
    GeneralInfoSectionComponent,
    PublishSectionComponent,
    NewModuleComponent,
  ],
  imports: [
    CommonModule,
    UploadFileBoxModule,
    SwitchModule,
    WeightingInputModule,
    CheckboxModule,
    ProcessActionsModule,
    UserSearchBarModule,
    GeneralFormModule,
    GeneralSectionPaginationModule,
    UserCardModule,
    FormsModule
  ],
  exports: [
    NewProcessComponent,
  ]
} )
export class NewProcessModule { }
