export class Range{
    public id:number;
    public ForceID:number;
    public OtanID:number;
    public NameRange:string;
    public Sigla:string;

    public totalCanceled:number=0;
    public totalAcepted:number=0;
    public totalGraduated:number=0;
}