import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { DirectivasService } from 'src/app/services/directivas.service';
import { SharedService } from 'src/app/services/shared.service';


import { isNullOrUndefined } from 'util-deprecate';
import {  Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets  } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'multipleBarChart-component',
  templateUrl: './multipleBarChart.component.html',
  styleUrls: ['./multipleBarChart.component.css', './multipleBarChart.component.sass']
})
export class MultipleBarChartComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() title: any;


  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  constructor(
    private _directivasService: DirectivasService,
    public sharedService: SharedService,
    private _router: Router
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (!isNullOrUndefined(changes['myInfo'])) {
      if (changes['myInfo'].firstChange) {
        // this.myInfo = changes['myInfo'].currentValue
        //  this.getSchools();
      }
    }

  }

  ngOnInit(): void {
    this.data = [
      { label: "Cupos", data: [0, 0, 0, 0, 0] },
      { label: "OAP", data: [0, 0, 0, 0, 0] },
      { label: "Certificados", data: [0, 0, 0, 0, 0] },
      { label: "Inscritos", data: [0, 0, 0, 0, 0] },
      { label: "Matriculados", data: [0, 0, 0, 0, 0] },
      { label: "Graduados", data: [0, 0, 0, 0, 0] },

    ]

 

  }




}
