import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { fromEvent } from 'rxjs'
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

@Component( {
  selector: 'user-search-bar',
  templateUrl: './user-search-bar.component.html',
  styleUrls: [ './user-search-bar.component.css' ]
} )
export class UserSearchBarComponent implements OnInit, AfterViewInit {

  constructor ( private _selfAssessmentService: SelfAssessmentService ) {
    //
  }

  @ViewChild( 'userSearchInput' ) userSearchInput: ElementRef;

  @Output( 'usersToAdd' ) usersToAdd: EventEmitter<any[]> = new EventEmitter();

  //Stores the filtered user list from database.
  public userList: any[] = [];

  //Stores the users to be added
  private _usersToAdd: any[] = [];

  //Toggles the div containing the list of users
  public showUserList: boolean = false;

  ngOnInit(): void {
    //
  }

  ngAfterViewInit(): void {
    
    //Creates an observable from the userSearchInput nativeElement
    fromEvent<Event>( this.userSearchInput.nativeElement, 'keyup' ).pipe(
      map( ( event: Event ) => ( event.target as HTMLInputElement ).value.toLowerCase() ),
      filter( ( value: string ) => value.length > 3 ),
      debounceTime( 300 ),
      //distinct(),
      switchMap( ( value: string ) => this.getUserList( value ) )
    ).subscribe( {
      next: ( resp ) => {
        this.userList = resp.filter( e => !this._usersToAdd.some( r => r.id === e.id ) );
        this.showUserList = this.userList.length > 0;
      }, error: ( err ) => console.log( err )
    } )
  }

  /**
   * This function is used to get a list of users from the self-assessment-service.
   * @param value 
   * @returns 
   */
  private getUserList( value: string ) {
    const filter = JSON.stringify( {
      where: { email: { regexp: `/${ value }/` } },
      limit: 4
    } )

    return this._selfAssessmentService.getUserList( filter );
  }

  /**
   * The function adds the user to the array "_usersToAdd" and filters the "userList" array to remove any elements with an id matching the user's id.
   * @param user 
   */
  public addUser( user: any ) {
    this._usersToAdd.push( user );
    this.userList = this.userList.filter( e => e.id !== user.id );

    if ( this.userList.length === 0 )
      this.userSearchInput.nativeElement.value = '';

    this.usersToAdd.emit( this._usersToAdd );
  }

  /**
  * This function removes a user from the array of "_usersToAdd". 
  * @param user 
  */
  public removeUser( user: any ) {
    this._usersToAdd = this._usersToAdd.filter( e => e.id !== user.id );
    
    this.usersToAdd.emit( this._usersToAdd );
    this.userList.push( user );
  }

  /**
   * This function is used to detect when a user clicks outside of an input container and a button with the class "button--add-partner"
   * @param event 
   */
  @HostListener( 'document:click', [ '$event' ] )
  clickOutside( event ): void {
    const target = event.target;
    const inputContainer = target.closest( '.input-container' );
    const addPartnerButton = target.classList.contains( 'button--add-partner' );

    if ( !inputContainer && !addPartnerButton ) {
      this.showUserList = false;
    } else {
      if ( this.userSearchInput.nativeElement.value.length > 0 ) {
        this.showUserList = true;
      }
    }
  }
}
