import { Component, Input, OnInit } from '@angular/core';

@Component( {
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.css' ]
} )
export class CheckboxComponent implements OnInit {

  @Input( 'checkId' ) checkId: string = 'check-item';

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

}
