import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileBoxModule } from 'src/app/components/shared/upload-file-box/upload-file-box.module';
import { PublishSectionComponent } from './publish-section/publish-section.component';
import { SwitchModule } from '../shared/switch/switch.module';
import { WeightingInputModule } from '../shared/weighting-input/weighting-input.module';
import { CheckboxModule } from '../shared/checkbox/checkbox.module';

import { GeneralFormModule } from '../shared/general-form/general-form.module';
import { FormsModule } from '@angular/forms';
import { UserSearchBarModule } from '../shared/user-search-bar/user-search-bar.module';
import { EditProcessComponent } from './edit-process.component';
import { ProcessActionsModule } from '../shared/process-actions/process-actions.module';
import { UserCardModule } from '../shared/user-card/user-card.module';
import { FilesSectionComponent } from './files-section/files-section.component';


@NgModule( {
  declarations: [
    FilesSectionComponent,
    EditProcessComponent,
    PublishSectionComponent
  ],
  imports: [
    CommonModule,
    UploadFileBoxModule,
    SwitchModule,
    WeightingInputModule,
    CheckboxModule,
    ProcessActionsModule,
    UserSearchBarModule,
    GeneralFormModule,
    UserCardModule,
    FormsModule
  ],
  providers: [Clipboard],
  exports: [
    EditProcessComponent,
  ]
} )
export class EditProcessModule { }
