import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, map, debounceTime, switchMap } from 'rxjs/operators';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'directiva-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.css'],
})
export class UserSearchComponent implements OnInit, AfterViewInit {
  constructor(private _courseService: CourseService) {
    //
  }
  @ViewChild('userSearch') userSearch!: ElementRef;
  @Output('selectedUserId') selectedUserId: EventEmitter<any> =
    new EventEmitter();
  @Output('userLoaded') userLoaded: EventEmitter<void> = new EventEmitter();
  @Output('onSelectUser') onSelectUser: EventEmitter<any> = new EventEmitter();
  @Input('hasErrors') hasErrors: boolean = false;
  @Input('position') position: string = 'bottom';

  public inputValue: string = '';
  public users: any[] = [];
  public showDropdown: boolean = false;

  /*   private _clickedInside: boolean = false; */
  private _clickInside: boolean = false;
  public isInputEmpty: boolean = false;

  public userId: number = 0;

  ngOnInit(): void {
    //
  }

  selectUser(user: any) {
    this.showDropdown = false;
    let name = `${user.Name1} ${user.Name2 || ''} ${user.LastName1 || ''} ${
      user.LastName2
    }`;
    this.inputValue = name.replace(/ +(?= )/g, '');
    this.userId = user.id;
    this.selectedUserId.emit(user.id);
  }

  onInputClick() {
    this.showDropdown = true;
    this._clickInside = true;
  }

  ngAfterViewInit(): void {
    fromEvent<Event>(this.userSearch.nativeElement, 'keyup')
      .pipe(
        map((event: Event) => {
          let value = (event.target as HTMLInputElement).value.toLowerCase();
          return value;
        }),
        filter((value: string) => {
          this.showDropdown = value.length > 3;

          //shows an error message again when the input is empty
          if (value.length <= 3) this.userId = 0;

          return value.length > 3;
        }),
        debounceTime(300),
        /* distinct(), */
        switchMap((value: string) => {
          let filter = JSON.stringify({
            where: {
              or: [
                { email: { regexp: `/${value}/` } },
                { Document: { regexp: `/${value}/` } },
              ],
            },
            limit: 4,
          });
          return this._courseService.getUsers(filter);
        })
      )
      .subscribe({
        next: (resp: any) => {
          this.users = resp;
        },
        error: () => {},
      });
  }

  getUserName(userId: number) {
    let filter = JSON.stringify({
      where: { id: userId },
    });
    this._courseService.getUsers(filter).subscribe({
      next: (resp) => {
        let name = `${resp[0].Name1} ${resp[0].Name2 || ''} ${
          resp[0].LastName1 || ''
        } ${resp[0].LastName2}`;
        this.inputValue = name.replace(/ +(?= )/g, '');
        this.userLoaded.emit();
      },
      error: (err) => console.log(err),
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    this.showDropdown = event.target.closest('#searchInput');
    //shows an error message when clicking outside the input and the user is not yet selected

    if (this.userId === 0) {
      if (event.target.closest('#searchInput') === null && this._clickInside)
        this.isInputEmpty = true;
    } else {
      this.isInputEmpty = false;
    }
  }
}
