import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CardIdService } from 'src/app/services/card-id.service';

@Component({
  selector: 'directiva-number-code',
  templateUrl: './number-code.component.html',
  styleUrls: ['./number-code.component.css']
})
export class NumberCodeComponent implements ICellRendererAngularComp {
  public cardID: number;
  public userID: number;
  public CarnetNum: string;

  public editData: boolean = false;
  public dataToUpdate: string;

  constructor(
    public _cardIdService: CardIdService,
  ) { }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.initAllData(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    // this.cellValue = this.getValueToDisplay(params);
    console.log('refresh params',params);
    this.initAllData(params);
    return true;
  }

  initAllData(params: ICellRendererParams) {
    this.cardID = params.data.CarnetID;
    this.userID = params.data.id;
    this.CarnetNum = params.data.CarnetNum;
    this.dataToUpdate = params.data.CarnetNum;
  }

  updateData() {
    if (this.dataToUpdate == undefined) return;
    console.log('dataToUpdate',this.dataToUpdate);
    if (this.cardID) this.patchData();
    else this.postData();
  }
  patchData() {
    console.log('patch');
    let data = {
      numberCode: this.dataToUpdate
    }
    this._cardIdService.patchCardIdByUserID(this.cardID, data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetNum = this.dataToUpdate;
    });
  }
  postData() {
    console.log('post');
    let data = {
      userID: this.userID,
      numberCode: this.dataToUpdate
    }
    this._cardIdService.postCardId(data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetNum = this.dataToUpdate;
    });
  }
}
