import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDescriptionComponent } from './header-description/header-description.component';



@NgModule({
  declarations: [
    HeaderDescriptionComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    HeaderDescriptionComponent
  ]
})
export class IntcallHeaderModule { }
