import { Component, EventEmitter, Input, OnInit , Output } from '@angular/core';

@Component( {
  selector: 'switch',
  templateUrl: './switch.component.html',
  styleUrls: [ './switch.component.css' ]
} )
export class SwitchComponent implements OnInit {

  //Sends the input state
  @Output('onInputChange') onInputChange: EventEmitter<boolean> = new EventEmitter();
  
  //Receives the input state
  @Input('checkboxValue') checkboxValue:boolean = false;

  constructor () { 
    //
  }

  ngOnInit(): void {
    //
  }

  /**
   * Emits the input value when it changes
   */
  public onChange(){
    this.onInputChange.emit( this.checkboxValue );
  }

}
