'use strict';
import { Component, OnInit, AfterViewInit} from '@angular/core';
import { DirectivasService } from 'src/app/services/directivas.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';

import { School } from 'src/app/models/school';

import * as moment from 'moment';

import { Course } from 'src/app/models/course';
import { CourseOffer } from 'src/app/models/courseOffer';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { TypesCourse } from 'src/app/models/typesCourse';
import { OFERSTATES, TypeOferState } from 'src/app/models/oferState';

declare var google: any;
moment.locale('es');
const async = require("async");

@Component({
  selector: 'financial-report-directiva',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.css', './financial-report.component.sass']
})
export class FinancialReportComponent implements OnInit, AfterViewInit {

  public isGantt = false;
  public isAdmin:boolean = false;
  public isCedoc:boolean = false;

  public myInfo: any;
  public year: number;

  public currentYearOriginal = (new Date()).getFullYear();
  public schools: School[] = [];

  public showDetails: boolean = false;
  public courseSelected: Course;


  public typeCourses: TypesCourse[] = [];
  public courses: Course[] = [];
  public showGantt = false;
  public ganttList: CourseOffer[] = [];


  public months = []

  private subscriptionCourseList: Subscription
  private subscriptionSchool: Subscription

  constructor(
    private _directivasService: DirectivasService,
    public sharedService: SharedService,
  ) {

  }

  ngAfterViewInit() {

    google.load("visualization", "1.1", {
      packages: ["timeline"]
    });

    this.sharedService.sleep(5000).then(() => {
      google.setOnLoadCallback(this.drawChart(this));
    })
    
  }
 

  ngOnInit(): void {
    this.subscriptionCourseList = this.sharedService.sharedcourseOfferlist.subscribe(list => {
      this.ganttList = list;
      if(this.isGantt){
        this.drawChart(this)
      }
    });
    this.subscriptionSchool = this.sharedService.sharedyearSchoolFilter.subscribe(data => {
      this.year = data.year;
      this.schools = data.schools;
    });

    this.getRoles();
  }

  ngOnDestroy() {
    this.subscriptionCourseList.unsubscribe();
    this.subscriptionSchool.unsubscribe();
  }

   getRoles() {
    let filter = `{"include":["role"]}`
    this._directivasService.getMyRoleMappings(filter).pipe(take(1)).subscribe(rolemapp => {
      rolemapp.forEach(role => {
        if (role.role.name == "Diedu") {
          this.isCedoc = true;
        }
        if (role.role.name == "Admin" || role.role.name == "Directiva") {
          this.isAdmin = true;
        }
      });
    });
  }

  editDate(index: number, campo: string): void {
    if(this.isAdmin)
    {
           Swal.fire({
        title: 'Editar',
        text: 'Desea editar la fecha?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.ganttList[index]["Edit" + campo] = true
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // );
        }
      }); 
    }
  }

  updateDate(index: number, campo: string, value): void {
    Swal.fire({
      title: 'Editar',
      text: 'Desea editar la fecha a ' + value + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.sharedService.swalLoading("Actualizando fecha ");


        if( this.isCedoc)
        {
          this.ganttList[index].OferState = TypeOferState.reprogramado;
          this.ganttList[index].oferState = OFERSTATES[TypeOferState.reprogramado];
        }
        else if( !this.isCedoc && this.ganttList[index].OferState!=TypeOferState.pendiente ){ 
          this.ganttList[index].OferState = TypeOferState.atraso;
          this.ganttList[index].oferState = OFERSTATES[TypeOferState.atraso];
        }
    

        this.ganttList[index][campo] = value
        this._directivasService.updateCourseOfer(this.ganttList[index]).pipe(take(1)).subscribe(newData => {
          this.sharedService.sleep(1500).then(() => {
            this.ganttList[index]["Edit" + campo] = false
            this.drawChart(this)
            this.sharedService.swalSuccess("Actualizado", "Fecha actualizada")
          })
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // );
      }
    });
  }


  eventDetailsComponent(event) {
    switch (event.typeEvent) {
      case 'edit':
       
        break
      case 'closeWindow':
        this.showDetails = false;
        break
      default:
        break
    }

  }


  loadDetails(offerDails) {
    this.showDetails = true;
    this.courseSelected = offerDails;
  }


  /*************************************** Crear TimeLine ********************************/

  
  drawChart(ctx): void {

    var dataTable = new google.visualization.DataTable();
    if(!!dataTable){
      this.isGantt = true;
    }
    dataTable.addColumn({ type: 'string', id: 'President' });
    dataTable.addColumn({ type: 'string', id: 'Name' });
    //dataTable.addColumn({ type: 'string', role: 'tooltip', p: { html: true } });
    dataTable.addColumn({ 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } });
    dataTable.addColumn({ type: 'string', role: 'style' });
    dataTable.addColumn({ type: 'date', id: 'Start' });
    dataTable.addColumn({ type: 'date', id: 'End' });
    dataTable.addColumn({ type: 'number', id: 'ID', role: 'hidden' });
    let format = "DD-MMMM-YYYY"
    if (ctx.ganttList.length > 0) {
      

      ctx.ganttList.forEach((element, index) => {
        let since = moment(element.Since).toDate()
        let until = moment(element.Until).toDate()
        let realSince = element.RealSince ? moment(element.RealSince).toDate() : moment().toDate()
        let realUntil = element.RealUntil ? moment(element.RealUntil).toDate() : moment().toDate()

        let toolTip1 = `
        <div style="padding:5px 10px; min-width:200px; position:absolute; background-color: #FFFFFF; border: 1px solid gray; ">
         <b> ${element.NameCourseOfer}</b> <br>
         <b>Inicio:</b> ${moment(element.Since).format(format)} <br>
         <b>Fin:</b> ${moment(element.Until).format(format)} <br>
         </div>
         `
        let toolTip2 = `
        <div style="padding:5px 10px; min-width:200px; position:absolute; background-color: #FFFFFF; border: 1px solid gray;">
        <b> ${element.NameCourseOfer}</b><br>
        <b>Inicio Real:</b> ${moment(element.RealSince).format(format)} <br>
        <b> Fin Real: </b>${moment(element.RealUntil).format(format)} <br>
         `

        moment(element.Since).isBefore(moment().set({ 'year': this.year }).startOf('year').toDate()) ? since = moment().set({ 'year': this.year }).startOf('year').toDate() : ""
        moment(element.Until).isAfter(moment().set({ 'year': this.year }).endOf('year').toDate()) ? until = moment().set({ 'year': this.year }).endOf('year').toDate() : ""

        moment(element.RealSince).isBefore(moment().set({ 'year': this.year }).startOf('year').toDate()) ? realSince = moment().set({ 'year': this.year }).startOf('year').toDate() : ""
        moment(element.RealUntil).isAfter(moment().set({ 'year': this.year }).endOf('year').toDate()) ? realUntil = moment().set({ 'year': this.year }).endOf('year').toDate() : ""

        dataTable.addRows([
          [element.NameCourseOfer, 'Programado', toolTip1, 'color: #72D014', since, until, index],

        ])

        // Si  existen las fechas de realsince
        if (element.RealSince && element.RealUntil) {
          dataTable.addRows([
            [element.NameCourseOfer, 'Real', toolTip2, 'color: #F39834', realSince, realUntil, index]
          ])
        }
        // Si no existen las fechas de realsince
        else {
          dataTable.addRows([
            [element.NameCourseOfer, 'Real', toolTip2, 'color: #F39834', moment(element.Since).toDate(), moment(element.Since).toDate(), index]
          ])
        }

      })
    }

    var paddingHeight = 400;

    var rowHeight = dataTable.getNumberOfRows() * 52 ;

    var chartHeight = rowHeight + paddingHeight;

    var options = {
      height: rowHeight,
      voidOverlappingGridLines: false,
      groupByRowLabel: true,
      backgroundColor: '#FFFFFF',
      tooltip: { isHtml: true },
      timeline: {
        groupByRowLabel: true,
        colorByRowLabel: false,
        showRowLabels: false,
        barLabelStyle: {
          fontSize: 17
        }
      },
      hAxis: {
        minValue: moment().set({ 'year': this.year }).startOf('year').toDate(),
        maxValue: moment().set({ 'year': this.year }).endOf('year').toDate(),
        ViewWindowMode: 'explicit',
        viewWindow: {
          min: moment().set({ 'year': this.year }).startOf('year').toDate(),
          max: moment().set({ 'year': this.year }).endOf('year').toDate(),
        },
        textStyle: {
          color: "#FFFFFF"
        },
        format: 'yy-MM',
        gridlines: { count: 12 }
      },
      width: 1000,
    };

    var container = document.getElementById('gantt');
    var chart = new google.visualization.Timeline(container);

    chart.draw(dataTable, options);
    window.addEventListener('resize', function () {
      chart.draw(dataTable, options);
      setBorderRadius();
      afterDraw()

    });
    google.visualization.events.addListener(chart, 'ready', function () {
      setBorderRadius();
      afterDraw()
      chart.draw(dataTable, options);
      
      
    
    
    });
    google.visualization.events.addListener(chart, 'select', function () {
      setBorderRadius();
      afterDraw()
    })
    google.visualization.events.addListener(chart, 'onmouseover', function () {
      setBorderRadius();
      afterDraw()
    })
    google.visualization.events.addListener(chart, 'onmouseout', function () {
      setBorderRadius();
      afterDraw()
    })


    function afterDraw() {
      var g = document.getElementsByTagName("svg")[0].getElementsByTagName("g")[1];
      document.getElementsByTagName("svg")[0].parentNode['style'].top = '40px';
      document.getElementsByTagName("svg")[0].style.overflow = 'visible';
      var height = Number(g.getElementsByTagName("text")[0].getAttribute('y')) + 20;
      g.setAttribute('transform', 'translate(0,-' + height + ')');
      g = null;

      var labels = container.getElementsByTagName('text');
      Array.prototype.forEach.call(labels, function (label) {
        if (label.getAttribute('text-anchor')) {
          if (label.getAttribute('text-anchor') === 'middle') {
            label.setAttribute('fill', '#ffffff');
          }
        }
      });


    }

    function setBorderRadius() {
      Array.prototype.forEach.call(container.getElementsByTagName('rect'), function (rect) {
        // if (parseFloat(rect.getAttribute('x')) > 0) {
        rect.setAttribute('rx', 5);
        rect.setAttribute('ry', 20);
        // }
      });
    }

  }





}
