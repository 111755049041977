import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'process-actions',
  templateUrl: './process-actions.component.html',
  styleUrls: [ './process-actions.component.css' ]
} )
export class ProcessActionsComponent implements OnInit {

  constructor () {
    //
  }

  @Output( 'onAddClick' ) onAddClick: EventEmitter<void> = new EventEmitter();
  @Output( 'onBackClick' ) onBackClick: EventEmitter<void> = new EventEmitter();
  @Output( 'onFilesClick' ) onFilesClick: EventEmitter<void> = new EventEmitter();
  @Output( 'onConfirmClick' ) onConfirmClick: EventEmitter<void> = new EventEmitter();
  @Output( 'onPublishClick' ) onPublishClick: EventEmitter<void> = new EventEmitter();

  @Input( 'showSteps' ) showSteps: boolean = true;
  @Input( 'completeSteps' ) completeSteps: boolean = false;

  @Input( 'setAddButton' ) setAddButton: any = {
    showAddButton: false,
    disableAddButton: false,
    addButtonText: 'Añadir',
  }

  @Input( 'setBackButton' ) setBackButton: any = {
    showBackButton: false,
    disableBackButton: false,
    backButtonText: 'Regresar',
  }

  @Input( 'setFilesButton' ) setFilesButton: any = {
    showFilesButton: false,
    disableFilesButton: false,
    filesButtonText: 'Archivos',
  }

  @Input( 'setConfirmButton' ) setConfirmButton: any = {
    showConfirmButton: false,
    disableConfirmButton: false,
    confirmButtonText: 'Confirmar',
  }

  @Input( 'setPublishButton' ) setPublishButton: any = {
    showPublishButton: false,
    disablePublishButton: false,
    publishButtonText: 'Publicar',
  }

  ngOnInit(): void {
    //
  }

  public restScroll(){
    window.scrollTo(0,0);
  }

}
