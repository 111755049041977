import { Injectable } from '@angular/core';
import { NotificationQueue } from '../models/notificationQueue';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationQueuesService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  createNotificationQueue(data?: NotificationQueue) {
    let url: string = `/NotificationQueues`
    return this._httpService.httpPost(url, data);
  }
  
}


