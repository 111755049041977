export enum ProcessNameEnum {
  selfAssessment = 'Autoevaluación', 
  accreditation = 'Acreditación',
  calls = 'Convocatorias'
}

export enum ProcessIdEnum  {
  selfAssessment = 1,
  accreditation = 2,
  calls = 3,
};