import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'role-button',
  templateUrl: './role-button.component.html',
  styleUrls: ['./role-button.component.css']
})
export class RoleButtonComponent {

  constructor() { }

  public params: any;
  public label: string;

  agInit(params: any): void {
    this.params = params;
    this.label = params.label || null;
  }

  /**
   * On button click
   */
  public onClick() {
    //Checks if onClick is a function
    if (this.params.onClick instanceof Function) {
      //Then executes the function
      this.params.onClick( this.params );
    }
  }

}
