import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PaymentOrderService } from '../services/payment-order.service';

@Directive({
  selector: '[allowedRoles]'
})

export class HasRoleDirective implements OnInit {
  
  constructor( 
    private _paymentOrderService: PaymentOrderService, 
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) { }

  @Input( 'allowedRoles' ) allowedRoles: string[] = [];
  @Input( 'allowedRolesThen' ) thenTemplate: TemplateRef<any> | null = null;
  @Input( 'allowedRolesElse' ) elseTemplate: TemplateRef<any> | null = null;


  ngOnInit(): void {
    const hasRole = this.checkIfUserHasAllowedRoles();
    if ( hasRole ) {
      const templateToRender = this.thenTemplate || this._templateRef;
      this._viewContainerRef.createEmbeddedView( templateToRender );
    } else {
      const templateToRender = this.elseTemplate || null;
      if ( templateToRender ) {
        this._viewContainerRef.createEmbeddedView( templateToRender );
      } else {
        this._viewContainerRef.clear();
      }
    }
  }
  private checkIfUserHasAllowedRoles(): boolean {
    return this.allowedRoles.some( role => this._paymentOrderService.getUserRoles.includes( role.toLowerCase() ) );
  }
}
