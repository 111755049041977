import { Component,Input, OnInit, SimpleChanges } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Category } from 'src/app/models/category';
import { Course } from 'src/app/models/course';
import { CourseOffer } from 'src/app/models/courseOffer';
import { Modality } from 'src/app/models/modality';
import { OferState } from 'src/app/models/oferState';
import { School } from 'src/app/models/school';
import { TypesCourse } from 'src/app/models/typesCourse';

import { CourseService } from 'src/app/services/course.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'directiva-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.css']
})
export class FilterBarComponent implements OnInit {

  @Input()
  mySchool: number
  @Input()
  yearSearch: number

  public viewFilterForm: boolean = false


  public schools: School[] = [];
  public categories: Category[] = [new Category()];
  public typeCourses: TypesCourse[] = [new TypesCourse()];
  public modalities: Modality[] = [new Modality()];
  public oferStates: OferState[] = [new OferState()];
  public courses: Course[] = [new Course()];
  public coursesOfers: CourseOffer[] = [new CourseOffer()];

  public typeCoursesAll: TypesCourse[] = [new TypesCourse()];
  public coursesAll: Course[] = [new Course()];
  public coursesOfersAll: CourseOffer[] = [new CourseOffer()];

  public schoolsSelected: School[] = [];
  public categoriesSelected: Category[] = [new Category()];
  public typeCoursesSelected: TypesCourse[] = [new TypesCourse()];
  public modalitiesSelected: Modality[] = [new Modality()];
  public oferStatesSelected: OferState[] = [new OferState()];
  public coursesSelected: Course[] = [new Course()];
  public coursesOfersSelected: CourseOffer[] = [new CourseOffer()];

  public typeSchedules = [{name:"Directiva",id:true},{name:"ExtraDirectiva",id:false}]
  public typeSchedulesSelected = this.typeSchedules;

  subscriptionFilters: Subscription

  constructor(
    private courseService: CourseService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.subscriptionFilters = this.sharedService.sharedCategoryNameFilter.subscribe(data => {
      switch (data.filterName) {
        case "TypeCourse":
          let filtererdData = this.typeCoursesAll.find(e => e.NameTypeCourse == data.value)
          if (!!filtererdData)
          {
            this.typeCoursesSelected = [filtererdData];
            this.onTypeCourseChange();
          }
          else{
            let filtererdData = this.oferStates.find(e => e.nameState == data.value)
            if(!!filtererdData)
            {
              this.oferStatesSelected = [filtererdData];
              this.onStateOferChange();
            }
            else{
              this.typeSchedulesSelected = [this.typeSchedules.find(e => e.name == data.value)]
              this.onTypeScheduleChange();
            }
          }
          break;
        case "Category":
          this.categoriesSelected = [this.categories.find(e => e.NameCategoryCourse == data.value)];
          if (!!this.categoriesSelected[0])
            this.onCategoryChange();
          break;

        case "Recall":
            this.onCourseOferChange();
            break;

        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionFilters.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'mySchool': {
            if (this.mySchool > 0) this.getFixedFilters();
          } break;

          case 'yearSearch': {
            this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
            this.getFilters();
          } break;

        }
      }
    }
  }

  public setSchool(school: School[]) {
    this.schoolsSelected = school
    this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
    this.getFilters();
  }

  public isSelectedSchool(SchoolID): boolean {
    return !(this.schoolsSelected.findIndex(e => e.id == SchoolID) < 0)
  }

  public getFixedFilters() {

    forkJoin([
      this.courseService.getCategories(),
      this.courseService.getModalities(),
      this.courseService.getOferStates(),
      this.courseService.getSchoolsAndChildren(this.mySchool)
    ]).subscribe(Val => {
      this.schools = [];

      [this.categories, this.modalities, this.oferStates, this.schools[0]] = Val;

      this.categoriesSelected = this.categories;

      this.typeCourses = this.categories.map(r => r.TypeCourses).reduce((acc, val) => acc.concat(val), []);
      this.typeCoursesAll = this.typeCourses;
      this.typeCoursesSelected = this.typeCourses;

      this.modalitiesSelected = this.modalities;

      this.oferStatesSelected = this.oferStates;

      this.schools[0].childrenSchools.forEach(school=>{
        this.schools.push(school)
        this.schools.push(...school.childrenSchools)
      })
      
      
      this.schoolsSelected = this.schools;
      this.sharedService.nextyearSchoolFilter({year:this.yearSearch,schools:this.schoolsSelected});
      this.getFilters();

    });
  }

  public getFilters() {

    const courseFilter = {
      where: { "SchoolID": { inq: [this.schoolsSelected.map(e => e.id)] } }
    }

    const courseOferFilter = {
      where: {
        and: [
          { "SchoolID": { inq: this.schoolsSelected.map(e => e.id) } },
          { "Since": { between: [new Date(this.yearSearch, 0), new Date(this.yearSearch + 1, 0)] } }
        ]
      },
      include: {"UserCourses":["militarInfo"]}
    }

    forkJoin([
      this.courseService.getCourses(JSON.stringify(courseFilter)),
      this.courseService.getCoursesOfers(JSON.stringify(courseOferFilter))]).subscribe(
        Val => {
          [this.courses, this.coursesOfers] = Val;

          this.coursesOfers = this.coursesOfers.map(
            csOffer => {
              csOffer.TypeCourseOFer = this.typeCoursesAll.find(e => e.id == csOffer.TypeCourseID);
              csOffer.oferState = this.oferStates.find(e => e.id == csOffer.OferState);
              csOffer.totalInscritos = csOffer.UserCourses.length;

              return csOffer
            }
          )

          this.coursesAll = this.courses;
          this.coursesOfersAll = this.coursesOfers;

          this.getCourses();

        }
      );

  }

  public onCategoryChange() {
    this.typeCourses = this.typeCoursesAll.filter(r => {
      return !(this.categoriesSelected.findIndex(e => e.id == r.CategoryID) < 0);
    });
    this.typeCoursesSelected = this.typeCourses;
    this.onTypeCourseChange();
  }

  public onModalityChange() {
    this.getCoursesOfer()
  }

  public onStateOferChange() {
    this.getCoursesOfer()
  }

  public onTypeScheduleChange(){
    this.getCoursesOfer()
  }

  public onTypeCourseChange() {
    this.getCourses();
  }

  public onCourseChange() {
    this.getCoursesOfer()
  }

  public onCourseOferChange() {
    this.sharedService.nextcourseOfferlist(this.coursesOfersSelected);
  }

  private getCoursesOfer() {
    this.coursesOfers = this.coursesOfersAll.filter(r => {
      if(!(this.typeSchedulesSelected.findIndex(e => e.id == r.IsDirectiva) < 0))
        if (!(this.modalitiesSelected.findIndex(e => e.id == r.ModalityID) < 0))
          if (!(this.oferStatesSelected.findIndex(e => e.id == r.OferState) < 0))
            if (!(this.coursesSelected.findIndex(e => e.id == r.CourseID) < 0))
              return true
      return false
    });
    this.coursesOfersSelected = this.coursesOfers;
    this.onCourseOferChange()
  }

  private getCourses() {
    this.courses = this.coursesAll.filter(r => {
      return !(this.typeCoursesSelected.findIndex(e => e.id == r.TypeCourseID) < 0);
    });
    this.coursesSelected = this.courses;
    this.onCourseChange();
  }

}
