import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeightingInputComponent } from './weighting-input.component';



@NgModule( {
  declarations: [
    WeightingInputComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    WeightingInputComponent
  ]
} )
export class WeightingInputModule { }
