import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommentsIntcallFieldAnswer } from 'src/app/models/selfAssessment/commentsIntcallFieldAnswer';
import { GradeIntcallFieldAnswer } from 'src/app/models/selfAssessment/gradeIntcallFieldAnswer';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import { UserIntcallFieldAnswer } from 'src/app/models/selfAssessment/userIntcallFieldAnswer';
import { UserIntcallFieldDocuments } from 'src/app/models/selfAssessment/userIntcallFieldDocuments';
import { HttpService } from '../http.service';

@Injectable( {
  providedIn: 'root'
} )
export class SelfAssessmentService {

  constructor ( private _httpService: HttpService ) {
    //
  }

  public getUserList( filter?: string ): Observable<any> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/ViewUserInfos${ newFilter }` );
  }

  public getCurrentUser() {
    const filter = JSON.stringify( {
      include: [ 'roleMappings', 'roles' ]
    } );
    return this._httpService.httpGet( `/Userapps/me?filter=${ filter }` );
  }

  public getUserIntCallAnswers( filter?: string ) {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/UserIntcallAnswers${ newFilter }` );
  }

  public getIntcalls( filter?: string ) {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/Intcalls${ newFilter }` );
  }

  public getIntcallModules( filter?: string ) {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/IntcallModules${ newFilter }` );
  }

  public getIntcallDocuments(intcallID:number){
    const newFilter = JSON.stringify({
      where: { intcallID }
    });

    return this._httpService.httpGet( `/DocumentIntcalls?filter=${ newFilter }` );
  }

  public setIntcall( data: any ) {
    return this._httpService.httpPost( '/Intcalls', data );
  }

  public patchIntcall( data: any ) {
    return this._httpService.httpPatch( '/Intcalls', data );
  }

  public setJudgeIntcall( data: any ) {
    return this._httpService.httpPost( '/JudgeIntcalls', data );
  }

  public patchJudgeIntcall(  data: any ) {
    return this._httpService.httpPatch( '/JudgeIntcalls', data );
  }

  public setIntcallModules( data: any ) {
    return this._httpService.httpPost( '/IntcallModules', data );
  }

  public setIntcallFields( data: any ) {
    return this._httpService.httpPost( '/IntcallFields', data );
  }

  public setDocumentIntcall( data: any ) {
    return this._httpService.httpPost( '/DocumentIntcalls', data );
  }

  public deleteIntCallDocument(id:number){
    return this._httpService.httpDelete( `/DocumentIntcalls/${id}` );
  }

  /** David */

  getAllIntcalls(): Observable<any[]> {
    let url: string = `/Intcalls`
    return this._httpService.httpGet( url );
  }
  getIntcallById( idIntcall: number ): Observable<IntcallI> {
    let filter = {
      include: [
        {
          intcallModules: {
            intcallFields: { 'userIntcallFieldAnswers': "GradeIntcallFieldAnswers" }
          }
        },
        {
          judgeIntcalls: "Userapp"
        },
        {
          userIntcallAnswers: [
            {
              UserIntcallFieldAnswers: [
                {
                  CommentsIntcallFieldAnswers: "Userapp"
                },
                {
                  GradeIntcallFieldAnswers: "Userapp"
                },
                "intcallFields",
                "UserIntcallFieldDocumentss"
              ]
            },
            "Userapp",
            {
              "intcall": { "judgeIntcalls": "Userapp" }
            },
            "UserIntcallFieldDocumentss"
          ]
        },
        "documentIntcalls"
      ]
    }
    let url: string = `/Intcalls/${ idIntcall }?filter=${ JSON.stringify( filter ) }`
    return this._httpService.httpGet( url );
  }
  postUserIntcallAnswer( data: UserIntcallAnswer ): Observable<UserIntcallAnswer> {
    let url: string = `/UserIntcallAnswers`
    return this._httpService.httpPost( url, data );
  }
  postUserIntcallFieldAnswer( data: UserIntcallFieldAnswer ): Observable<UserIntcallFieldAnswer> {
    let url: string = `/UserIntcallFieldAnswers`
    return this._httpService.httpPost( url, data );
  }
  postCommentsIntcallFieldAnswers( data: CommentsIntcallFieldAnswer ): Observable<CommentsIntcallFieldAnswer> {
    let url: string = `/CommentsIntcallFieldAnswers`
    return this._httpService.httpPost( url, data );
  }
  postUserIntcallFieldDocuments( data: object ): Observable<UserIntcallFieldDocuments> {
    let url: string = `/UserIntcallFieldDocuments`
    return this._httpService.httpPost( url, data );
  }
  patchUserIntcallFieldAnswer( data: UserIntcallFieldAnswer ): Observable<UserIntcallFieldAnswer> {
    let url: string = `/UserIntcallFieldAnswers`
    return this._httpService.httpPatch( url, data );
  }
  patchUserIntcallAnswer( data: object ): Observable<UserIntcallAnswer> {
    let url: string = `/UserIntcallAnswers`
    return this._httpService.httpPatch( url, data );
  }
  patchGradeIntcallFieldAnswer( data: object ): Observable<GradeIntcallFieldAnswer> {
    let url: string = `/GradeIntcallFieldAnswers`
    return this._httpService.httpPatch( url, data );
  }
  getCommentsIntcallFieldAnswersByID( idComment: number ): Observable<CommentsIntcallFieldAnswer> {
    let filter = {
      include: [
        "Userapp"
      ]
    }
    let url: string = `/CommentsIntcallFieldAnswers/${ idComment }?filter=${ JSON.stringify( filter ) }`
    return this._httpService.httpGet( url );
  }

  private saveEvent = new BehaviorSubject( false );
  sharedSaveEvent = this.saveEvent.asObservable();
  private saveFinishEvent = new BehaviorSubject( false );
  sharedSaveFinishEvent = this.saveFinishEvent.asObservable();
  private saveEvaluador = new BehaviorSubject( false );
  sharedSaveEvaluador = this.saveEvaluador.asObservable();
  private saveEvauadorFinishEvent = new BehaviorSubject( false );
  sharedSaveEvaluadorFinishEvent = this.saveEvauadorFinishEvent.asObservable();

  /*** Message Emiter */
  nextSaveEvent() {
    this.saveEvent.next( true )
  }
  nextSaveFinishEvent() {
    this.saveFinishEvent.next( true )
  }
  nextEvaluadorCalificaEvent() {
    this.saveEvaluador.next( true )
  }
  nextEvaluadorCalificaFinalizaEvent() {
    this.saveEvauadorFinishEvent.next( true )
  }


}
