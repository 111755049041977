import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: [ './user-card.component.css' ]
} )
export class UserCardComponent implements OnInit {

  constructor () {
    //
  }


  //Sends a call when the user is deleted
  @Output( 'deleteUser' ) deleteUser: EventEmitter<any> = new EventEmitter();

  //Sends the user percentage
  @Output( 'getUserPercentage' ) getUserPercentage: EventEmitter<any> = new EventEmitter();

  //Receives the user info
  @Input( 'user' ) user: any = {};

  //Allow or not to delete the user
  @Input( 'cantDeleteUser' ) cantDeleteUser: boolean = true;

  //Receives the user percentage
  @Input( 'userPercentage' ) userPercentage: number = undefined;

  //Allow to disable input
  @Input( 'disabled' ) disabled: boolean = false;


  ngOnInit(): void {
    //
  }

  /**
   * Limits the input value and send the percentage value when input value changes
   * @param event 
   */
  public getPercentage( event: any ): any {
    const value = event.target.value;
    if ( value > 100 ) {
      event.target.value = 100;
    } else if ( value < 1 ) {
      event.target.value = 1;
    }

    this.userPercentage = Number( event.target.value );
    this.getUserPercentage.emit( { value: this.userPercentage, userId: this.user.id } );
  }

}
