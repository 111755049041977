import { DOCUMENT } from '@angular/common';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ViewContainerRef, ElementRef, Inject, HostListener } from '@angular/core';
import { SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { NewModuleComponent } from './new-module/new-module.component';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';

@Component( {
  selector: 'general-info-section',
  templateUrl: './general-info-section.component.html',
  styleUrls: [ './general-info-section.component.css' ]
} )
export class GeneralInfoSectionComponent implements OnInit {

  constructor (
    @Inject( DOCUMENT ) private _document: Document,
    private _elementRef: ElementRef,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService
  ) {
    //
  }

  @ViewChild( 'newModuleContainer', { read: ViewContainerRef } ) newModuleContainer: ViewContainerRef;

  //Sends the name of the section to be activated
  @Output( 'onChangeSection' ) onChangeSection: EventEmitter<SectionToShowEnum> = new EventEmitter();

  //Sends the current section info
  @Output( 'onSendGeneralInfo' ) onSendGeneralInfo: EventEmitter<any> = new EventEmitter();

  //Sends the ids of the modules created for paging
  @Output( 'modulesLength' ) modulesLength: EventEmitter<number> = new EventEmitter();

  //Sends the 
  @Output( 'currentModuleIndex' ) currentModuleIndex: EventEmitter<number> = new EventEmitter();

  //Stores the name of the current section, obtained from the url
  @Input( 'sectionName' ) sectionName: string = '...';

  //It retrieves a reference to an HTML element with a specific ID and then scrolls the window to the top of that element with a smooth scrolling effect.
  @Input( 'moduleIndexToScrollTo' ) set moduleIndexToScrollTo( value: number ) {
    const items = this._elementRef.nativeElement.querySelectorAll( '.inner-sections .inner-row' );
    const element = items[ value  ];

    if ( element ) {
      const offset = 64;
      const topPosition = element.getBoundingClientRect().top - this._document.body.getBoundingClientRect().top - offset;

      window.scrollTo( {
        behavior: 'smooth',
        top: topPosition,
      } );
    }
  }

  //It is used to store the reference to a specific section.
  public sectionToShow: any = SectionToShowEnum;

  //Stores a reference to the instances of the newModule for later deleting them or getting the value of their fields
  public newModuleInstances: any[] = [];

  //Creates a new id when creating a new cardModule
  private _newModuleInstanceId: number = 0;

  private _allInnerRows: any[] = [];

  ngOnInit(): void {
    //
  }

  /**
  * This function adds a new NewModuleComponent to the view and handles its deletion when the user clicks on the delete button.
  */
  public onAddButtonClick() {
    this._newModuleInstanceId++;

    const fieldCardInstance = this.newModuleContainer.createComponent( NewModuleComponent );
    fieldCardInstance.setInput( 'sectionName', this.sectionName );
    fieldCardInstance.setInput( 'moduleId', this._newModuleInstanceId );
    this.newModuleInstances.push( fieldCardInstance );

    this.getActiveModules();

    //subscribes to the deleteCard event of the FieldCardComponent instance, which is emitted when the user clicks on the delete button.
    fieldCardInstance.instance.deleteModule.subscribe( moduleId => {
      const moduleToDelete = this.newModuleInstances.find( e => e.instance.moduleId === moduleId );
      moduleToDelete ? moduleToDelete.destroy() : console.log( 'No card found with the given id' );
      this.newModuleInstances = this.newModuleInstances.filter( e => e.instance.moduleId !== moduleId );
      this.getActiveModules();
    } )
  }

  /**
   * Gets the ids of the active modules to pass them as a reference to the paging component.
   */
  private getActiveModules() {
    //Get all active modules ids
    this.modulesLength.emit( this.newModuleInstances.length );
  }

  /**
   * Handles the click event of the "Publish" button.
   * If the module data is valid, emits the "onChangeSection" and "onSendGeneralInfo" events.
   */
  public onPublishButtonClick() {
    if ( !this.isModuleValid() )
      return;

    this.onChangeSection.emit( this.sectionToShow.publishSection );
    this.onSendGeneralInfo.emit( this.newModuleInstances.map( e => e.instance.getModuleData() ) );
  }

  /**
   * Validates the module information for the new module instances.
   * @returns true if the validation succeeded, false otherwise.
   */
  public isModuleValid(): boolean {
    let totalPercentage = 0;

    for ( const [ i, instance ] of this.newModuleInstances.entries() ) {
      const moduleData = instance.instance.getModuleData();
      if ( this.handleModuleErrors( moduleData, i ) )
        return false;

      totalPercentage += moduleData?.moduleInfo.percentageModule ?? 0;
    }

    if ( totalPercentage !== 100 ) {
      const errorMessage = 'La ponderación en la lista de procesos debe sumar 100%';
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', errorMessage );
      return false;
    }

    return true;
  }

  /**
   * Handles the click event of the "Files" button.
   * If the module data is valid, emits the "onChangeSection" event to show the files section.
   */
  public onFilesButtonClick() {
    if ( !this.isModuleValid() )
      return;

    this.onSendGeneralInfo.emit( this.newModuleInstances.map( e => e.instance.getModuleData() ) );
    this.onChangeSection.emit( this.sectionToShow.filesSection );
  }

  /**
   * Handles module errors and displays error messages if necessary.
   * @param moduleData The module data object to check for errors.
   * @param index The index of the module in the module instances array.
   * @returns A boolean indicating whether there are errors or not.
   */
  private handleModuleErrors( moduleData: any, index: number ): boolean {
    const { noIntcallFields, invalidIntcall, invalidIntcallFields, invalidWeighting } = moduleData;
    const sectionNumber = index + 1;

    if ( invalidIntcall ) {
      const errorMessage = `Al parecer tienes campos inválidos en la sección #${ sectionNumber }`;
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', errorMessage );
      return true;
    }

    if ( noIntcallFields ) {
      const errorMessage = `Aún no has agregado ningún campo al módulo #${ sectionNumber }`;
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', errorMessage );
      return true;
    }

    if ( invalidIntcallFields ) {
      const errorMessage = `Al parecer tienes uno o más campos inválidos en la lista de campos en la sección #${ sectionNumber }`;
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', errorMessage );
      return true;
    }

    if ( invalidWeighting ) {
      const errorMessage = `La ponderación en la lista de campos en la sección #${ sectionNumber } debe sumar 100%`;
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', errorMessage );
      return true;
    }

    return false;
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const items = this._elementRef.nativeElement.querySelectorAll( '.inner-sections .inner-row' );

    for (let i = 0; i < items.length; i++) {
      const itemTop = items[i].getBoundingClientRect().top + window.pageYOffset;
      const distance = Math.abs(scrollTop - itemTop);

      if (distance <= 95) {
        this.currentModuleIndex.emit( i )
        break;
      }
    }
  }
}