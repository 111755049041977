import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InstrumentsComponent } from './instruments.component';
import { AgGridModule } from 'ag-grid-angular';
import { StatePillComponent } from './state-pill/state-pill.component';
import { StateSelectComponent } from './state-select/state-select.component';
import { RouterModule } from '@angular/router';
import { ProcessCardComponent } from './process-card/process-card.component';

@NgModule( {
  declarations: [
    InstrumentsComponent,
    StateSelectComponent,
    StatePillComponent,
    ProcessCardComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule,
    RouterModule,
  ],
  exports: [
    InstrumentsComponent,
  ],
  providers: [
    DatePipe
  ]
} )
export class InstrumentsModule { }
