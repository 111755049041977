
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { concat, of } from 'rxjs';
import { catchError, switchMap, tap, toArray } from 'rxjs/operators';
import { DOCUMENTS } from 'src/app/constants/payments';
import { PaymentOrderService } from 'src/app/services/payment-order.service';
import { XlsxService } from 'src/app/services/xlsx.service';
import { swalClose, swalError, swalLoading, swalSuccess, swalUpdateLoading } from 'src/utils/alerts';

@Component({
  selector: "upload-cedoc-file",
  templateUrl: "./upload-cedoc-file.component.html",
  styleUrls: ["./upload-cedoc-file.component.css"],
})
export class UploadCedocFileComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef;

  @Output("onSubmit") onSubmit: EventEmitter<void> = new EventEmitter();
  @Input("loadingReceipts") loadingReceipts: boolean = false;
  @Input("itemsPerPage") itemsPerPage: number = 0;
  @Input("schools") schools: any[] = [];
  @Input("currentUser") currentUser: any = undefined;

  private _data: any[] = [];
  get data(): any[] {
    return this._data;
  }

  public previewData: any[] = [];
  public previewTableHeader: string[] = [];
  public currentPage: number = 0;

  private _defaultFields = [
    "ODC",
    "UNIDAD",
    "CONVENIO",
    "CODIGO",
    "DESCRIPCION",
    "APELLIDOS Y NOMBRES COMPLETOS",
    "DOCUMENTO",
    "NUMERO DOCUMENTO",
    "DIRECCIÓN",
    "CODIGO MUNICIPIO",
    "CORREO ELECTRONICO1",
    "CORREO ELECTRONICO 2",
    "TELEFONO",
    "VALOR A CONSIGNAR",
    "POSICION CATALOGO BIENES Y SERVICIOS SIIF",
    "EDUCACION",
    'FECHA RECIBO',
  ];

  public fileName = "";
  public fileToUpload?: File;
  public fileHasErrors: boolean = false;
  public uploadFileText: string = "Elija el archivo";
  public showTablePreview: boolean = false;
  public pageErrors: any[] = [];
  public previewTableTotalPages: number = 0;

  constructor(
    private _xlsxService: XlsxService,
    private _paymentOrder: PaymentOrderService,
    @Inject(DOCUMENT) private document: Document
  ) { }
  ngOnInit(): void { }

  onFileInput(event: any) {
    this.fileToUpload = <File>event.target.files[0];
    if (this.fileToUpload.size > 5000000) {
      this.uploadFileText = "Error de Archivo";
      this.fileHasErrors = true;
      return;
    }

    this.uploadFileText = this.fileToUpload.name;
    this.fileHasErrors = false;
    this.fileReader(event);
  }

  public fileReader(event: any) {
    const reader: FileReader = new FileReader();

    swalLoading({
      title: "Cargando archivo",
      message: "Esto puede tomar unos minutos.",
    });
    setTimeout(() => {
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const rawData = this._xlsxService.importFromFileInBatches(bstr);

        this.processFile(rawData);
      };

      reader.readAsBinaryString(event.target.files[0]);
    }, 1000);
  }

  /**
   * Create batches
   * @param rawData
   */
  private processFile(rawData: any[]) {
    this.previewTableHeader = rawData[0].filter(
      (header) => header.trim() !== ""
    );

    //Validate headers
    const isHeaderValid: boolean =
      this._defaultFields.length === this.previewTableHeader.length &&
        this._defaultFields.every((field, i) => field === this.previewTableHeader[i]);

    if (!isHeaderValid) {
      const missingFields = this._defaultFields.map((e) => `"${e}"`).join("<br>");
      swalError({
        html: `La cabecera del documento parece incorrecta. Verifica el siguiente orden de campos:<br><br> ${missingFields}`,
        confirmCallback: () => this.resetFileInfo(),
      });
      return;
    }

    //Filters rows containing more than 8 non-empty cells
    rawData = rawData.filter(
      (row: any[]) =>
        row.filter((cell) => cell.toString().trim() !== "").length >= 8
    );
    rawData.shift(); //Delete first column

    this.pageErrors = [];
    const allRows = [];
    for (let i = 0; i < rawData.length; i++) {
      const data = rawData[i];

      const dueDate = new Date(data[16]);
      dueDate.setDate(dueDate.getDate() + 30);

      //Due date + 30 days
      const finalDueDate = `${dueDate.getFullYear()}-${dueDate.toLocaleString(
        "en-US",
        { month: "2-digit" }
      )}-${dueDate.toLocaleString("en-US", { day: "2-digit" })}`;
      
      const pos = i + 1;
      const validatedFields = {
        id: (new Date().valueOf() + pos).toString(),
        Code: this.validateFields("school", data[1], 'UNIDAD'),
        EcollectValue: this.validateFields("number", data[2], 'CONVENIO'),
        CODIGO: data[3], //No necessary for db
        Description: this.validateFields("string", data[4], 'DESCRIPCION'),
        FirstName: this.validateFields("string", data[5], 'APELLIDOS Y NOMBRES COMPLETOS'),
        documentType: this.validateFields("documentType", data[6], 'DOCUMENTO'), //Get by document acronym
        lasName: "N/A", //Mandatory field,
        UserId: this.currentUser.id, //User id
        document: this.validateFields("string", data[7], 'NUMERO DOCUMENTO'),
        Address: this.validateFields("string", data[8], 'DIRECCIÓN'),
        municipalCode: this.validateFields("municipalCode", data[9], 'CODIGO MUNICIPIO'),
        firsEmail: this.validateFields("string", data[10], 'CORREO ELECTRONICO1'),
        lastEmail: this.validateFields("string", data[11], 'CORREO ELECTRONICO 2'),
        Telephone: this.validateFields("number", data[12], 'TELEFONO'),

        //TotalPayment: this.validateFields("number", e[13]),
        paymentDate1: this.validateFields("number", data[13], 'VALOR A CONSIGNAR'),

        AssetsAndServicesCatalog: this.validateFields("string", data[14], 'POSICION CATALOGO BIENES Y SERVICIOS SIIF'),
        Education: this.validateFields("string", data[15], 'EDUCACION'),

        DueDate: this.validateFields("date", finalDueDate, 'FECHA RECIBO'),
        issueDate: this.validateFields("date", data[16], ''),

        paidTo: JSON.stringify([`${data[7]}`]),

        fileAdminName: this.fileName,
        PaymentState: false,
      };

    let hasError = false;
    const errorColumns = {};

    Object.keys(validatedFields).forEach((key) => {
      if (validatedFields[key] && validatedFields[key].error) {
        hasError = true;
        if (!errorColumns[pos])
          errorColumns[pos] = [];

        const defaultFieldKey = this._defaultFields.find(
          (e) => e === validatedFields[key].colName
        );

        if (defaultFieldKey) 
          errorColumns[pos].push(defaultFieldKey);
      }
    });

    if (hasError)
      this.pageErrors.push({ row: pos + 1, columns: errorColumns[pos] });

    allRows.push(validatedFields);
  }

    this._data = [...allRows];
    this.previewTableTotalPages = this._data.length;
    this.previewData = allRows.slice(0, this.itemsPerPage);

    swalClose();
    this.showTablePreview = true;
  }

  /**
   * Searches whether or not the records in the table are already in the database.
   */
  public submitReceipt() {
    if (this._data.length === 0 || this.loadingReceipts) return;

    swalLoading({
      title: "Cargando datos",
      message: "Tiempo estimado: 0 minutos",
    });
    this.loadingReceipts = true;

    //Shows table with errors
    if (this.pageErrors.length > 0 && this.pageErrors.length <= 10) {
      const content = `
        <p style="margin-bottom: 10px !important; color:#514d6a; ">Tienes errores en las siguientes columnas</p>

        <table style="width:100%;">
          <thead>
            <tr style="color:#514d6a;">
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Fila</th>
              <th style="border: solid 1px #d3dae585; text-align: center; font-weight: normal; padding:5px;">Columnas</th>
            </tr>
          </thead>
          <tbody style="color:#514d6a;">
            ${this.pageErrors
          .map(
            (e, i) => `
            <tr style="${i % 2 === 0 ? "background-color: #1e438c0f;" : ""}">
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.row
              }</td>
              <td style="border: solid 1px #d3dae585; padding:5px;">${e.columns.join(
                ", "
              )}</td>
            </tr>`
          )
          .join("")}
          </tbody>
        </table>`;

      swalError({
        title: "¡Error!",
        html: content,
      });

      this.loadingReceipts = false;
      return;
    }

    //Download txt errors file
    if (this.pageErrors.length > 10) {
      const content = `
        <p style="margin-bottom: 10px !important; color:#514d6a; ">Tienes múltiples errores. Para visualizarlos, por favor descarga el archivo adjunto.</p>
        <a href="#" style="color: #0190FE" id="downloadErrors">Descargar errores ↘</a>
        `;
      swalError({
        title: "¡Error!",
        html: content,
      });

      this.document.getElementById("downloadErrors").onclick = () =>
        this.downloadErrors();
      this.loadingReceipts = false;
      return;
    }

    const receipts = this._data.map((e) =>
      this._paymentOrder.createOrUpdateReceipt(e).pipe(
        catchError((error) => {
          console.log(error);
          console.log(`Error in: ${e}`);
          return of([]);
        })
      )
    );

    const startTime = performance.now();
    let completedRequests = 0;

    concat(...receipts)
      .pipe(
        tap(() => {
          completedRequests++;
          const remainingTime = this.calculateWaitTime(
            receipts.length,
            completedRequests,
            startTime
          );
          swalUpdateLoading({
            title: "Cargando datos",
            message: `Tiempo estimado: ${remainingTime} minutos`,
          });
        }),
        toArray(),
        switchMap(() => {
          const emails = this._data.map((e) => e.firsEmail);
          return this.sendNotification(emails);
        })
      )
      .subscribe({
        complete: () => {
          this.loadingReceipts = false;
          swalSuccess({ message: "Archivo cargado con éxito" });
          this.resetFileInfo();
          this.onSubmit.emit();
        },
        error: (err) => {
          this.loadingReceipts = false;
          swalError({
            message: `Error: ${err.message ||
              "Error interno del servidor, por favor comuníquese con un administrador"
              }`,
          });
          console.log(err);
        },
      });
  }

  /**
   * reset the file upload button
   */
  public resetFileInfo() {
    this.showTablePreview = false;
    this.previewData = [];
    this.fileInput.nativeElement.value = "";
    this.uploadFileText = "Elija el archivo";
    this.fileToUpload = undefined;
    this.fileName = "";
  }

  //Validate fields
  private validateFields(valueType: string, value: any, colName: string) {
    if (value === null || value === undefined || value === "")
      return { colName, error: "Por favor, completa este campo, es obligatorio." };

    switch (valueType) {
      case "string":
        //Remove double spaces
        const finalValue = value.toString().replace(/\s{2,}/g, " ").trim();
        if(!finalValue || finalValue.toLowerCase() === 'null')
          return {
            colName,
            error: 'Por favor, completa este campo, es obligatorio.',
          };

        return finalValue
      case "number":
        if (!isNaN(value))
          return value;

        return {
          colName,
          error: `${value} no es un número válido. Asegúrate de ingresar un valor numérico para este campo.`,
        };
      case "date":
        const date = new Date(value);
        if (!isNaN(date.getTime()))
          return date.toISOString();

        return {
          colName,
          error: "La fecha ingresada no es válida. Por favor, asegúrate de ingresar una fecha válida.",
        };
      case "school":
        const schoolAcronym = value.toString().trim().toUpperCase();
        const school = this.schools.find((e) => e.schoolAcronym == schoolAcronym);
        if (school)
          return school.id;

        return {
          colName,
          error: `No se encontró ninguna escuela con el acrónimo "${value}". Por favor, verifica el acrónimo e inténtalo de nuevo.`,
        };
      case "documentType":
        const documentAcronym = value.toString().trim().toUpperCase();
        const document = DOCUMENTS.find((e) => e.documentAcronym == documentAcronym);
        if (document)
          return document.id;

        return {
          colName,
          error: `No se encontró ningún documento con el acrónimo "${value}". Por favor, verifica el acrónimo e inténtalo de nuevo.`,
        };
      case "municipalCode":
        return value.toString().replace(/\D/g, "");
      default:
        return "";
    }
  }

  /**
   * Sends a notification to the users selected
   */
  private sendNotification(emailList: string[]) {
    const date: Date = new Date();
    date.setMinutes(date.getMinutes() + 1);

    const notificationData = {
      namesTO: JSON.stringify(["Notificación de nuevo recibo"]),
      emailsTo: JSON.stringify(emailList),
      msg: JSON.stringify([{ message: "¡Tienes un nuevo recibo pendiente de revisión!" }]),
      timeToSend: date.toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: "email",
    };

    return this._paymentOrder.setNotificationQueue(notificationData);
  }

  /**
   * Calculate elapsed time
   */
  private calculateWaitTime(
    totalRequests,
    completedRequests: number,
    startTime: DOMHighResTimeStamp
  ) {
    const currentTime = performance.now();
    const elapsedTime = currentTime - startTime;
    const elapsedTimeInMinutes = elapsedTime / 60000;
    const averageTimePerRequest = elapsedTimeInMinutes / completedRequests;
    const remainingTime = Math.max(
      0,
      averageTimePerRequest * (totalRequests - completedRequests)
    );
    return remainingTime.toFixed(2);
  }

  //Download text file with errors
  private downloadErrors() {
    let content = "FILA\tCOLUMNAS\n";
    this.pageErrors.forEach(
      (error) => (content += `${error.row}\t${error.columns}\n`)
    );

    //-----------------------------------<a></a>
    const element = this.document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(content)
    );
    element.setAttribute("download", "errores.txt");

    element.style.display = "none";
    this.document.body.appendChild(element);
    element.click();
    this.document.body.removeChild(element);
  }

  public renderField(value: any, valueType: string = "default") {
    switch (valueType) {
      case "school":
        const school = this.schools.find((e) => e.id == value);
        return school ? school.schoolAcronym : value;
      case "documentType":
        const document = DOCUMENTS.find((e) => e.id == value);
        return document ? document.documentAcronym : value;
      default:
        return value;
    }
  }

  public showFieldError(error: any) {
    swalError({
      title: "",
      message: error.error,
    });
  }
}