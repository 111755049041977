import { Component, Input, OnInit } from '@angular/core';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { JudgeIntcallI } from 'src/app/models/selfAssessment/judgeIntCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';

@Component({
  selector: 'se-table-results',
  templateUrl: './se-table-results.component.html',
  styleUrls: ['./se-table-results.component.css']
})
export class SeTableResultsComponent implements OnInit {

  @Input() myUserIntcallAnswer: UserIntcallAnswer;
  @Input() intcall: IntcallI;
  @Input() idUserToViewData: number;

  public showTableData: boolean = false;
  public tableData = [];

  constructor() {/** */}

  ngOnInit(): void {
    this.calculateUserDataToEvaluate();
    this.calculateDataTable();
  }

  public userEvaluated: UserIntcallAnswer;
  public listaCalificadores: JudgeIntcallI[] = [];
  calculateUserDataToEvaluate(): void {
    this.intcall.userIntcallAnswers.forEach(userResponser => {
      if (userResponser.userID == this.idUserToViewData) {
        this.userEvaluated = userResponser;
        this.showDataTable();
      }
    });
  }

  showDataTable(): void {
    this.showTableData = true;
  }

  calculateDataTable(): void {
    this.tableData = []
    this.intcall.intcallModules.forEach(modlue => {
      let dataModule = {};
      let dataModuleFields = [];
      modlue.intcallFields.forEach(fields => {
        let allGrades = [];
        this.intcall.judgeIntcalls.forEach(judge => {
          this.myUserIntcallAnswer.UserIntcallFieldAnswers.forEach(fieldAnswer => {
            if (fieldAnswer.intcallFieldID == fields.id) {
              let grade;
              let gradeAnswer_userID;
              fieldAnswer.GradeIntcallFieldAnswers.forEach(gradeAnswer => {
                if (judge.userID == gradeAnswer.userID) {
                  grade = gradeAnswer.grade;
                }
                gradeAnswer_userID = gradeAnswer.userID;
              });
              let data = {
                grade: grade || 0,
                fieldAnswer_intcallFieldID: fieldAnswer.intcallFieldID,
                fields_id: fields.id,
                judge_userID: judge.userID,
                gradeAnswer_userID: gradeAnswer_userID
              }
              allGrades.push(data);
            }
          });
        });
        let dataFields = {
          nameField: fields.nameField,
          idField: fields.id,
          grade: allGrades,
          percent: fields.percentageField,
          value: 0
        }
        dataModuleFields.push(dataFields);
      });
      dataModule = {
        moduleName: modlue.descript,
        fields: dataModuleFields,
        moduleID: modlue.id,
        percent: modlue.percentageModule,
        value: 0
      }
      this.tableData.push(dataModule)
    });
    this.tableData.forEach((module, idxMod) => {
      module.fields.forEach((field, idxField) => {
        field.grade.forEach((grade, idxgrade) => {
          field.value = field.value + grade.grade
        });
        module.value = module.value + ((field.percent/100)*field.value)
      });
    });
    console.log('calculateDataTable',this.tableData);
  }

  calculateJudgeValidation(idJudge: number): number {
    let result = 0;
    this.tableData.forEach((module, idxMod) => {
      module.fields.forEach((field, idxField) => {
        field.grade.forEach((grade, idxgrade) => {
          if (idJudge == grade.judge_userID) result = result + ((grade.grade*field.percent/100)*(module.percent/100));
        });
      });
    });
    return result;
  }

  calculateTotalJudgeValidation(): number {
    let result = 0;
    this.intcall.judgeIntcalls.forEach(judge => {
      result = result + (this.calculateJudgeValidation(judge.userID)*(judge.percentageJudge/100));
    });
    return result;
  }

  test() {
    console.log('***************************');
    console.log('***************************');
    console.log('myUserIntcallAnswer',this.myUserIntcallAnswer);
    console.log('intcall',this.intcall);
  }

}
