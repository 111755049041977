import { Injectable } from '@angular/core';



// Modulos Externos
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Swal from 'sweetalert2';
import { DirectivasService } from './directivas.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CourseOffer } from '../models/courseOffer';
import { School } from '../models/school';



const moment = extendMoment(Moment);


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  private courseOfferlist = new BehaviorSubject([new CourseOffer()]);
  sharedcourseOfferlist = this.courseOfferlist.asObservable();

  private categoryNameFilter = new BehaviorSubject({filterName:"",value:""});
  sharedCategoryNameFilter = this.categoryNameFilter.asObservable();

  private yearSchoolFilter = new BehaviorSubject({year:2017,schools:[]});
  sharedyearSchoolFilter = this.yearSchoolFilter.asObservable();

  
  constructor(
    private _directivasService: DirectivasService,

  ) {
  }

  /*** Message Emiter */
  nextcourseOfferlist(courseOfferlist: CourseOffer[]) {
    this.courseOfferlist.next(courseOfferlist)
  }

  nextyearSchoolFilter(yearSchoolFilter: {year:number,schools:School[]}) {
    this.yearSchoolFilter.next(yearSchoolFilter)
  }

  nextcategoryNameFilter(categoryNameFilter: {filterName:string,value:string}) {
    this.categoryNameFilter.next(categoryNameFilter)
  }


  /***** FUNCIONES QUE NO CONSULTAN API */

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  getUnique(arr, comp) {
    const unique = arr
      .map(e => e[comp])
      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  swalLoading(titleString: string) {
    Swal.fire({
      icon: 'success',
      title: titleString,
      text: 'This Process May Take Some Time',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: function () {
        Swal.showLoading();
      }

    });

  }

  swalEndLoad() {
    Swal.fire({
      icon: 'error',
      timer: 5,

    })

  }




  swalSuccess(titleString: string, textString: string) {
    Swal.fire({
      icon: 'success',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,

    })
  }


  swalError(titleString: string, textString: string) {

    Swal.fire({
      icon: 'error',
      title: titleString,
      text: textString,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    })

  }


  returnDateForm(dateMoment, typeFormat?: string) {

    let format = "YYYY-MM-DD"
    if (typeFormat)
      format = typeFormat

    return moment(dateMoment).add({ days: 1 }).format(format)
  }



  getMySchools(SchoolID: number): Promise<any> {

    return new Promise((resolve, reject) => {
      let schools = [];
      let isCedoc = false;
      let filter1 = {
        include: ["parentSchool",
          { relation: 'childrenSchools', scope: { order: 'NameTSchool ASC' } },
        ]
      };
      this._directivasService.getSchoolInfoByID(SchoolID, JSON.stringify(filter1)).pipe(take(1)).subscribe(data => {
        // NIVEL 0 : CEDOC
        // NIVEL 1 : CENTRO DE EDUCACION
        // NIVEL 2 : ESCUELA

        if (data.childrenSchools.length > 0 && !!(data.parentSchool)) {
          //Si tengo hijos pero el padre es la misma escuela (SOY CEDOC, NIVEL 0) 
          if (SchoolID === data.parentSchool.id) {
            this._directivasService.getSchoolInfo(JSON.stringify({ include: {childrenSchools:["childrenSchools"]} })).pipe(take(1)).subscribe(data2 => {
              schools = data2;
              let returnData = {
                schools: schools,
                isCedoc: true
              }
              console.log("NIVEL 0")
              resolve(returnData)
            })
          } else {
            //Si tengo Hijos  y tengo padre diferente a mi escuela (ESTOY EN EL NIVEL 1)
            schools = [data, ...data.childrenSchools]
            let returnData = {
              schools: schools,
              isCedoc: false
            }
            console.log("NIVEL 1")

            resolve(returnData)
          }

        }
      
        else   {
            // Si  no tengo hijos y si tengo padre (ESTOY EN EL NIVEL 2)
            console.log("NIVEL 2")

            schools = [data];
            let returnData = {
              schools: schools,
              isCedoc: false
            }
            resolve(returnData)

          }
        })

    })

  }

}
