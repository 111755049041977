import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';
import { SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { DocumentIntcallI } from 'src/app/models/selfAssessment/documentIntcall';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'publish-section',
  templateUrl: './publish-section.component.html',
  styleUrls: [ './publish-section.component.css' ]
} )
export class PublishSectionComponent implements OnInit {

  constructor (
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService,
    private _clipboard: Clipboard,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {
    //
  }


  //Gets the intcall data to edit
  private _getCurrentIntcallData: any = {};
  @Input( 'getCurrentIntcallData' ) set getCurrentIntcallData( value: any ) {
    this._getCurrentIntcallData = value;

    if ( Object.keys( value ).length !== 0 ) {

      

      //If the date value is different to '' then set the date inputs to true
      this.showPublishDate = value.dateStart !== '';
      this.showEndDate = value.dateEnd !== '';


      //Set to the the date input the value
      this.publishDate = new Date( value.dateStart );
      this.endDate = new Date( value.dateEnd );

      //Adds judges obtained from the database
      value?.judgeIntcalls.forEach( e => {
        this.judgesToAdd.push( {
          judgeInfo: e.Userapp,
          percentage: e.percentageJudge
        } )

      } )
    }
  }

  get getCurrentIntcallData() {
    return this._getCurrentIntcallData;
  }

  //Send the name of the section to be activated
  @Output( 'onChangeSection' ) onChangeSection: EventEmitter<SectionToShowEnum> = new EventEmitter();

  //Send a check to activate the main form validations
  @Output( 'checkMainFormData' ) checkMainFormData: EventEmitter<boolean> = new EventEmitter();

  //Gets the general section data
  @Input( 'getGeneralData' ) getGeneralData: any[] = [];

  //Gets the internal call document list
  @Input( 'getFilesData' ) getFilesData: any[] = [];

  //Gets the internal call document to delete from database
  @Input( 'getDbFilesToDelete' ) getDbFilesToDelete: any[] = [];

  //Gets the main form data
  @Input( 'getFormData' ) getFormData: any = {};

  //Stores the current section name
  @Input( 'sectionName' ) sectionName: string = '...';

  //Gets the type section id
  @Input( 'typeSectionId' ) typeSectionId: number = 0;

  //Gets the current user info
  @Input( 'currentUserInfo' ) currentUserInfo: any = {};

  //It is used to store the reference to a specific section.
  public sectionToShow: any = SectionToShowEnum;

  //Stores the judges to add
  public judgesToAdd: any[] = [];

  //#region Set the publication and end dates, and whether they are editable or not.
  public showPublishDate: boolean = false;
  public publishDate: Date = new Date();

  public showEndDate: boolean = false;
  public endDate: Date = new Date();
  //#endregion

  ngOnInit(): void {
    //
  }

  /**
   * Creates a new record for a intCall and its related tables
   */
  public confirmData() {
    this._selfAssessmentAlertService.swalLoading( 'Procesando...', 'Estamos procesando la solicitud. Por favor, espera un momento' );

    if ( !this.isModuleValid() )
      return;

    forkJoin( {
      patchIntcall: this.patchIntcall(),
      //judgesIntcall: forkJoin( [ ...this.patchJudges() ] ), //Temporarily disabled
      documents: forkJoin( [ ...this.setDocumentsIntcall() ] ).pipe( defaultIfEmpty( [] ) ),
      documentsToDelete: forkJoin([...this.deleteIntCallDocuments()]).pipe( defaultIfEmpty( [] ) )
    } ).subscribe({
      complete:()=>{
        this._selfAssessmentAlertService.success( 'Hecho!', 'El proceso se ha actualizado con éxito', () => {
          const processName: string = this._activatedRoute.snapshot.params.process;
          this._router.navigate( [ `dashboard/${ processName }` ] );
        } );
      },error:( err )=>{
        this.onError();
        console.log( err );
      }
    })
  }

  /**
   * This method is used to update the percentage value of a judge in the judgesToAdd array when a user changes the percentage input value.
   * @param data 
   */
  public setUserPercentage( data: any ) {
    this.judgesToAdd.forEach( e => {
      if ( e.judgeInfo.id === data.userId )
        e.percentage = data.value
    } );
  }

  /**
   * Displays a date picker when the user clicks on the date text field
   * @param event 
   */
  public showDatePicker( event: any ) {
    event.target.showPicker();
  }

  /**
   * This function sets the value of this._publishDate to the value of the event target.
   * @param event 
   */
  public getPublishDate( event: any ) {
    this.publishDate = event.target.value;
  }

  /**
   * This function sets the value of this._endDate to the value of the event target.
   * @param event 
   */
  public getEndDate( event: any ) {
    this.endDate = event.target.value;
  }

  /**
   * Convert a comma-separated string of words into a string array that can be used as tags.
   * @param data Is a string that contains comma-separated words.
   * @returns Returns a string array in JSON format.
   */
  private tagsToStringArray( data: string ) {
    const words = data.split( ',' );
    const wordsArray = JSON.stringify( words );
    return wordsArray;
  }

  /**
   * Updates an Intcall object and returns an Observable of type IntcallI which is returned by the _selfAssessmentService.patchIntcall() method.
   * @returns An array of Observables
   */
  private patchIntcall(): Observable<any>[] {
    const intCallID: number = this._activatedRoute.snapshot.params.id;

    const intcall = {
      id: Number( intCallID ),
      nameIntCall: this.getFormData.nameIntCall,
      descript: this.getFormData.descript,
      tags: this.tagsToStringArray( this.getFormData.tags ),
      dateEnd: this.showEndDate ? this.endDate : null,
      dateStart: this.showPublishDate ? this.publishDate : null,
    }

    return this._selfAssessmentService.patchIntcall( intcall );
  }

  /**
   * Updates a JudgeIntcallI based on the list of judges to add,
   * and returns an array of Observables to set each JudgeIntcallI object using the self assessment service.
   * @returns An array of Observables
   */
  private patchJudges(): Observable<any>[] {
    const judges = [];
  
    for ( const judge of this.judgesToAdd ) {
      const judgeIntCall = this._getCurrentIntcallData.judgeIntcalls.find( e => e.userID === judge.judgeInfo.id );
      if (judgeIntCall) {
        judges.push({
          id: judgeIntCall.id,
          percentageJudge: judge.percentage
        });
      }
    }
  
    return judges.map( e => this._selfAssessmentService.patchJudgeIntcall( e ) );
  }

  /**
   * 
   * Sets files for a given intcall ID and returns an array of observables for each request.
   * @param intcallID The ID of the intcall.
   * @returns An array of observables for each request made.
   */
  private setDocumentsIntcall( ): Observable<any>[] {
    const intCallID: number = this._activatedRoute.snapshot.params.id;

    this.getFilesData.forEach( (e:DocumentIntcallI) => {
      e.intcallID = Number( intCallID );
      e.userID = this.currentUserInfo.id;
    }) ;

    // Map each file data to a request with the merged object data.
    return this.getFilesData.map( ( e ) => this._selfAssessmentService.setDocumentIntcall( e ) );
  }


  /**
   * IntCall documents to delete
   * @returns 
   */
  private deleteIntCallDocuments(){
    const documents = this.getDbFilesToDelete.map( ( e ) => this._selfAssessmentService.deleteIntCallDocument( e.id ) );
    return documents;
  }


  /**
   * Copy a link to the clipboard
   * @param text 
   */
  public copyToClipboard() {
    const intCallID: number = this._activatedRoute.snapshot.params.id;
    this._clipboard.copy(`${environment.appFrontUrl}/dashboard/selfEvaluating/user/${ intCallID }`);
  }
  /**
   * This function checks if some fields are valid or not and returns a boolean result accordingly.
   * @returns a boolean
   */
  private isFormValid(): boolean {
    this.checkMainFormData.emit( true );

    const { nameIntCall, descript, tags } = this.getFormData;

    if ( nameIntCall === undefined || nameIntCall === '' ) return false;

    if ( descript === undefined || descript === '' ) return false;

    if ( tags === undefined || tags === '' ) return false;

    return true;
  }

  /**
   * Validates the module judges, judges weighting and main form
   * @returns true if the validation succeeded, false otherwise.
   */
  private isModuleValid(): boolean {
    let totalPercentage: number = 0;

    if ( this.judgesToAdd.length === 0 ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'Aún no has agregado ningún juez' );
      return false;
    }

    for ( const judge of this.judgesToAdd ) {
      totalPercentage += judge.percentage ?? 0;
    }

    if ( totalPercentage !== 100 ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'La ponderación en la lista de jueces debe sumar 100%' );
      return false;
    }

    if ( !this.isFormValid() ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'Hay uno o más campos en formulario por completar' );
      return false;
    }

    return true;
  }


  /**
   * Checks if a given date string is valid
   * @param dateString 
   * @returns 
   */
  public isTemplateDateValid(dateString: string): boolean {
    //TODO: CHANGE THIS METHOD TO A VARIABLE
    
    const date = new Date(dateString);
    return !Number.isNaN(date.getTime());
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    const processName: string = this._activatedRoute.snapshot.params.process;
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
      () => this._router.navigate( [ `dashboard/${ processName }` ] ) );
  }
}