import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component( {
  selector: 'state-pill',
  templateUrl: './state-pill.component.html',
  styleUrls: [ './state-pill.component.css' ]
} )
export class StatePillComponent implements OnInit, ICellRendererAngularComp {

  //is a variable that stores information about the state selected by the user.
  public selectedState: any = undefined;

  //stores information about the available states
  private states: any[] = [
    {
      state: 'ongoing',
      title: 'En proceso',
    },
    {
      state: 'ended',
      title: 'Finalizado',
    },
    {
      state: 'programmed',
      title: 'Programado',
    }
  ]

  constructor () {
    //
  }

  /**
   * ag2 init function
   * @param params 
   */
  agInit( params: ICellRendererParams<any, any> ): void {
    this.selectedState = this.states.find( e => e.state === params.data.state );
  }

  /**
   * ag2 fresh function
   * @param params 
   * @returns 
   */
  refresh( params: ICellRendererParams<any, any> ): boolean {
    return true;
  }

  ngOnInit(): void {
    //
  }

}
