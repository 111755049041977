import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Subscription, forkJoin } from 'rxjs';
import { RoleManagerService } from 'src/app/services/roleManager/role-manager.service';
import { RoleButtonComponent } from './role-button/role-role-button.component';
import { AttachmentService } from 'src/app/services/attachment.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/user';

@Component( {
  selector: 'directiva-role-manager',
  templateUrl: './role-manager.component.html',
  styleUrls: [ './role-manager.component.css' ]
} )
export class RoleManagerComponent implements OnInit {

  constructor (
    private _roleManagerService: RoleManagerService,
    private _router: Router,
    private _attachmentService: AttachmentService
  ) {
    //
  }

  //Table options
  public gridOptions: GridOptions = {
    columnDefs: [
      {
        headerName: 'Acciones', field: 'actions',
        cellRenderer: RoleButtonComponent,
        cellRendererParams: {
          label: 'EDITAR ROLES',
          onClick: this.onEditRoles.bind( this )
        }
      },
      { headerName: 'Correo', field: 'email' },
      { headerName: 'Primer nombre', field: 'firstName' },
      { headerName: 'Segundo nombre', field: 'secondName' },
      { headerName: 'Primer apellido', field: 'firstSurname' },
      { headerName: 'Segundo apellido', field: 'secondSurname' },
    ],

    rowData: [],

    localeText:{
      noRowsToShow: 'No hay datos disponibles'
    },

    rowHeight: 64,
    domLayout: 'autoHeight'
  };

  //Stores the selected user info
  public selectedUser: any | undefined = undefined;

  //Stores the main roles
  public filteredRoles: any[] = [];

  //Stores the file to be uploaded
  public fileToUpload: File | undefined = undefined;

  //Checks if the file to be uploaded has errors
  public isFileError: boolean = false;

  //Stores a custom text for when a file is being uploaded or if there is an error in the file.
  public uploadFileText: string = 'Elija el archivo';

  //Checks if the user is editing a role
  public isEditingRole: boolean = false;

  //Stores a copy ot the filtered roles
  private _filteredRolesCopy: any[] = [];

  //Stores the selected user roles
  private _selectedUserRoles: any[] = [];

  //Saves the users obtained after the request
  private _users: User[] = [];

  //Stores the current user shool
  private _currentUserSchoolID: number = 0;

  //Checks if the current user is a super admin
  private _isSuperAdmin = false;

  public SCHOOL_NAME:string = environment.SCHOOL_NAME;

  private _subscription: Subscription = new Subscription();


  ngOnInit(): void {
    this.swalLoading( 'Cargando...', 'Estamos preparando el módulo, por favor espera un momento' );

    forkJoin( {
      user: this._roleManagerService.getCurrentUser(),
      roles: this._roleManagerService.getAllRoles()
    } ).subscribe( {
      next: ( { user, roles } ) => {
        this.checkRoles( user, roles );
        this.swalClose()
      }, error: ( err ) => {
        const errorMessage = err.message || 'Ha ocurrido un error al cargar el módulo';
        this.swalError( 'Error', errorMessage );
        console.log( err );
      }
    } )
  }

  public onGridReady( params ) {
    params.api.sizeColumnsToFit();
  };

  /**
   * Checks if the current user is a super admin and sets the roles
   * @param userInfo Current logged in user
   * @param roles All roles in db
   */
  private checkRoles( userInfo: any, roles: any ) {
    //Current user role IDs
    const userRoleIDs: any = [ ...new Set( userInfo.roleMappings.map( e => e.roleId ) ) ];

    //If I do not have the role Admin || Personal then I cannot access this module
    if ( !( userRoleIDs.includes( 1 ) || userRoleIDs.includes( 9 ) ) ) {
      this._router.navigate( [ '/register' ] );
      return;
    }

    //.....................................................................Admin || Personal
    const getPersonalRole: any = userInfo.roleMappings.find( e => e.roleId === 1 || e.roleId === 9 );

    //If the current user has the role 1 or 19, then get their school id 
    this._currentUserSchoolID = getPersonalRole ? getPersonalRole.SchoolID : 0;

    //If current user has the role 9, then he is a super admin
    this._isSuperAdmin = userRoleIDs.includes( 9 );

    //................................... All roles : All roles except ....Personal && Diedu
    this.filteredRoles = this._isSuperAdmin ? roles : roles.filter( e => e.id !== 9 && e.id !== 22 );

    //Stores a copy of the filtered roles
    this._filteredRolesCopy = [ ...this.filteredRoles ];
  }

  /**
   * Gets the value tipped in the search user field
   * @param event 
   */
  public getSearchInputValue( event: any ) {
    this.swalLoading( 'Cargando...', 'Buscando usuarios, por favor espera un momento' );

    this.isEditingRole = false;

    const value: string = event.target.value;

    //Always add the @cedoc_celic.edu.co
    const finalValue = value.includes( '@' )
      ? value.substring( 0, value.indexOf( '@' ) ) + `@${this.SCHOOL_NAME === 'CELIC' ? 'liceosejercito.edu.co' : 'cedoc.edu.co'}`
      : value + `@${this.SCHOOL_NAME === 'CELIC' ? 'liceosejercito.edu.co' : 'cedoc.edu.co'}`;

    this._subscription.unsubscribe();
    this._subscription = this.getUserByEmail( finalValue ).subscribe( {
      next: ( users ) => {

        //If I am a superAdmin, I can access the complete list of users.
        //If I am not, then I can only access the list of users who belong to the same school as me.
        this._users = this._isSuperAdmin
          ? users
          : users.filter( e => { return e.roleMappings.some( role => role.SchoolID === this._currentUserSchoolID ) } );

        const noRowsMessage = this._isSuperAdmin
          ? "No se encontraron usuarios"
          : "No se encontraron usuarios para la escuela actual.";

        this.gridOptions.localeText.noRowsToShow = this._users.length === 0 ? noRowsMessage : '';

        const rowData = this._users.map( user => ( {
          user,
          email: user.CedocEmail,
          firstName: user.Name1,
          secondName: user.Name2,
          firstSurname: user.LastName1,
          secondSurname: user.LastName2
        } ) );

        this.gridOptions.api.setRowData( rowData );

        this.swalClose();
      }, error: ( err ) => {
        const errorMessage = err.message || 'Ha ocurrido un error al cargar los usuarios';
        this.swalError( 'Error', errorMessage );
        console.log( err );
      }
    } )
  }

  /**
   * Loads the selected user roles
   * @param selectedUser
   */
  public onEditRoles( selectedUser: any ) {
    this.swalLoading( 'Cargando...', 'Cargando roles, por favor espera un momento' );

    const data = selectedUser.data;
    this.selectedUser = data.user;

    this._subscription.unsubscribe();
    this._subscription = this.getRoleMappings( this._currentUserSchoolID, data.user.id ).subscribe( {
      next: ( roles ) => {
        this._selectedUserRoles = roles;

        if ( !this._isSuperAdmin ) {
          const requiredRoles = [ 2, 3, 10, 18 ];
          const hasRole = roles.some( role => requiredRoles.includes( role.roleId ) );

          this.filteredRoles = ( roles.length === 0 || !hasRole )
            ? this._filteredRolesCopy.filter( e => requiredRoles.includes( e.id ) )
            : this._filteredRolesCopy.filter( e => !requiredRoles.includes( e.id ) )
        }

        this.isEditingRole = true;

        this.swalClose();
      }, error: ( err ) => {
        const errorMessage = err.message || 'Ha ocurrido un error al cargar los roles';
        this.swalError( 'Error', errorMessage );
        console.log( err );
      }
    } )
  }

  /**
   * This function checks if a specific role is present in the array
   * @param roleID 
   * @returns 
   */
  public isRoleChecked( roleID: number ) {
    const checkRole = this._selectedUserRoles.find( roleMapping => roleMapping.roleId === roleID );
    return checkRole;
  }

  /**
   * Allows to toggle the selected role
   * @param roleID 
   * @param isCheckboxCheckbox 
   */
  public changeRole( roleID: number, isCheckboxCheckbox: boolean ) {
    this.swalLoading( 'Cargando...', 'Procesando cambios, por favor espera' );

    const selectedRoleIndex: number = this._selectedUserRoles.findIndex( x => x.roleId === roleID );

    if ( selectedRoleIndex >= 0 ) {
      const roleIdToDelete = this._selectedUserRoles[ selectedRoleIndex ].id;
      this._roleManagerService.deleteRoleMappings( roleIdToDelete ).subscribe( {
        complete: () => {
          this._selectedUserRoles.splice( selectedRoleIndex, 1 );
          this.swalClose();
        }, error: ( err ) => {
          const errorMessage = err.message || 'Se produjo un error en el proceso';
          this.swalError( 'Error', errorMessage );
          console.log( err );
        }
      } )
    } else {
      const data = {
        roleId: roleID,
        SchoolID: this._currentUserSchoolID,
        principalType: "USER",
        principalId: this.selectedUser.id
      }

      this._roleManagerService.newRoleMappings( data ).subscribe( {
        next: ( resp ) => {
          this._selectedUserRoles.push( resp )
          this.swalClose();
        }, error: ( err ) => {
          const errorMessage = err.message || 'Se produjo un error en el proceso';
          this.swalError( 'Error', errorMessage );
          console.log( err );
        }
      } )
    }
  }

  /**
   * This is a method that is called when a file is selected using a file input field. 
   * It checks if a file has been selected, and if not, it returns immediately. 
   * Otherwise, it sets the fileToUpload property to the selected file.
   * @param event 
   * @returns 
   */
  public onFileInput( event: any ) {
    if ( !<File>event.target.files[ 0 ] )
      return;

    this.fileToUpload = <File>event.target.files[ 0 ];

    if ( this.fileToUpload.size > 5000000 ) { //5M
      this.uploadFileText = 'Error de Archivo'
      this.isFileError = true;
      return;
    }

    this.uploadFileText = 'Cambiar el archivo';
    this.isFileError = false;
  }

  /**
   * Uploads a file to the singinfo container
   */
  public uploadFile() {
    this._attachmentService.storageFile( 'singinfo', this.fileToUpload ).then( ( result: any ) => {
      const urlFile = `/attachments/singinfo/download/${ result[ 'result' ][ 'files' ][ 'file' ][ 0 ][ 'name' ] }`;
      this.saveUrlSign( urlFile );
    } );
  }

  /**
   * Saves the URL of a signature file to a user document
   * @param url 
   */
  private saveUrlSign( url: string ): void {
    this.swalLoading( 'Cargando...', 'Cargando archivo, por favor espera un momento' );

    const userDocumentID = this.selectedUser.UserDocuments[ 0 ].id;
    const data = { Signature: url };
    this._roleManagerService.patchUserDocuments( userDocumentID, data ).subscribe( {
      next: ( result ) => {
        if ( this.selectedUser.UserDocuments.length == 0 ) this.selectedUser.UserDocuments.push( result );
        else this.selectedUser.UserDocuments[ 0 ] = result;
        this.fileToUpload = undefined;
        this.swalClose();
      }, error: ( err ) => {
        const errorMessage = err.message || 'Se produjo un error al cargar el archivo';
        this.swalError( 'Error', errorMessage );
        console.log( err );
      }
    } )
  }

  /**
   * Gets a user by their email address
   * @param email 
   * @returns 
   */
  private getUserByEmail( email: string ) {
    const filters: string = JSON.stringify( {
      where: { CedocEmail: email },
      include: [ 'roleMappings', 'UserDocuments' ],
      limit: 5
    } )

    return this._roleManagerService.getUsers( filters );
  }

  /**
   * Gets a user's roles
   * @param schoolID 
   * @param userID 
   * @returns 
   */
  private getRoleMappings( schoolID: number, userID: number ) {
    const filters: string = JSON.stringify( {
      where: { and: [ { SchoolID: schoolID }, { principalId: userID } ] },
      include: [ 'role' ]
    } );

    return this._roleManagerService.getRoleMappings( filters );
  }

  //#region 🚦 ALERTS
  private swalError( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'error',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then( ( result ) => {
      if ( result.isConfirmed && callback ) {
        callback();
      }
    } );
  }

  private success( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'success',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then( ( result ) => {
      if ( result.isConfirmed && callback ) {
        callback();
      }
    } )
  }

  private swalLoading( title?: string, message?: string ) {
    Swal.fire( {
      title: title,
      text: message,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } );
    Swal.showLoading()
  }

  private swalWarning( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'warning',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then( ( result ) => {
      if ( result.isConfirmed && callback ) {
        callback();
      }
    } )
  }

  private swalClose() {
    Swal.close()
  }

  //#endregion
  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }
}
