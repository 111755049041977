import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UploadFileBoxComponent } from './upload-file-box.component';



@NgModule({
  declarations: [
    UploadFileBoxComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports:[
    UploadFileBoxComponent
  ]
})
export class UploadFileBoxModule { }
