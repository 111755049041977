import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CourseOffer } from 'src/app/models/courseOffer';
import { distinct, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { School } from 'src/app/models/school';
import { Category } from 'src/app/models/category';

import { Course } from 'src/app/models/course';

import { TypesCourse } from 'src/app/models/typesCourse';
import { forkJoin, Subscription } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSearchComponent } from '../../shared/user-search/user-search.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'payment-order',
  templateUrl: './EditPaymentOrder.component.html',
  styleUrls: [
    './EditPaymentOrder.component.css',
    './paymentOrder.component.sass',
  ],
} )
export class EditPaymentOrderComponent implements OnInit, OnDestroy {
  constructor (
    private _courseService: CourseService,
    public sharedService: SharedService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    //
  }

  //userSearchInput component reference
  @ViewChild( 'userSearchInput' ) userSearchInput: UserSearchComponent;

  //checks whether the form is being sent
  public isSubmitting: boolean = false;

  //sets whether the course is active
  public isCourseActive: boolean = true;

  //stores the courses
  public courses: Course[] = [];
  public selectedCourseId: number = 0;

  //stores the course offers
  public courseOffers: CourseOffer[] = [];
  public selectedCourseOfferId: number = 0;

  //stores the price
  public selectedPrice: number = 0;

  //stores the user id
  public selectedUserId: number = 0;

  //stores the file url
  public fileUrl: string = '';

  //stores the schools
  public schools: School[] = [];
  public selectedSchoolId: number = 0;

  //stores the categories
  public categories: Category[] = [];

  //stores the categories
  public selectedCategoryId: number = 0;

  //stores the courses types
  public typeCourses: TypesCourse[] = [];
  private _typeCourses: TypesCourse[] = [];
  public selectedTypeCourseId: number = 0;

  //stores the type requests
  public typeRequests: any[] = [];
  public selectedTypeRequestId: number = 0;
  public nameTypeRequest: string = '';

  //stores the payment code
  public paymentCode: string = '';

  //stores the description
  public description: string = '';

  //check if has errors
  public hasErrors: boolean = false;

  public isLoading: boolean = true;
  public isLoadingUser: boolean = true;

  //saves the data of the order being edited

  public containerCertsName: string = environment.containerCertsName;

  private _currentSubtypeRequestId: number = 0;

  private subscriptionSchool: Subscription;

  ngOnInit(): void {
    let tempSchools: any[] = [];
    this.subscriptionSchool = this.sharedService.sharedyearSchoolFilter
      .pipe( distinct() )
      .subscribe( {
        next: ( data ) => {
          if ( data.schools.length === 0 ) return;

          const id = this._activatedRoute.snapshot.paramMap.get( 'id' );
          tempSchools = data.schools;

          forkJoin( {
            categories: this._courseService.getCategories(),
            typeRequest: this._courseService.getTypeRequest(),
            subtypeRequest: this._courseService.getSubtypeRequest( Number( id ) ),
          } )
            .pipe(
              switchMap( ( forkResp: any ) => {
                let tempSchoolId =
                  tempSchools.length > 0 &&
                    tempSchools.find(
                      ( school ) => school.id === forkResp.subtypeRequest.schoolID
                    )
                    ? forkResp.subtypeRequest.schoolID
                    : 0;

                let tempCourseId = forkResp.subtypeRequest.courseID;
                let tempTypeCourseId = forkResp.subtypeRequest.typeCourseID;

                return this.getCourses(
                  tempSchoolId,
                  tempTypeCourseId,
                  tempCourseId
                ).pipe(
                  map( ( coursesData ) => {
                    return { ...forkResp, ...coursesData };
                  } )
                );
              } )
            )
            .subscribe( {
              next: ( {
                categories,
                courseOffers,
                courses,
                subtypeRequest,
                typeRequest,
              } ) => {
                this.schools = tempSchools;
                this.selectedSchoolId = subtypeRequest.schoolID;

                {
                  //sets categories
                  this.categories = categories;
                  //allows to know to which category the type of course belongs
                  this.selectedCategoryId = this.categories.find( ( c ) =>
                    c.TypeCourses.some(
                      ( t ) => t.id === subtypeRequest.typeCourseID
                    )
                  )?.id || 0;
                }

                {
                  //sets typeCourses
                  this._typeCourses = categories
                    .map( ( r ) => r.TypeCourses )
                    .reduce( ( acc, val ) => acc.concat( val ), [] );

                  this.typeCourses = this._typeCourses.filter(
                    ( t ) => this.selectedCategoryId === t.CategoryID
                  );

                  this.selectedTypeCourseId =
                    subtypeRequest.typeCourseID === null
                      ? 0
                      : subtypeRequest.typeCourseID;
                }

                {
                  //sets courses
                  this.courses = courses;
                  this.courseOffers = courseOffers;
                  this.selectedCourseId =
                    subtypeRequest.courseID === null
                      ? 0
                      : subtypeRequest.courseID;

                  this.selectedCourseOfferId =
                    subtypeRequest.courseOfferID === null
                      ? 0
                      : subtypeRequest.courseOfferID;
                }

                this.paymentCode = subtypeRequest.code;

                this.typeRequests = typeRequest;
                this.selectedTypeRequestId = subtypeRequest.typeRequestID;

                this.nameTypeRequest = subtypeRequest.nameSubTypeRequest;
                this.selectedPrice = subtypeRequest.price;
                this.description = subtypeRequest.descript;

                this.fileUrl = subtypeRequest.fileUrl;

                this.selectedUserId = subtypeRequest.userID;
                this.userSearchInput.getUserName( subtypeRequest.userID ); //loads the user depending on the id brought in by the record

                this._currentSubtypeRequestId = subtypeRequest.id;
                this.isLoading = false;
              },
              error: ( err ) => console.log( err ),
            } );
        },
        error: ( err ) => console.log( err ),
      } );
  }

  /**
   * obtains the information of the file to be uploaded
   * @param event
   */
  onFileInput( event: any ) {
    this.fileUrl = event.urlFile;
  }

  onSchoolChange() {
    this.categories = [];
    this.typeCourses = [];
    this.courses = [];
    this.courseOffers = [];

    this.selectedCategoryId = 0;
    this.selectedTypeCourseId = 0;
    this.selectedCourseId = 0;
    this.selectedCourseOfferId = 0;
  }

  /**
   * filters course types and retrieves courses based on the types entered
   */
  onCategoryChange() {
    this.typeCourses = [];
    this.courses = [];
    this.courseOffers = [];

    this.selectedTypeCourseId = 0;
    this.selectedCourseId = 0;
    this.selectedCourseOfferId = 0;

    this.typeCourses = this._typeCourses.filter(
      ( t ) => this.selectedCategoryId === t.CategoryID
    );
  }

  onTypeCourseChange() {
    this.selectedCourseId = 0;
    this.courses = [];

    this.onCoursesChange();
  }

  onCoursesChange() {
    this.getCourses(
      this.selectedSchoolId,
      this.selectedTypeCourseId,
      this.selectedCourseId
    ).subscribe( {
      next: ( { courses, courseOffers } ) => {
        this.courses = courses;
        this.courseOffers = courseOffers.filter(
          ( e ) => this.selectedCourseId === e.CourseID
        );
      },
      error: ( err ) => console.log( err ),
    } );
  }

  /**
   * get the courses and course offers
   */
  getCourses( schoolId: number, typeCourseId: number, courseId: number ) {
    this.courseOffers = [];
    this.selectedCourseOfferId = 0;

    const courseFilter = {
      where: {
        and: [
          { isCourseActive: this.isCourseActive },
          { SchoolID: schoolId },
          { TypeCourseID: typeCourseId },
        ],
      },
      include: { CourseOfer: [ 'oferState', 'TypeCourseOFer' ] },
    };

    return this._courseService.getCourses( JSON.stringify( courseFilter ) ).pipe(
      switchMap( ( courses: Course[] ) => {
        const courseOfferFilter = {
          where: {
            and: [ { SchoolID: schoolId }, { CourseID: courseId } ],
          },
        };
        return this._courseService
          .getCoursesOfers( JSON.stringify( courseOfferFilter ) )
          .pipe(
            map( ( offers ) => {
              let result = {
                courses: courses,
                courseOffers: offers,
              };
              return result;
            } )
          );
      } )
    );
  }

  /**
   * update the payment order
   * @param valid
   */
  updatePaymentOrder( valid ) {
    if (
      valid &&
      this.selectedUserId !== 0 &&
      this.selectedSchoolId !== 0 &&
      this.selectedTypeRequestId !== 0
    ) {
      this.isSubmitting = true;

      Swal.fire( {
        title: 'Guardando...',
        text: 'Por favor, espera',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: function () {
          Swal.showLoading();
        },
      } );

      let data = {
        schoolID: this.selectedSchoolId,
        typeCourseID:
          this.selectedTypeCourseId === 0 ? null : this.selectedTypeCourseId,
        courseID: this.selectedCourseId === 0 ? null : this.selectedCourseId,
        courseOfferID:
          this.selectedCourseOfferId === 0 ? null : this.selectedCourseOfferId,
        typeRequestID: this.selectedTypeRequestId,
        nameSubTypeRequest: this.nameTypeRequest,
        descript: this.description,
        price: this.selectedPrice,
        code: this.paymentCode,
        fileUrl: this.fileUrl,
        userID: this.selectedUserId,
        updateDate: Date.now(),
      };

      this._courseService
        .updateSubtypeRequest( this._currentSubtypeRequestId, data )
        .subscribe( {
          complete: () => {
            this.isSubmitting = false;
            this.sharedService.swalSuccess(
              '¡Hecho!',
              'Orden guardada con éxito'
            );
          },
          error: ( err ) => console.log( err ),
        } );
    } else {
      this.hasErrors = true;
      this.isSubmitting = false;
      this.sharedService.swalError(
        'Error De formulario',
        'Todos los campos son obligatorios'
      );
    }
  }

  /**
   * redirects to payment history
   */
  goToHistoricalPaymentsOrders() {
    this._router.navigate( [ '/dashboard/paymentOrderHistory' ], {
      state: { enableToLoad: true },
    } );
  }

  ngOnDestroy() {
    this.subscriptionSchool.unsubscribe();
  }
}
