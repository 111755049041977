import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import { UserIntcallFieldAnswer } from 'src/app/models/selfAssessment/userIntcallFieldAnswer';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import * as allEnums from '../../../models/allTypes/types';

@Component({
  selector: 'se-sticky-bar',
  templateUrl: './se-sticky-bar.component.html',
  styleUrls: ['./se-sticky-bar.component.css']
})
export class SeStickyBarComponent implements OnInit {

  @Input('showData') showData;
  @Input('loguedUserID') loguedUserID: number;
  @Input('intcall') intcall: IntcallI;
  @Output('userParticipate') userParticipate: EventEmitter<boolean> = new EventEmitter();
  @Output('changeViewNavLeft') changeViewNavLeft: EventEmitter<number> = new EventEmitter();
  @Output('showModalFiles') showModalFiles: EventEmitter<boolean> = new EventEmitter();

  public allBtnViewSelected = allEnums.enumTypeGeneralView;
  public btnViewSelected = this.allBtnViewSelected.formulario;

  disableBtnInit: boolean = false;

  constructor(
    public _selfAssessmentService: SelfAssessmentService,
  ) {/** */}

  ngOnInit(): void {
    /** */
  }

  changeTypeOfView(typeOfView: number): void {
    this.btnViewSelected = typeOfView;
    console.log('typeOfView',typeOfView);
    
    this.changeViewNavLeft.emit( typeOfView );
  }
  isBtnSelected(optionToShow: number): boolean {
    return this.btnViewSelected == optionToShow;
  }

  initFormInDB(): void {
    this.disableBtnInit = true;
    let data: UserIntcallAnswer = {
      intcallID: this.intcall.id,
      userID: this.loguedUserID,
      isFinalAnswer: false,
      isFinalGrade: false,
      value: 0,
      createdAt: new Date(),
      updatedAt: new Date()
    }
    this._selfAssessmentService.postUserIntcallAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('initFormInDB:',data);
        this.createUserIntcallFieldAnswer(data.id);
      }, err => {
        console.log('initFormInDB err:',err);
      }
    )
  }
  answers = 0;
  questions = 0;
  createUserIntcallFieldAnswer(userIntcallAnswerIDr:number): void {
    this.answers = 0;
    this.questions = 0;
    this.intcall.intcallModules.forEach(intMod => {
      intMod.intcallFields.forEach(fields => {
        this.questions +=1;
        let data: UserIntcallFieldAnswer = {
          intcallID: this.intcall.id,
          UserIntcallAnswerID: userIntcallAnswerIDr,
          intcallFieldID: fields.id,
          value: '',
          createdAt: new Date()
        }
        this.postUserIntcallFieldAnswer(data);
      });
    });
  }
  postUserIntcallFieldAnswer(data:UserIntcallFieldAnswer): void {
    this._selfAssessmentService.postUserIntcallFieldAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('postUserIntcallFieldAnswer:',data);
        this.answers+=1;
        this.confimrInitAllData();
      }, err => {
        console.log('postUserIntcallFieldAnswer err:',err);
      }
    )
  }
  confimrInitAllData(): void {
    if (this.answers == this.questions) this.userParticipate.emit( true );
  }
  showModalFilesData(): void {
    this.showModalFiles.emit( true );
  }
  saveDataMethod(isFinished: boolean): void {
    this._selfAssessmentService.nextSaveEvent();
    if (isFinished) this._selfAssessmentService.nextSaveFinishEvent();
  }

}
