import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  baseUrl: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',

  fileBaseUrl: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  containerCertsName: 'cedoccerts',
  appFrontUrl: 'https://cedoc-360test.web.app/',

  principalAppName: 'cedoc',

  ...schoolToLoad()
};
