import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'field-card',
  templateUrl: './field-card.component.html',
  styleUrls: [ './field-card.component.css' ]
} )
export class FieldCardComponent implements OnInit {

  constructor () {
    //
  }

  //Sends a callback when delete button is clicked
  @Output( 'deleteField' ) deleteField: EventEmitter<number> = new EventEmitter();

  //Receives the card id
  @Input( 'fieldCarId' ) fieldCarId: number = 0;

  //Select type response variables
  public showResponseType: boolean = false;

  //Stores information about the type of response selected by the user
  public selectedResponseType: any = { title: 'Campo de texto', responseTypeId: 1 };

  //Stores the card input fields values
  public intcallFields: any = {};

  //Stores the added selection fields and their values
  public multipleSelectionFields: any[] = [ { fieldId: 1, value: '' } ];

  //If true, the validations will be activated
  public checkErrors: boolean = false;

  //Stores the selection fields ids
  private selectionFieldId: number = 1;

  ngOnInit(): void {
    //
  }

  /**
   * Used to update the response type selection information and hide the list of response types after a user selects a response type.
   * @param responseTypeValue 
   * @param title 
   */
  public setResponseType( responseTypeId: number, title: string ) {
    this.selectedResponseType = { title, responseTypeId };
    this.showResponseType = false;

    this.resetSelectionField();
  }

  /**
   * This function adds a selection field to the multipleSelectionFields array.
   */
  public addSelectionField() {
    this.selectionFieldId++;
    const newSelectionField = { fieldId: this.selectionFieldId, value: '' };
    this.multipleSelectionFields.push( newSelectionField );
  }

  /**
   * This function removes a selection field from the multipleSelectionFields array
   * @param fieldId 
   */
  public removeSelectionField( fieldId: number ) {
    this.multipleSelectionFields = this.multipleSelectionFields.filter( e => e.fieldId !== fieldId );
  }

  /**
   * This function resets the multipleSelectionFields array to contain a single element
   */
  private resetSelectionField() {
    this.selectionFieldId = 1;
    this.multipleSelectionFields = [ { fieldId: 1, value: '' } ];
  }

  /**
   * Returns as object the values entered in the card inputs 
   */
  public getIntcallData() {
    if ( !this.isValid() ) return;

    const cardData: any = { ...this.intcallFields };

    cardData.typeField = this.selectedResponseType.responseTypeId;

    if ( this.selectedResponseType.responseTypeId === 3 )
      cardData.fields = this.multipleSelectionFields;

    return cardData;
  }

  /**
   * This function checks if some fields are valid or not and returns a boolean result accordingly.
   * @returns a boolean
   */
  private isValid(): boolean {
    this.checkErrors = true;

    const { nameField, percentageField } = this.intcallFields;
    const { responseTypeId } = this.selectedResponseType;

    // Check for invalid name or percentage
    if ( nameField === undefined || nameField === '' || percentageField === undefined || percentageField === 0 ) 
      return false;

    // Check for invalid multiple selection fields
    if ( responseTypeId === 3 && ( this.multipleSelectionFields.length === 0 || this.multipleSelectionFields.some( e => e.value === '' ) ) ) 
      return false;
    

    return true;
  }
}