import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CardIdService } from 'src/app/services/card-id.service';

@Component({
  selector: 'directiva-date-change',
  templateUrl: './date-change.component.html',
  styleUrls: ['./date-change.component.css']
})
export class DateChangeComponent implements ICellRendererAngularComp {
  public cardID: number;
  public userID: number;
  public CarnetFecCambio: string;

  public editData: boolean = false;
  public dataToUpdate: string;

  constructor(
    public _cardIdService: CardIdService,
  ) { }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.initAllData(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    // this.cellValue = this.getValueToDisplay(params);
    console.log('refresh params',params);
    this.initAllData(params);
    return true;
  }

  initAllData(params: ICellRendererParams) {
    this.cardID = params.data.CarnetID;
    this.userID = params.data.id;
    this.CarnetFecCambio = params.data.CarnetFecCambio;
    this.dataToUpdate = params.data.CarnetFecCambio;
  }

  updateData() {
    if (this.dataToUpdate == undefined) return;
    console.log('dataToUpdate',this.dataToUpdate);
    if (this.cardID) this.patchData();
    else this.postData();
  }
  patchData() {
    console.log('patch');
    let data = {
      dateChange: this.dataToUpdate
    }
    this._cardIdService.patchCardIdByUserID(this.cardID, data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetFecCambio = this.dataToUpdate;
    });
  }
  postData() {
    console.log('post');
    let data = {
      userID: this.userID,
      dateChange: this.dataToUpdate
    }
    this._cardIdService.postCardId(data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetFecCambio = this.dataToUpdate;
    });
  }
}
