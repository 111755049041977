import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadPaymentOrderComponent } from './upload-payment-order.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { HasRoleDirective } from 'src/app/directives/has-role.directive';
import { UploadCedocFileComponent } from './components/cedoc-file/upload-cedoc-file.component';
import { TablePaginationComponente } from './components/table-paginationation/table-pagination.component';
import { UploadCelicFileComponent } from './components/celic-file/upload-celic-file.component';



@NgModule({
  declarations: [
    UploadPaymentOrderComponent,
    UploadCedocFileComponent,
    UploadCelicFileComponent,
    TablePaginationComponente,
    HasRoleDirective
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule
  ],
  exports:[
    UploadPaymentOrderComponent,
  ]
})
export class PaymentOrderModule { }
