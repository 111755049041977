import { Component, OnDestroy, OnInit } from '@angular/core';
import { CourseOffer } from 'src/app/models/courseOffer';
import { map, switchMap } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { School } from 'src/app/models/school';
import { Category } from 'src/app/models/category';
import { Course } from 'src/app/models/course';
import { TypesCourse } from 'src/app/models/typesCourse';
import { forkJoin, Subscription } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'payment-order',
  templateUrl: './CreatePaymentOrder.component.html',
  styleUrls: [
    './CreatePaymentOrder.component.css',
    './paymentOrder.component.sass',
  ],
} )
export class CreatePaymentOrderComponent implements OnInit, OnDestroy {
  constructor (
    private _courseService: CourseService,
    public sharedService: SharedService,
    private _router: Router
  ) { }

  public isSubmitting: boolean = false;

  public isCourseActive: boolean = true;

  //stores the courses
  public courses: Course[] = [];
  public selectedCourseId: number = 0;

  //stores the course offers
  public courseOffers: CourseOffer[] = [];
  public selectedCourseOfferId: number = 0;

  //stores the price
  public selectedPrice: number = 0;

  //stores the user id
  public selectedUserID: number = 0;

  public fileUrl: string = '';

  public schools: School[] = [];
  public selectedSchoolId: number = 0;

  public categories: Category[] = [];
  public selectedCategoryId: number = 0;

  public typeCourses: TypesCourse[] = [];
  private _typeCourses: TypesCourse[] = [];

  public selectedTypeCourseId: number = 0;

  public typeRequests: any[] = [];
  public selectedTypeRequestId: number = 0;
  public nameTypeRequest: string = '';

  public paymentCode: string = '';

  public description: string = '';

  public hasErrors: boolean = false;

  public isLoading: boolean = true;

  private subscriptionSchool: Subscription;

  public containerCertsName: string = environment.containerCertsName;

  ngOnInit(): void {
    this.getFixedFilters();
  }

  onFileInput( event: any ) {
    this.fileUrl = event.urlFile;
    console.log( this.fileUrl );
  }

  public getFixedFilters() {
    forkJoin( {
      categories: this._courseService.getCategories(),
      typeRequest: this._courseService.getTypeRequest(),
    } ).subscribe( ( { categories, typeRequest } ) => {
      this.categories = categories;
      this.typeRequests = typeRequest;

      let typeCourses = categories
        .map( ( r ) => r.TypeCourses )
        .reduce( ( acc, val ) => acc.concat( val ), [] );
      this._typeCourses = typeCourses;
      this.typeCourses = typeCourses;

      this.getSchoolData();
    } );
  }

  getSchoolData() {
    this.subscriptionSchool =
      this.sharedService.sharedyearSchoolFilter.subscribe( ( data ) => {
        this.schools = data.schools;

        if ( this.schools[ 0 ] ) {
          this.selectedSchoolId = this.schools[ 0 ] ? this.schools[ 0 ].id : 0;
        }
        this.isLoading = false;
      } );
  }

  onSchoolChange() {
    this.categories = [];
    this.typeCourses = [];
    this.courses = [];
    this.courseOffers = [];

    this.selectedCategoryId = 0;
    this.selectedTypeCourseId = 0;
    this.selectedCourseId = 0;
    this.selectedCourseOfferId = 0;
  }

  onCategoryChange() {
    this.typeCourses = [];
    this.courses = [];
    this.courseOffers = [];

    this.selectedTypeCourseId = 0;
    this.selectedCourseId = 0;
    this.selectedCourseOfferId = 0;

    this.typeCourses = this._typeCourses.filter(
      ( t ) => this.selectedCategoryId === t.CategoryID
    );
  }

  onTypeCourseChange() {
    this.selectedCourseId = 0;

    this.courses = [];
    this.getCourses();
  }

  getCourses() {
    this.courseOffers = [];
    this.selectedCourseOfferId = 0;

    const courseFilter = {
      where: {
        and: [
          { isCourseActive: this.isCourseActive },
          { SchoolID: this.selectedSchoolId },
          { TypeCourseID: this.selectedTypeCourseId },
        ],
      },
      include: { CourseOfer: [ 'oferState', 'TypeCourseOFer' ] },
    };

    this._courseService
      .getCourses( JSON.stringify( courseFilter ) )
      .pipe(
        switchMap( ( courses: Course[] ) => {
          const courseOfferFilter = {
            where: {
              and: [
                { SchoolID: this.selectedSchoolId },
                { CourseID: this.selectedCourseId },
              ],
            },
          };
          return this._courseService
            .getCoursesOfers( JSON.stringify( courseOfferFilter ) )
            .pipe(
              map( ( offers ) => {
                let result = {
                  courses: courses,
                  courseOffers: offers,
                };
                return result;
              } )
            );
        } )
      )
      .subscribe( {
        next: ( { courses, courseOffers } ) => {
          this.courses = courses;
          this.courseOffers = courseOffers.filter(
            ( e ) => this.selectedCourseId === e.CourseID
          );
        },
        error: ( err ) => console.log( err ),
      } );
  }

  createPaymentOrder( form ) {
    console.log('valid: ', form.valid);
    console.log('value: ', form.value);
    console.log('selectedUserID: ', this.selectedUserID);
    if (
      !form.valid ||
      form.value.typeRequestID == 0 ||
      this.selectedUserID == 0
    ) {
      this.hasErrors = true;
      this.isSubmitting = false;
      this.sharedService.swalError(
        'Error De formulario',
        'Todos los campos son obligatorios'
      );
      return;
    }
    console.log('Segundo');
    Swal.fire( {
      title: 'Guardando...',
      text: 'Por favor, espere',
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: function () {
        Swal.showLoading();
      },
    } );

    let data = {
      schoolID: form.value.schoolID,
      descript: form.value.descript,
      nameSubTypeRequest: form.value.nameSubTypeRequest,
      price: form.value.price,
      typeRequestID: form.value.typeRequestID,
      userID: this.selectedUserID,
      updateDate: Date.now(),
    };

    this._courseService.setSubtypeRequest( data ).subscribe({
      complete: () => {
        this.isSubmitting = false;
        Swal.fire( {
          icon: 'success',
          title: '¡Hecho!',
          text: 'Orden guardada con éxito',
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        } ).then( ( result ) => {
          if ( result.isConfirmed ) {
            this._router.navigate( [ '/dashboard/paymentOrderHistory' ], {
              state: { enableToLoad: true },
            } );
          }
        } );
      },
      error: ( err ) => {
        console.log( err );
        Swal.fire( {
          icon: 'error',
          title: '¡Error!',
          text: 'Intentelo de nuevo.',
          showConfirmButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
        } ).then( ( result ) => {
          if ( result.isConfirmed ) {
            console.log('Contacte a un administrador');
          }
        } );
      }
    });
  }

  goToHistoricalPaymentsOrders() {
    this._router.navigate( [ '/dashboard/paymentOrderHistory' ], {
      state: { enableToLoad: true },
    } );
  }

  ngOnDestroy() {
    this.subscriptionSchool.unsubscribe();
  }
}
