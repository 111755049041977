import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Category } from '../models/category';
import { Modality } from '../models/modality';
import { Course } from '../models/course';
import { CourseOffer } from '../models/courseOffer';
import { OferState } from '../models/oferState';
import { School } from '../models/school';
import { take } from 'rxjs/operators';
import { Force } from '../models/force';
import { Range } from '../models/range';
import { Departament } from '../models/department';
import { TypeTraining } from '../models/typeTraining';
import { UserCourse } from '../models/userCourse';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private _httpService: HttpService) {}

  getSchoolsAndChildren(schoolID: number): Observable<School> {
    let url: string = `/Schools/${schoolID}?filter={"include":{"childrenSchools":"childrenSchools"}}`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCategories(): Observable<Category[]> {
    const filter = { include: ['TypeCourses'] };

    const url: string = `/Categories?filter=${JSON.stringify(filter)}`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getDepartments(): Observable<Departament[]> {
    const url: string = `/Departaments`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getTypeTrainings(): Observable<TypeTraining[]> {
    const url: string = `/TypeTrainings`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getRanges(filter?: string): Observable<Range[]> {
    let url: string = `/Ranges`;
    if (filter) url = `/Ranges?filter=${filter}`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getForces(filter?: string): Observable<Force[]> {
    let url: string = `/Forces`;
    if (filter) url = `/Forces?filter=${filter}`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getModalities(): Observable<Modality[]> {
    const url: string = `/Modalities`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getOferStates(): Observable<OferState[]> {
    const url: string = `/OferStates`;
    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCourses(filter?: string): Observable<Course[]> {
    let url: string = `/Courses`;
    if (!!filter) url = url + '?filter=' + filter;

    return this._httpService.httpGet(url).pipe(take(1));
  }

  getCoursesOfers(filter?: string): Observable<CourseOffer[]> {
    let url: string = `/CoursesOfers`;
    if (!!filter) url = url + '?filter=' + filter;

    return this._httpService.httpGet(url).pipe(take(1));
  }

  getStudentListByCoursesOfers(
    id: number,
    filter?: string
  ): Observable<UserCourse[]> {
    let url: string = `/CoursesOfers/${id}/UserCourses`;
    if (!!filter) url = url + '?filter=' + filter;

    return this._httpService.httpGet(url).pipe(take(1));
  }

  getStudentListCoursesOfersByUserDocument(filter?: string): Observable<any[]> {
  // getStudentListCoursesOfersByUserDocument(document: string, filter?: string): Observable<UserCourse[]> {
    let url: string = `/UserDocuments?filter=${filter}`;
    // let url: string = `/UserDocuments`;
    console.log('url: ',url);
    
    return this._httpService.httpGet(url).pipe(take(1));
  }

  patchUserCourseByID(id: number, data: object): Observable<UserCourse> {
    let url: string = `/UserCourses/${id}`;
    return this._httpService.httpPatch(url, data).pipe(take(1));
  }

  getTypeRequest(): Observable<any> {
    let url: string = `/TypesRequest`;
    return this._httpService.httpGet(url);
  }

  getUsers(filter: string): Observable<any> {
    let url: string = `/ViewUserInfos`;
    if (filter) url = `/ViewUserInfos?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getSubtypeRequests(filter?: string): Observable<any> {
    let url: string = `/SubTypesRequest`;
    if (filter) url = `/SubTypesRequest?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getSubtypeRequest(id: number): Observable<any> {
    let url = `/SubTypesRequest/${id}`;
    return this._httpService.httpGet(url);
  }

  setSubtypeRequest(data: any) {
    let url = `/SubTypesRequest`;
    return this._httpService.httpPost(url, data);
  }

  updateSubtypeRequest(id: number, data: any) {
    let url = `/SubTypesRequest/${id}`;
    return this._httpService.httpPatch(url, data);
  }

  /**
   * get the list of schools
   * @returns
   */
  getSchools(filter?: string): Observable<any[]> {
    let filterData = '';
    if (filter) filterData = `?filter=${filter}`;
    return this._httpService.httpGet(`/Schools${filterData}`);
  }

  getCurrentUser(id: any, filter?: string): Observable<any> {
    let newFilter = '';
    if (filter) newFilter = `?filter=${filter}`;
    return this._httpService.httpGet(`/Userapps/${id}${newFilter}`);
  }
}
