import { Component, Input, OnInit } from '@angular/core';
import { IntcallModulesI } from 'src/app/models/selfAssessment/intCallModules';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import * as allTypes from '../../../models/allTypes/types';

enum vistaGeneral {
  formulario,
  vistaGeneral
}

@Component({
  selector: 'se-form-section',
  templateUrl: './se-form-section.component.html',
  styleUrls: ['./se-form-section.component.css']
})
export class SeFormSectionComponent implements OnInit {

  @Input() intcallModule: IntcallModulesI;
  @Input() myUserIntcallAnswer: UserIntcallAnswer;
  @Input() allTypesOfView;
  @Input() typeOfView;
  @Input() loguedUserID: number;
  @Input() disabledEdit: boolean;

  constructor() {/** */}

  ngOnInit(): void {
    /** */
  }

  isBtnVisible(typeOfView: number): boolean {
    return this.typeOfView == typeOfView;
  }

}
