import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { StateSelectComponent } from './state-select/state-select.component';
import { StatePillComponent } from './state-pill/state-pill.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { DatePipe } from '@angular/common'
import { ProcessCardComponent } from './process-card/process-card.component';
import { ProcessIdEnum, ProcessNameEnum } from 'src/app/models/selfAssessment/enums/processEnum';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';

@Component( {
  selector: 'directiva-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: [ './instruments.component.css' ]
} )
export class InstrumentsComponent implements OnInit {

  constructor (
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService,
    private _datePipe: DatePipe
  ) { }

  //Ag-grid options
  public gridOptions: GridOptions = {

    //default table header
    columnDefs: [
      {
        headerName: 'Proceso',
        field: 'nameIntCall',
        resizable: false,
        cellRenderer: ProcessCardComponent,
        cellStyle: { 'overflow':'hidden' },
        cellRendererParams: ( params: any ) => ( {
          label: params.data.nameIntCall,
          processId: params.data.id,
          tags: params.data.tags
        } ),
        //Filter by tags
        getQuickFilterText: params => {
          return  JSON.parse( params.data.tags ).join(', ')
        }
      },
      {
        headerName: 'Fecha de inicio',
        field: 'dateStart',
        valueGetter: params => {
          return params.data.dateStart || '-';
        }
      },
      {
        headerName: 'Fecha de finalización',
        field: 'dateEnd',
        valueGetter: params => {
          return params.data.dateEnd || '-';
        }
      },
      {
        headerName: 'NO. De participantes',
        field: 'participants',
      },
      {
        resizable: false,
        headerName: 'Estados',
        field: 'state',
        filter: StateSelectComponent,
        flex: 0,
        width: 200,
        cellStyle: { 'display': 'grid', 'place-items': 'center' },
        cellRenderer: StatePillComponent
      },
    ],

    //default table config
    defaultColDef: {
      resizable: true,
      sortable: true,
      autoHeight: true,
      flex: 1,
      cellStyle: { 'display': 'flex', 'align-items': 'center' }
    },

    overlayLoadingTemplate: 'Cargando...',
    overlayNoRowsTemplate: 'No se encontraron registros',
    suppressMenuHide: true,
  }


  //It is an array of objects representing different instruments and their status.
  public rows: any[] = [];

  //Stores the current url name
  public processName: string = '...';

  //GridApi
  private _gridApi: GridApi = undefined;

  //Gets the url name ids enum
  private _processId: any = ProcessIdEnum;

  //Gets the url names enum
  private _processName: any = ProcessNameEnum;


  ngOnInit(): void {
    this.getIntcalls();
  }

  /**
   * Grid ready
   * @param params 
   */
  onGridReady( params: any ) {
    this._gridApi = params.api;
  }

  /**
   * Gets a list of intcalls based on the id of the url
   */
  private getIntcalls(): void {

    this._activatedRoute.paramMap
    .subscribe((params) => {
      const processName: string = params.get('process');
      this.processName = this._processName[ processName ];


      if ( this.processName === undefined ) {
        this._router.navigate( [ '/landing' ] );
        return;
      }
  
      const filter: string = JSON.stringify( {
        where: { and: [ { typeCall: this._processId[ processName ] }, { isTemplate: false } ] }, /* TODO: IS TEMPLATE FALSE? */
        include: [ 'userIntcallAnswers' ]
      } );
  
      this._selfAssessmentService.getIntcalls( filter ).subscribe( {
        next: ( resp: IntcallI[] ) => {
          this.rows = resp.map((e: IntcallI) => ({
            id: e.id,
            participants: e.userIntcallAnswers.length,
            nameIntCall: e.nameIntCall,
            tags: e.tags,
            dateEnd: e.dateEnd ? this._datePipe.transform(e.dateEnd, 'yyyy-MM-dd') : '',
            dateStart: e.dateStart ? this._datePipe.transform(e.dateStart, 'yyyy-MM-dd') : '',
            state: this.checkStatus(e.dateStart, e.dateEnd)
          }));
        }, error: ( err ) => {
          this.onError();
          console.log( err );
        }
      } )
    });
  }

  /**
   * This function toggles the visibility of a modal, as well as toggles the body class of the document.
   */
  public openSection() {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `/dashboard/new-process/${ processName }` ] );
  }

  /**
   * Filters the data displayed in the grid based on the search input value
   * 
   * @param event 
   * @returns 
   */
  public searchData( event: any ) {
    const value = event.target.value;
    if ( this.rows.length === 0 ) {
      this._gridApi.setQuickFilter( null );
      return;
    }

    this._gridApi.setQuickFilter( value );
  }

  /**
   * Sets a status based on start date, end date and current date.
   * @param startDate 
   * @param endDate 
   * @returns 
   */
  private checkStatus( startDate: any, endDate: any ): string {
    const startDateTimestamp = new Date( startDate ).getTime();
    const endDateTimestamp = new Date( endDate ).getTime();
    const currentTimestamp = Date.now();

    if ( startDateTimestamp < currentTimestamp ) {
      return 'programmed';
    } else if ( startDateTimestamp < endDateTimestamp ) {
      return 'ongoing';
    } else {
      return 'ended';
    }
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
        () => this._router.navigate( [ 'dashboard' ] ) );
  }
}