import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import { UserIntcallFieldDocuments } from 'src/app/models/selfAssessment/userIntcallFieldDocuments';
import { AuthService } from 'src/app/services/auth.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import * as allTypes from '../../models/allTypes/types';
import { environment } from 'src/environments/environment';

enum vistaGeneral {
  formulario,
  vistaGeneral,
  vistaTabla
}
@Component({
  selector: 'directiva-self-evaluation-judge',
  templateUrl: './self-evaluation-judge.component.html',
  styleUrls: ['./self-evaluation-judge.component.css']
})
export class SelfEvaluationJudgeComponent implements OnInit {
  /** ****************************** View Data ****************************** */
  public ALL_TYPES_OF_VIEW = allTypes.enumTypeOfView;
  public typeOfView = allTypes.enumTypeOfView.calificar;
  public allBtnViewSelected = vistaGeneral;
  public btnViewSelected = vistaGeneral.formulario;
  public dataLoaded: boolean = false;
  public disabledEdit: boolean = false;
  public showData: boolean = false;
  /** ****************************** ********** ****************************** */
  public idIntcall: number;
  public typeIntcall: string;
  public intcall: IntcallI;
  public loguedUserID: number;
  public myUserIntcallAnswer: UserIntcallAnswer;
  public containerCertsName: string = environment.containerCertsName;

  private subscriptionSavePartialData: Subscription
  private subscriptionSaveFinishData: Subscription

  constructor(
    public _selfAssessmentService: SelfAssessmentService,
    public _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    public _router: Router,
  ) {
    const LOGUED_USER_ID = this._authService.getCurrentUserID();
    console.log('LOGUED_USER_ID',LOGUED_USER_ID);
    if (LOGUED_USER_ID == null) this._router.navigate(['/register']);
    else this.loguedUserID = +LOGUED_USER_ID;
    this.subscriptionSaveDataMethod();
    this.initializeData();
  }

  ngOnInit(): void {
    /** */
  }

  ngOnDestroy() {
    this.subscriptionSavePartialData.unsubscribe();
    this.subscriptionSaveFinishData.unsubscribe();
  }

  initializeData(): void {
    this._activatedRoute.params.subscribe(params => {
      if (params["id"]) {
        const idIntcall = params["id"];
        this.disabledEdit = true;
        this.typeOfView = this.ALL_TYPES_OF_VIEW.calificar;
        this.getAllIntcallData(idIntcall);
      } else this._router.navigate(['/user/my-account']);
    });
  }

  getAllIntcallData(inIncall:number): void {
    this._selfAssessmentService.getIntcallById(inIncall).pipe(take(1)).subscribe(
      data => {
        console.log('getAllIntcallData:',data);
        this.intcall = data;
        this.intcall.tagsDecoded = JSON.parse(this.intcall.tags);
        /* ********************************************************** */
        /* ********************************************************** */
        /* ********************************************************** */
        this.myUserIntcallAnswer = this.intcall.userIntcallAnswers[0];
        console.log('*************************************');
        console.log('*************************************');
        console.log('*************************************');
        console.log('this.myUserIntcallAnswer',this.myUserIntcallAnswer);
        console.log('*************************************');
        console.log('*************************************');
        console.log('*************************************');
        /* ********************************************************** */
        /* ********************************************************** */
        /* ********************************************************** */
        if (this.typeOfView == this.ALL_TYPES_OF_VIEW.calificar) {
          this.getUserAnswers();
        } else this.showDataLoaded();
        this.showData = false;
      }, err => {
        console.log('getAllIntcallData err:',err);
      }
    )
  }
  getUserAnswers(): void {
    this.intcall.userIntcallAnswers.forEach(element => {
      if (element.userID == this.loguedUserID) {
        this.myUserIntcallAnswer = element;
        if (this.myUserIntcallAnswer.isFinalAnswer) this.disabledEdit = true
        this.showFormData();
      }
      this.showDataLoaded();
    });
  }
  userParticipate(data): void {
    this.showFormData();
  }

  showFormData(): void {
    this.showData = true;
  }
  showDataLoaded(): void {
    this.dataLoaded = true;
  }

  subscriptionSaveDataMethod():void {
    this.subscriptionSavePartialData = this._selfAssessmentService.sharedSaveEvent.subscribe(data => {
      if (data) this.patchUserIntcallAnswer();
    });
    this.subscriptionSaveFinishData = this._selfAssessmentService.sharedSaveFinishEvent.subscribe(data => {
      if (data) this.patchUserIntcallAnswerFinish();
    });
  }

  showModalFilesMethod() {
    this.showModalFiles = true;
  }
  showModalSave: boolean = false;
  showModalSave2: boolean = false;
  showModalFiles: boolean = false;
  patchUserIntcallAnswer(): void {
    let data = {
      id: this.myUserIntcallAnswer.id,
      updatedAt: new Date()
    }
    this._selfAssessmentService.patchUserIntcallAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('patchUserIntcallAnswer:',data);
        this.showModalSave = true;
      }, err => {
        console.log('patchUserIntcallAnswer err:',err);
      }
    )
  }
  patchUserIntcallAnswerFinish(): void {
    let data = {
      id: this.myUserIntcallAnswer.id,
      isFinalAnswer: true,
      updatedAt: new Date()
    }
    this._selfAssessmentService.patchUserIntcallAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('patchUserIntcallAnswerFinish:',data);
        this.showModalSave = true;
      }, err => {
        console.log('patchUserIntcallAnswerFinish err:',err);
      }
    )
  }
  idUsuarioPAraCalificar: number;
  userParaCalificar(iDusuarioSelcted): void {
    this.dataLoaded = false;
    console.log('iDusuarioSelcted',iDusuarioSelcted);
    this.idUsuarioPAraCalificar = iDusuarioSelcted;
    this.getUserAnswersParaCalificar(iDusuarioSelcted);
  }
  cancelViewCalificar(): void {
    this.showData = false;
  }

  isBtnVisible(typeOfView: number): boolean {
    return this.typeOfView == typeOfView;
  }
  getUserAnswersParaCalificar(idUsuario: number): void {
    this.intcall.userIntcallAnswers.forEach(element => {
      if (element.userID == idUsuario) {
        this.myUserIntcallAnswer = element;
        this.disabledEdit = true
        this.showFormData();
      }
      this.showDataLoaded();
      console.log('myUserIntcallAnswer',this.myUserIntcallAnswer);
      
    });
  }
  changeViewNavLeft(view): void {
    this.btnViewSelected = view;
    console.log('vista cambiada',view);
  }

  public fileUrl: string = '';
  public nameFile: string = '';
  public descript: string = '';
  onFileInput( event: any ) {
    console.log('event',event);
    
    this.fileUrl = event.urlFile;
    this.nameFile = event.nameFile;
    this.descript = event.descript;
    this.getDataFromUploadFileComponent();
  }

  public fileReqTemp: UserIntcallFieldDocuments;
  getDataFromUploadFileComponent(): void {
    let data = {
      intcallID: this.myUserIntcallAnswer.intcallID,
      UserIntcallAnswerID: this.intcall.userIntcallAnswers[0].id,
      documentLink: this.fileUrl,
      name: this.nameFile,
      description: this.descript,
    }
    this.createUserIntcallFieldDocuments(data);
  }
  createUserIntcallFieldDocuments(data: object): void {
    console.log('data',data);
    
    this._selfAssessmentService.postUserIntcallFieldDocuments(data).pipe(take(1)).subscribe(
      data => {
        console.log('createUserIntcallFieldDocuments success', data);
      },
      err => {
        console.log('createUserIntcallFieldDocuments fail', err);
      }
    );
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }

}
