import { Component, Input, OnInit } from '@angular/core';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';

@Component({
  selector: 'header-description',
  templateUrl: './header-description.component.html',
  styleUrls: ['./header-description.component.css']
})
export class HeaderDescriptionComponent implements OnInit {

  constructor() { 
    //
  }

  private _intcall:IntcallI = {} as IntcallI;
  @Input('intcall') set intcall(value: IntcallI){
    this._intcall = value;
    this.tags = value.tags ? JSON.parse(value.tags) : [];
    this.states = this.checkStatus(this._intcall.dateStart, this._intcall.dateEnd);
  }

  get intcall(){
    return this._intcall;
  }

  //Stores the tagas
  public tags:string[] = [];

  //Stores the current state
  public states: string = '';

  ngOnInit(): void {
    //
  }

  /**
   * Sets a status based on start date, end date and current date.
   * @param startDate 
   * @param endDate 
   * @returns 
   */
  private checkStatus( startDate: any, endDate: any ): string {
    const startDateTimestamp = new Date( startDate ).getTime();
    const endDateTimestamp = new Date( endDate ).getTime();
    const currentTimestamp = Date.now();

    if ( startDateTimestamp < currentTimestamp ) {
      return 'programmed';
    } else if ( startDateTimestamp < endDateTimestamp ) {
      return 'ongoing';
    } else {
      return 'ended';
    }
  }
}
