import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralResultsComponent } from './general-results.component';
import { SwitchModule } from '../shared/switch/switch.module';
import { IntcallHeaderModule } from '../shared/intcallHeader/intcall-header.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    GeneralResultsComponent,
  ],
  imports: [
    CommonModule,
    SwitchModule,
    IntcallHeaderModule,
    RouterModule
  ],
  exports:[
    GeneralResultsComponent,
  ]
})
export class GeneralResultsModule { }
