import { Component, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Course } from 'src/app/models/course';
import { Departament } from 'src/app/models/department';
import { Modality } from 'src/app/models/modality';
import { Range } from 'src/app/models/range';
import { Force } from 'src/app/models/force';
import { School } from 'src/app/models/school';
import { TypeTraining } from 'src/app/models/typeTraining';
import { CourseService } from 'src/app/services/course.service';
import { DirectivasService } from 'src/app/services/directivas.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import { CourseOffer } from 'src/app/models/courseOffer';
import { OferState, OFERSTATES, TypeOferState } from 'src/app/models/oferState';
import { TypesCourse } from 'src/app/models/typesCourse';

@Component({
  selector: 'directiva-multi-directiva',
  templateUrl: './multi-directiva.component.html',
  styleUrls: ['./multi-directiva.component.sass']
})
export class MultiDirectivaComponent implements OnInit {

  public year: number;
  public schools: School[] = [];
  public courseList: Course[];

  public newMassiveOffers: Course[] = [];


  public modalities: Modality[] = [new Modality()];
  public ranges: Range[] = [new Range()];
  public forces: Force[] = [new Force()];
  public deptos: Departament[];
  public typeTrainings: TypeTraining[];
  public typeCourses: TypesCourse[] =[]
  private subscriptionSchool: Subscription;

  constructor(
    private _directivasService: DirectivasService,
    private courseService: CourseService,
    public sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.getFixedFilters();
    this.subscriptionSchool = this.sharedService.sharedyearSchoolFilter.subscribe(data => {
      this.year = data.year;
      this.schools = data.schools;
      this.loadCourses();
    });
  }

  ngOnDestroy() {
    this.subscriptionSchool.unsubscribe();
  }

  public getFixedFilters() {

    const filterRange = { where: { ForceID: 1 } }

    forkJoin([
      this.courseService.getModalities(),
      this.courseService.getForces(),
      this.courseService.getRanges(JSON.stringify(filterRange)),
      this.courseService.getDepartments(),
      this.courseService.getTypeTrainings()
    ]).subscribe(Val => {

      [this.modalities, this.forces, this.ranges, this.deptos, this.typeTrainings] = Val;

    });
  }

  loadCourses() {

    const filter = {
      order: "NameCourse ASC",
      where: { SchoolID: this.schools[0].id },
      include: [
        {
          relation: 'CourseOfer',
          scope: {
            order: 'NameCourseOfer ASC',
            where: {
              Since: { between: [new Date(this.year - 1, 0), new Date(this.year, 0)] }
            }
          }
        }
      ]
    };

    this._directivasService.getCourses(JSON.stringify(filter)).pipe(take(1)).subscribe(data => {
      this.courseList = data.filter(course => {
        return course.CourseOfer.length > 0
      });
      let filterTypeCourses = {
        order: "NameTypeCourse ASC",
        where: {
          and: [
            { "id": { inq:  this.courseList.map(x => x.TypeCourseID) } }
          ]
        },
        include:["Category"]
      }

      this._directivasService.getTypeCourses(JSON.stringify(filterTypeCourses)).pipe(take(1)).subscribe(typeCourses=>{
        this.typeCourses = typeCourses
      })


    })

  }



  selectCourse(course: Course): void {
    const pos = this.newMassiveOffers.findIndex(x => x.id == course.id)
    if (pos >= 0)
      this.newMassiveOffers.splice(pos, 1)
    else {
      let newCourse = course
      newCourse.CourseOfer = course.CourseOfer.map(coff => {
        let newOffer: CourseOffer = new CourseOffer();

        newOffer.CourseID = coff.CourseID;
        newOffer.SchoolID = coff.SchoolID;
        newOffer.TypeCourseID = coff.TypeCourseID;

        newOffer.Profiles = coff.Profiles;
        newOffer.Forces = coff.Forces;
        newOffer.ForeignPeople = coff.ForeignPeople
        newOffer.TotalPeople = coff.TotalPeople

        newOffer.ModalityID = coff.ModalityID;
        newOffer.OferState = TypeOferState.pendiente;
        newOffer.IsActive = false;
        newOffer.IsDirectiva = true;

        newOffer.TypeTrainingID = coff.TypeTrainingID
        newOffer.DepartamentID = coff.DepartamentID
        newOffer.Altitud = coff.Altitud;
        newOffer.Longitud = coff.Longitud

        return newOffer;
      })
      this.newMassiveOffers.push(newCourse)
    }

  }

  isSelectedCourse(CourseID: number): boolean {
    const pos = this.newMassiveOffers.findIndex(x => x.id == CourseID)
    if (pos >= 0)
      return true
    else
      return false
  }

  addNewOffer(CourseID: number): void {
    const pos = this.newMassiveOffers.findIndex(x => x.id == CourseID)
    if (pos >= 0) {
      let newOffer: CourseOffer = new CourseOffer();

      newOffer.CourseID = this.newMassiveOffers[pos].id;
      newOffer.SchoolID = this.newMassiveOffers[pos].SchoolID;
      newOffer.TypeCourseID = this.newMassiveOffers[pos].TypeCourseID;

      newOffer.Profiles = this.newMassiveOffers[pos].profiles;
      newOffer.Forces = [];
      newOffer.ForeignPeople = 0
      newOffer.TotalPeople = 0

      newOffer.ModalityID = 1;
      newOffer.OferState = TypeOferState.pendiente;
      newOffer.IsActive = false;
      newOffer.IsDirectiva = true;

      this.newMassiveOffers[pos].CourseOfer.push(newOffer)
    }

  }

  removeOffer(CourseID: number, offerIndex: number): void {
    const pos = this.newMassiveOffers.findIndex(x => x.id == CourseID)
    if (pos >= 0)
      this.newMassiveOffers[pos].CourseOfer.splice(offerIndex, 1)
  }

  getCoursesByTypeCourseID(typeCourseID:number){
    return this.courseList.filter(x=>x.TypeCourseID==typeCourseID)
  }

  /***************************************  FUNCIONES  PARA CURSOS MASIVOS ********************************/




  createMassiveOffers(): void {
    Swal.fire({
      title: 'Crear Conogramas',
      text: 'Esta seguro de crear el cronograma con ' + this.newMassiveOffers.length + ' cursos seleccionados?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.sharedService.swalLoading("Creando Programa ");
        let offerListToCreate: CourseOffer[] = [];
        const today = new Date()
        //Check all data
        this.newMassiveOffers.forEach(course => {
          offerListToCreate.push(...course.CourseOfer)
        });

        const existErrors = offerListToCreate.findIndex(offer => {
          if (!offer.NameCourseOfer || !offer.Since || !offer.Until)
            return true
          if (offer.NameCourseOfer == "")
            return true
          if (new Date(offer.Since) < today || new Date(offer.Until) < today)
            return true
          if (new Date(offer.Since) > new Date(offer.Until))
            return true
        })

        if (existErrors >= 0) {
          this.sharedService.sleep(100).then(() => {
            this.sharedService.swalError("Error al crear cronograma", "Revisar la oferta: " + (existErrors + 1))
          })
        }
        else {
          this._directivasService.createMassiveOffers(offerListToCreate).pipe(take(1))
            .subscribe(newData => {
              this.sharedService.sleep(1000).then(() => {

                this.sharedService.swalEndLoad()

              })
            }, err => {
              this.sharedService.sleep(1000).then(() => {
                this.sharedService.swalError("Error", "Hubo un error al almacenar, intenta de nuevo")
              })
            })
        }


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // );
      }
    });
  }


}
