import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { defaultIfEmpty, switchMap } from 'rxjs/operators';
import { SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { JudgeIntcallI } from 'src/app/models/selfAssessment/judgeIntCall';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import { UserSearchBarComponent } from '../../shared/user-search-bar/user-search-bar.component';
import { DocumentIntcallI } from 'src/app/models/selfAssessment/documentIntcall';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';

@Component( {
  selector: 'publish-section',
  templateUrl: './publish-section.component.html',
  styleUrls: [ './publish-section.component.css' ]
} )
export class PublishSectionComponent implements OnInit {

  constructor (
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {
    //
  }

  @ViewChild( UserSearchBarComponent ) userSearchBarComponent: UserSearchBarComponent;

  //Send the name of the section to be activated
  @Output( 'onChangeSection' ) onChangeSection: EventEmitter<SectionToShowEnum> = new EventEmitter();

  //Send a check to activate the main form validations
  @Output( 'checkMainFormData' ) checkMainFormData: EventEmitter<boolean> = new EventEmitter();

  //Gets the general section data
  @Input( 'getGeneralData' ) getGeneralData: any[] = [];

  //Stores the current section name
  @Input( 'sectionName' ) sectionName: string = '...';

  //Gets the internal call document list
  @Input( 'getFilesData' ) getFilesData: any[] = [];

  //Gets the main form data
  @Input( 'getFormData' ) getFormData: any = {};

  //Gets the type section id
  @Input( 'typeSectionId' ) typeSectionId: number = 0;

  //Gets the current user info
  @Input( 'currentUserInfo' ) currentUserInfo: any = {};

  //It is used to store the reference to a specific section.
  public sectionToShow: any = SectionToShowEnum;

  //Stores the judges to add
  public judgesToAdd: any[] = [];

  //#region Set the publication and end dates, and whether they are editable or not.
  public showPublishDate: boolean = false;
  public showEndDate: boolean = false;
  private _publishDate: Date = undefined;
  private _endDate: Date = undefined;
  //#endregion

  ngOnInit(): void {
    //
  }

  /**
   *  this function updates an array of judges to be added by filtering out judges that are already present in the users array, 
   * and then adding any new judges that were not previously present.
   * 
   * @param users It takes an array of users 
   */
  public addJudge( users: any[] ) {

    const filteredJudges = this.judgesToAdd.filter( judge => users.some( user => user.id === judge.judgeInfo.id ) )

    users.forEach( user => {
      const exist = filteredJudges.some( filtered => filtered.judgeInfo.id === user.id );
      if ( !exist )
        filteredJudges.push( {
          judgeInfo: user,
          percentage: 0
        } )
    } );

    this.judgesToAdd = filteredJudges;
  }

  /**
   * This method is used to update the percentage value of a judge in the judgesToAdd array when a user changes the percentage input value.
   * @param data 
   */
  public setUserPercentage( data: any ) {
    this.judgesToAdd.forEach( e => {
      if ( e.judgeInfo.id === data.userId )
        e.percentage = data.value
    } );
  }

  /**
   * Handles the removal of a judge from the list of judges to be added to an assessment. 
   * It takes a user object as a parameter, which represents the judge to be removed.
   * @param user It takes a user object as a parameter
   */
  public removeJudge( user: any ) {
    this.userSearchBarComponent.removeUser( user );
  }

  /**
   * Creates a new record for a intCall and its related tables
   */
  public confirmData() {
    this._selfAssessmentAlertService.swalLoading( 'Procesando...', 'Estamos procesando la solicitud. Por favor, espera un momento' );

    if ( !this.isModuleValid() )
      return;

    this.setIntcall().pipe(
      switchMap( ( intcall: any ) => forkJoin( {
        judgesIntcall: forkJoin( [ ...this.setJudges( intcall.id ) ] ),
        intCallModules: forkJoin( [ ...this.setIntcallModules( intcall.id ) ] ),
        documentIncall: forkJoin( [ ...this.setDocumentsIntcall( intcall.id ) ] ).pipe( defaultIfEmpty( [] ) ),
      },
      ) )
    ).subscribe( {
     complete: () => {
        this._selfAssessmentAlertService.success( 'Hecho!', 'El proceso se ha creado con éxito', () => {
          const processName: string = this._activatedRoute.snapshot.params.process;
          this._router.navigate( [ `dashboard/${ processName }` ] );
        } );
      },
      error: ( err ) => {
        this.onError();
        console.log( err );
      }
    } )
  }

  /**
   * Displays a date picker when the user clicks on the date text field
   * @param event 
   */
  public showDatePicker( event: any ) {
    event.target.showPicker();
  }

  /**
   * This function sets the value of this._publishDate to the value of the event target.
   * @param event 
   */
  public getPublishDate( event: any ) {
    this._publishDate = event.target.value;
  }

  /**
   * This function sets the value of this._endDate to the value of the event target.
   * @param event 
   */
  public getEndDate( event: any ) {
    this._endDate = event.target.value;
  }

  /**
   * Convert a comma-separated string of words into a string array that can be used as tags.
   * @param data Is a string that contains comma-separated words.
   * @returns Returns a string array in JSON format.
   */
  private tagsToStringArray( data: string ) {
    const words = data.split( ',' );
    const wordsArray = JSON.stringify( words );
    return wordsArray;
  }

  /**
   * Sets up an Intcall object and returns an Observable of type IntcallI which is returned by the _selfAssessmentService.setIntcall() method.
   * @returns Returns the _selfAssessmentService.setIntcall() method, for making an HTTP request to create the Intcall.
   */
  private setIntcall(): Observable<IntcallI> {
    const schoolId = this.currentUserInfo.roleMappings[ 0 ].SchoolID;

    const intcall: IntcallI = {
      nameIntCall: this.getFormData.nameIntCall,
      typeCall: this.typeSectionId,
      schoolID: schoolId,
      descript: this.getFormData.descript,
      tags: this.tagsToStringArray( this.getFormData.tags ),
      isTemplate: false,
      dateEnd: this.showEndDate ? this._endDate : null,
      dateStart: this.showPublishDate ? this._publishDate : null,
    }

    return this._selfAssessmentService.setIntcall( intcall );
  }

  /**
   * This function creates an array of JudgeIntcallI objects based on the list of judges to add,
   * and returns an array of Observables to set each JudgeIntcallI object using the self assessment service.
   * @param intcallID The ID of the Intcall.
   * @returns An array of Observables that set each JudgeIntcallI object using the self assessment service.
   */
  private setJudges( intcallID: number ): Observable<JudgeIntcallI>[] {
    const judges: JudgeIntcallI[] = this.judgesToAdd.map( e => ( {
      intcallID: intcallID,
      percentageJudge: e.percentage,
      userID: e.judgeInfo.id,
      createdAt: new Date()
    } ) );

    return judges.map( e => this._selfAssessmentService.setJudgeIntcall( e ) );
  }

  /**
   * Sets files for a given intcall ID and returns an array of observables for each request.
   * @param intcallID The ID of the intcall.
   * @returns An array of observables for each request made.
   */
  private setDocumentsIntcall( intcallID: number ): Observable<any>[] {

    this.getFilesData.forEach( ( e: DocumentIntcallI ) => {
      e.intcallID = intcallID;
      e.userID = this.currentUserInfo.id;
    } );

    // Map each file data to a request with the merged object data.
    return this.getFilesData.map( ( e ) => this._selfAssessmentService.setDocumentIntcall( e ) );
  }

  /**
   * Creates an array of observables that are used to create IntcallModulesI and IntcallFieldsI entries
   * in the database.
   *
   * @param intcallID The ID of the Intcall to which the IntcallModulesI and IntcallFieldsI belong.
   * @returns An array of observables.
   */
  private setIntcallModules( intcallID: number ): Observable<any>[] {
    return this.getGeneralData.map( ( element: any ) => {
      const intCallModule:any = {
        nameModule: element.moduleInfo.nameModule,
        descript: element.moduleInfo.descript,
        intcallID,
        percentageModule: element.moduleInfo.percentageModule,
      };

      // Create an observable to add the IntcallModulesI to the database
      const intCallModuleObs = this._selfAssessmentService.setIntcallModules( intCallModule );

      const intCallFields:any[] = element.moduleFields.map( field => ( {
        nameField: field.nameField,
        intcallID,
        typeField: field.typeField,
        moduleID: 0, // The module ID is set later 
        percentageField: field.percentageField,
      } ) );


      return intCallModuleObs.pipe(
        switchMap( ( intCallModule: any ) => {
          // Set the moduleID of each IntcallFieldsI object to the ID of the IntcallModulesI that was just created
          intCallFields.forEach( ( field ) => field.moduleID = intCallModule.id );
          const intCallFieldsObs = intCallFields.map( obs => this._selfAssessmentService.setIntcallFields( obs ) );

          return forkJoin( [ ...intCallFieldsObs ] );
        } )
      );
    } );
  }

  /**
   * This function checks if some fields are valid or not and returns a boolean result accordingly.
   * @returns a boolean
   */
  private isFormValid(): boolean {
    this.checkMainFormData.emit( true );

    const { nameIntCall, descript, tags } = this.getFormData;

    if ( nameIntCall === undefined || nameIntCall === '' ) return false;

    if ( descript === undefined || descript === '' ) return false;

    if ( tags === undefined || tags === '' ) return false;

    return true;
  }

  /**
   * Validates the module judges, judges weighting and main form
   * @returns true if the validation succeeded, false otherwise.
   */
  private isModuleValid(): boolean {
    let totalPercentage: number = 0;

    if ( this.judgesToAdd.length === 0 ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'Aún no has agregado ningún juez' );
      return false;
    }

    for ( const judge of this.judgesToAdd ) {
      totalPercentage += judge.percentage ?? 0;
    }

    if ( totalPercentage !== 100 ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'La ponderación en la lista de jueces debe sumar 100%' );
      return false;
    }

    if ( !this.isFormValid() ) {
      this._selfAssessmentAlertService.swalError( '¡Algo salió mal!', 'Hay uno o más campos en el formulario por completar' );
      return false;
    }

    return true;
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    const processName: string = this._activatedRoute.snapshot.params.process;
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
      () => this._router.navigate( [ `dashboard/${ processName }` ] ) );
  }
}