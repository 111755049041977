import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'directiva-book-number',
  templateUrl: './book-number.component.html',
  styleUrls: ['./book-number.component.css']
})
export class BookNumberComponent implements ICellRendererAngularComp {
  public userCourseID: number;
  public actualValue: string;

  public editData: boolean = false;
  public dataToUpdate: string;

  constructor(
    public _courseService: CourseService,
  ) { }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.initAllData(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    // this.cellValue = this.getValueToDisplay(params);
    this.initAllData(params);
    return true;
  }

  initAllData(params: ICellRendererParams) {
    this.userCourseID = params.data.localData_userCourseID;
    this.dataToUpdate = params.data.nLibro;
    this.actualValue = params.data.nLibro;
  }

  updateData() {
    if (this.dataToUpdate == undefined) return;
    this.patchData();
  }
  patchData() {
    let data = {
      bookNumber: this.dataToUpdate
    }
    this._courseService.patchUserCourseByID(this.userCourseID, data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.actualValue = this.dataToUpdate;
    });
  }
}
