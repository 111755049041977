import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'files-section',
  templateUrl: './files-section.component.html',
  styleUrls: [ './files-section.component.css' ]
} )
export class FilesSectionComponent implements OnInit {

  constructor ( ) {
    //
  }

  //send the name of the section to be activated
  @Output( 'onChangeSection' ) onChangeSection: EventEmitter<SectionToShowEnum> = new EventEmitter();

  //Sends file list
  @Output('fileData') fileData:EventEmitter<any[]> = new EventEmitter();

  //It is used to store the reference to a specific section.
  public sectionToShow:any = SectionToShowEnum;

  //Stores the files to be uploaded to the database
  public fileList:any[] = [];

  ngOnInit(): void {
    //
  }

  /**
   * Map the DocumentIntcall and emits the result
   * @param data 
   */
  public setDocumentIncall( data:any ){
    this.fileList.push({
      intcallID:0,
      nameDocument: data.nameFile,
      documentLink: data.urlFile,
      desc: data.descript,
      userID:0,
      createdAt: new Date(),
    });

    this.fileData.emit( this.fileList );
  }

  /**
   * Removes an item from the list
   * @param index 
   */
  public removeItem( index:number ) {
    this.fileList.splice( index, 1); 
    this.fileData.emit( this.fileList );
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }
}
