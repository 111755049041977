import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import * as allTypes from '../../../models/allTypes/types';

enum vistaGeneral {
  formulario,
  vistaGeneral
}

@Component({
  selector: 'directiva-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.css']
})
export class EvaluateComponent implements OnInit {
  /** ****************************** Table Data ****************************** */
  columnDefs: ColDef[] = [
    { field: 'Usuarios' },
    { field: 'Estados' },
    { field: 'Última modificación' },
    { field: 'Fecha de inicio' },
    { field: 'Fecha de finalizción' },
    { field: 'Puntaje' }
  ];
  rowData = [
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' },
    { Usuarios: 'santiago.salsedo@cedoc.edu.co', Estados: 'En proceso', 'Última modificación': '7/11/19', 'Fecha de inicio': '5/19/12', 'Fecha de finalizción': '-', Puntaje: '798' }
  ];
  /** ****************************** View Data ****************************** */
  public allTypesOfView = allTypes.enumTypeOfView;
  public typeOfView = allTypes.enumTypeOfView.diligenciar;
  public allBtnViewSelected = vistaGeneral;
  public btnViewSelected = vistaGeneral.formulario;
  public dataLoaded: boolean = false;
  public disabledEdit: boolean = false;

  /** ****************************** ********** ****************************** */
  public idIntcall: number;
  public typeIntcall: string;
  public intcall: IntcallI;

  constructor(
    public _selfAssessmentService: SelfAssessmentService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._activatedRoute.params.subscribe(params => {
      if (params["id"]) this.idIntcall = params["id"];
      if (params["type"]) this.typeIntcall = params["type"]; // user or judge
      if (this.typeIntcall == 'judge') {
        this.disabledEdit = true; // user or judge
        this.typeOfView = this.allTypesOfView.calificar;
      }
      console.log('idIntcall',this.idIntcall);
      console.log('typeIntcall',this.typeIntcall);
      this.getAllIntcallData(this.idIntcall);
    });
  }

  ngOnInit(): void {
    /** */
  }

  getAllIntcallData(inIncall:number): void {
    this._selfAssessmentService.getIntcallById(inIncall).pipe(take(1)).subscribe(
      data => {
        console.log('data:',data);
        this.intcall = data;
        console.log('tags:',this.intcall.tags);
        console.log('tags:',JSON.stringify(this.intcall.tags));
        console.log('tags:',JSON.stringify(JSON.stringify(this.intcall.tags)));
        console.log('tags:',JSON.parse(this.intcall.tags));
        this.intcall.tagsDecoded = JSON.parse(this.intcall.tags);
        this.dataLoaded = true;
      }, err => {
        console.log('err:',err);
      }
    )
  }

  changeTypeOfView(typeOfView: number): void {
    this.btnViewSelected = typeOfView;
  }
  isBtnVisible(typeOfView: number): boolean {
    return this.typeOfView == typeOfView;
  }
  isBtnSelected(optionToShow: number): boolean {
    return this.btnViewSelected == optionToShow;
  }


}
