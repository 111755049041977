import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttachmentService } from 'src/app/services/attachment.service';

@Component( {
  selector: 'upload-file-box',
  templateUrl: './upload-file-box.component.html',
  styleUrls: [ './upload-file-box.component.css' ]
} )
export class UploadFileBoxComponent implements OnInit {

  @ViewChild( 'fileInput' ) fileInput: ElementRef;

  @Input() containerName: string;
  @Input() maxMbSize: number = 5000000;//5M
  @Output() responseDataFile: EventEmitter<any> = new EventEmitter();

  public dataFile: any;
  public fileToUpload?: File;
  public errorNoFileName: boolean = false;
  public isArchiveError: boolean = false;
  public isSubmitting: boolean = false;

  @Input( 'title' ) title: string = 'Buscar archivo'
  @Input( 'smallTitle' ) smallTitle: string = 'pdf (max 5MB)'
  constructor (
    private _attachmentService: AttachmentService,
  ) { }

  ngOnInit(): void {
    this.dataFile = {
      nameFile: "",
      descript: "",
      urlFile: ""
    }
  }

  onFileInput( event: any ) {
    if ( !<File>event.target.files[ 0 ] ) return;
    this.fileToUpload = <File>event.target.files[ 0 ];

    if ( this.fileToUpload.size > this.maxMbSize ) {
      this.title = 'Error'
      this.isArchiveError = true;
    } else {
      this.title = 'Cambiar';
      this.isArchiveError = false;
    }

  }

  uploadAttachment() {
    if ( this.dataFile.nameFile == '' ) {
      this.errorNoFileName = true;
      return;
    }

    this.isSubmitting = true;
    this._attachmentService.storageFile( this.containerName, this.fileToUpload! ).then( ( result: any ) => {
      this.dataFile.urlFile = `/attachments/${ this.containerName }/download/${ result[ 'result' ][ 'files' ][ 'file' ][ 0 ][ 'name' ] }`;
      this.createFileReq();
    } );
  }

  createFileReq(): void {
    this.responseDataFile.emit( this.dataFile );
    this.resetFileInfo();
  }

  /**
   * reset the file upload button
   */
  resetFileInfo() {
    this.isSubmitting = false;

    this.dataFile = {
      nameFile: "",
      descript: "",
      urlFile: ""
    }

    this.fileInput.nativeElement.value = '';
    this.fileToUpload = undefined;
    this.errorNoFileName = false;
    this.isArchiveError = false;
    this.title = 'Buscar archivo';
  }

}
