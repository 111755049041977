
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.css']
})

export class TablePaginationComponente implements OnInit {
  @Output("onPageChange") onPageChange: EventEmitter<{
    currentPage: number,
    data: any[]
  }> = new EventEmitter();
  @Input("itemsPerPage") itemsPerPage: number = 0;
  @Input("totalPages") totalPages: number = 0;
  @Input("data") data: any[] = [];

  public fileName = '';
  public fileToUpload?: File;
  public fileHasErrors: boolean = false;
  public uploadFileText: string = 'Elija el archivo';
  public showTablePreview: boolean = false;
  public currentPage: number = 0;
  public get maxPages() {
    return Math.ceil((this.totalPages / this.itemsPerPage) - 1)
  }
  constructor() { }
  ngOnInit(): void { }

  /**
   * Allows to paginate
   * @param next
   */
  public tableDataPagination(next: boolean) {
    if (next && this.currentPage < Math.ceil((this.totalPages / this.itemsPerPage) - 1))
      this.currentPage++;
    else if (!next && this.currentPage > 0)
      this.currentPage--;

    const from = this.currentPage * this.itemsPerPage;
    const to = from + this.itemsPerPage;

    this.onPageChange.emit({
      currentPage: this.currentPage,
      data: this.data.slice(from, to)
    });
  }

  /**
   * Shows the pagination
   * @returns
   */
  public previewTablePaginationText() {
    const from = this.currentPage * this.itemsPerPage + 2; // Ajusta el inicio de la página
    const to = Math.min(from + this.itemsPerPage - 1, this.data.length + 1);
    return `de ${this.data.length === 0 ? 0 : from} a ${this.data.length === 0 ? 0 : to} / total: ${this.data.length} registros`;
  }
}