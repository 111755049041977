import { Component, OnInit } from '@angular/core';
import { DirectivasService } from 'src/app/services/directivas.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';

import { Router, ActivatedRoute } from '@angular/router';
import { CourseOffer } from 'src/app/models/courseOffer';

import { Course } from 'src/app/models/course';
import { Subscription } from 'rxjs';
import { TypesCourse } from 'src/app/models/typesCourse';
import { RCourseOfeUpdate } from 'src/app/models/r-course-ofe-update';

import { OFERSTATES, TypeOferState } from 'src/app/models/oferState';


@Component({
  selector: 'matriz-directiva',
  templateUrl: './matriz.component.html',
  styleUrls: ['./matriz.component.css', './matriz.component.sass']
})
export class MatrizComponent implements OnInit {


  public OFERSTATES = OFERSTATES;
  public courseOfferList: CourseOffer[];

  public courseSelected: CourseOffer = new CourseOffer();
  public showDetails: boolean = false

  public typeCourses: TypesCourse[] = [];
  public courses: Course[] = [];
  public rCourseOfers: RCourseOfeUpdate[]=[];

  public isHistory = false;


  private subscriptionCourseList: Subscription

  constructor(
    private _directivasService: DirectivasService,
    public sharedService: SharedService,
    private _router: Router,
    private _activedRoute: ActivatedRoute
  ) {

    this._activedRoute.params.subscribe(params => {
      if (params["id"]) {
        this.isHistory=true;
        this.showHistoral(params["id"]);
      }
    });

  }


  ngOnInit(): void {
    this.subscriptionCourseList = this.sharedService.sharedcourseOfferlist.subscribe(list => {
      this.courseOfferList = list;
    });
  }

  ngOnDestroy() {
    this.subscriptionCourseList.unsubscribe()
  }

  // Funcion para cargar recuadro derecho con informacion de detalles
  selectCourse(courseOfer: CourseOffer) {
    this.courseSelected = courseOfer;
    this.showDetails = true
  }

  goTo(url) {
    this._router.navigate([url]);
  }

  //Mostrar historial 
  showHistoral(courseID: number) {
    let filter = {
      where: { CourseOferID: courseID },
      include: [{ relation: 'userapp' } ]
    }

    this._directivasService.getCourseOferUpdate(JSON.stringify(filter)).pipe(take(1)).subscribe(data => {
      this.rCourseOfers = data.map(r=>{
        r.CourseOffer =  JSON.parse(r.NewValue);
        return r;
      });
    })


  }


  updateCourseOfer(valueForm: CourseOffer, isValid) {
    if (isValid) {
      this.sharedService.swalLoading("Actualizando Datos")
      this._directivasService.updateCourseOfer(valueForm).pipe(take(1)).subscribe(data => {
        this.sharedService.sleep(1500).then(() => {
          this.sharedService.swalSuccess("Datos Actualizados", "")
        })
      }, err => {
        this.sharedService.sleep(1500).then(() => {
          this.sharedService.swalError("Error al actualizar Actualizados", "Intenta nuevamente o contactate con el administrador")
        })
      })
    }
    else {
      this.sharedService.swalError("Error al actualizar Actualizados", "Todos los datos del formulario son obligatorios")

    }
  }

  eventDetailsComponent(event) {
    switch (event.typeEvent) {
      case 'closeWindow':
        this.showDetails = false;
        break
      default:
        break
    }
  }


}
