import { ColDef } from "ag-grid-community";
import { DateChangeComponent } from "../shared/tableList/cardId/date-change/date-change.component";
import { DateExpiryComponent } from "../shared/tableList/cardId/date-expiry/date-expiry.component";
import { DateIssueComponent } from "../shared/tableList/cardId/date-issue/date-issue.component";
import { NumberCodeComponent } from "../shared/tableList/cardId/number-code/number-code.component";
import { StateComponent } from "../shared/tableList/cardId/state/state.component";
import { ActaNumberComponent } from "../shared/tableList/userCourse/acta-number/acta-number.component";
import { BookNumberComponent } from "../shared/tableList/userCourse/book-number/book-number.component";
import { DiplomaNumberComponent } from "../shared/tableList/userCourse/diploma-number/diploma-number.component";
import { FolioComponent } from "../shared/tableList/userCourse/folio/folio.component";
import { environment } from "src/environments/environment";

export let columnsSelected = {
  personalData: [
    {
      id: 0,
      name: 'ID',
      value: false,
      keyValue: 'id'
    },
    {
      id: 1,
      name: 'Cédula Militar',
      value: false,
      keyValue: 'CedulaMilitar'
    },
    {
      id: 2,
      name: 'Documento',
      value: false,
      keyValue: 'Documento'
    },
    {
      id: 3,
      name: 'Fecha de expedición',
      value: false,
      keyValue: 'FechaDeExpedicionDoc'
    },
    {
      id: 4,
      name: 'Lugar de expedición',
      value: false,
      keyValue: 'LugarDeExpedicionDoc'
    },
    {
      id: 5,
      name: 'Tipo de Documento',
      value: false,
      keyValue: 'TipoDocumento'
    },
    {
      id: 6,
      name: 'Fecha de nacimiento',
      value: false,
      keyValue: 'FechaDeNacimiento'
    },
    {
      id: 7,
      name: 'Lugar de Nacimiento',
      value: false,
      keyValue: 'LugarDeNacimiento'
    },
    {
      id: 8,
      name: 'Nombre Contacto de emergencia',
      value: false,
      keyValue: 'ContactoDeEmergenciaNombre'
    },
    {
      id: 9,
      name: 'Telefono Contacto de emergencia',
      value: false,
      keyValue: 'ContactoDeEmergenciaNombre'
    },
    {
      id: 10,
      name: 'E-mail personal',
      value: false,
      keyValue: 'EmailPersonal'
    },
    {
      id: 11,
      name: 'Nombre',
      value: false,
      keyValue: 'Nombre'
    },
    {
      id: 12,
      name: 'Apellido',
      value: false,
      keyValue: 'Apellido'
    },
    {
      id: 13,
      name: 'Teléfono',
      value: false,
      keyValue: 'Telefono'
    },
    {
      id: 14,
      name: 'Celular',
      value: false,
      keyValue: 'TelefonoCel'
    },
    {
      id: 15,
      name: 'Último acceso a la plataforma',
      value: false,
      keyValue: 'UltimoAcceso'
    },
    {
      id: 16,
      name: 'Dirección',
      value: false,
      keyValue: 'Ubicacion'
    },
    {
      id: 17,
      name: 'Fuerza',
      value: false,
      keyValue: 'Fuerza'
    },
    {
      id: 18,
      name: 'Grado',
      value: false,
      keyValue: 'Grado'
    },
    {
      id: 19,
      name: 'Ver documento',
      value: false,
    },
    {
      id: 20,
      name: 'Es Militar',
      value: false,
      keyValue: `esMilitar`
    },
  ],
  academicData: [
    {
      id: 0,
      name: 'E-mail',
      value: false,
      keyValue: 'EmailCEDOC'
    },
    {
      id: 1,
      name: 'Año de graduación del último estudio realizado',
      value: false,
      keyValue: 'UltimoEstudio'
    },
    {
      id: 2,
      name: 'Cargo que se encuentre desempeñando',
      value: false,
      keyValue: 'CargoActual'
    },
    {
      id: 3,
      name: 'Ciudad en que se encuentra ubicada la empresa',
      value: false,
      keyValue: 'CiudadEmpresa'
    },
    {
      id: 4,
      name: 'Correo electrónico de la empresa',
      value: false,
      keyValue: 'EmailEmpresa'
    },
    {
      id: 5,
      name: 'Ocupación Actual',
      value: false,
      keyValue: 'SeEncuentraLaborando'
    },
    {
      id: 6,
      name: 'Fecha graduación',
      value: false,
      keyValue: 'FechaDeGraduacion'
    },
    {
      id: 7,
      name: 'Empresa dónde trabaja',
      value: false,
      keyValue: 'EmpresaDondeTrabaja'
    },
    {
      id: 8,
      name: 'Sector empresa',
      value: false,
      keyValue: 'SectorEmpresa'
    },
    {
      id: 9,
      name: 'Teléfono empresa',
      value: false,
      keyValue: 'TelefonoEmpresa'
    },
    {
      id: 10,
      name: 'Rango Salarial',
      value: false,
      keyValue: 'RangoSalarial'
    },
    {
      id: 11,
      name: 'Nivel Escolaridad',
      value: false,
      keyValue: 'NivelEscolaridad'
    },
    {
      id: 12,
      name: 'Perfil profesional',
      value: false,
      keyValue: 'PerfilProfesional'
    },
    {
      id: 13,
      name: 'Ver Icfes',
      value: false,
    },
    {
      id: 14,
      name: 'Ver Saber Pro',
      value: false,
    },
    {
      id: 15,
      name: 'Habilidades',
      value: false,
      keyValue: 'Skills'
    },
    {
      id: 16,
      name: 'Número Carnet',
      value: false,
      keyValue: 'CarnetNum'
    },
    {
      id: 17,
      name: 'Fecha Emisión Carnet',
      value: false,
      keyValue: 'CarnetFecEmision'
    },
    {
      id: 18,
      name: 'Fecha Vencimiento Carnet',
      value: false,
      keyValue: 'CarnetFecVencimiento'
    },
    {
      id: 19,
      name: 'Fecha de cambio Carnet',
      value: false,
      keyValue: 'CarnetFecCambio'
    },
    {
      id: 20,
      name: 'Tipo Carnet',
      value: false,
      keyValue: 'CarnetTipo'
    },
    {
      id: 21,
      name: 'Procesos de carnetización',
      value: false,
    },
    {
      id: 22,
      name: 'Ver Hoja de Vida',
      value: false
    },
    {
      id: 23,
      name: 'Aceptado',
      value: false,
      keyValue: 'IsAcepted'
    },
    {
      id: 24,
      name: 'Recibo Pago',
      value: false,
      keyValue: 'IsPaied'
    },
    {
      id: 25,
      name: 'Ver Recibo',
      value: false,
    },
    {
      id: 26,
      name: 'Promedio',
      value: false,
      keyValue: 'GPA'
    },
    {
      id: 27,
      name: 'No. de libro',
      value: false,
      keyValue: 'nLibro'
    },
    {
      id: 28,
      name: 'No. diploma',
      value: false,
      keyValue: 'nDiploma'
    },
    {
      id: 29,
      name: 'No. acta',
      value: false,
      keyValue: 'nActa'
    },
    {
      id: 30,
      name: 'Folio',
      value: false,
      keyValue: 'nfolio'
    },
    {
      id: 31,
      name: 'Oferta',
      value: false,
      keyValue: 'couseOfferName'
    },
  ],
  clasificationData: [
    {
      id: 0,
      name: 'Sexo biologico',
      value: false,
      keyValue: 'SexoBiologico'
    },
    {
      id: 1,
      name: 'Estado civil',
      value: false,
      keyValue: 'EstadoCivil'
    },
    {
      id: 2,
      name: 'Grupo Etnico',
      value: false,
      keyValue: 'GrupoEtnico'
    },
    {
      id: 3,
      name: 'Comunidad indigena adscrita familiar',
      value: false,
      keyValue: 'ComunidadIndigenaAdscrita'
    },
    {
      id: 4,
      name: 'Grupo étnico',
      value: false,
      keyValue: 'ComunidadAdscrita'
    },
    {
      id: 5,
      name: 'Discapacidad',
      value: false,
      keyValue: 'Discapacidad'
    },
    {
      id: 6,
      name: 'Capacidad excepcional',
      value: false,
      keyValue: 'PoseeCapacidadExcepcional'
    },
    {
      id: 7,
      name: 'Sisben',
      value: false,
      keyValue: 'Sisben'
    },
    {
      id: 8,
      name: 'Estrato',
      value: false,
      keyValue: 'EstratoSocial'
    },
    {
      id: 9,
      name: 'Zona residencia social',
      value: false,
      keyValue: 'zonaResidencia'
    },
    {
      id: 10,
      name: 'RH social',
      value: false,
      keyValue: 'type_rh'
    },
    {
      id: 11,
      name: 'EPS',
      value: false,
      keyValue: 'EPS'
    },
    {
      id: 12,
      name: 'Caja Compansación',
      value:false,
      keyValue: 'CajaCompensacion'
    },
    {
      id: 13,
      name: 'Edad',
      value: false,
      keyValue: 'Edad'
    },
    {
      id: 14,
      name: 'Reconocimiento especial',
      value: false,
      keyValue: 'ReconocimientoEspecial'
    },
    {
      id: 15,
      name: 'Proyectos de impacto Social',
      value: false,
      keyValue: 'ProyectoImpactoSocial'
    },
    {
      id: 16,
      name: 'Ponencias en el exterior',
      value: false,
      keyValue: 'PonenciasExterior'
    },
    {
      id: 17,
      name: 'Grupo de investigación',
      value: false,
      keyValue: 'GrupoDeInvestigacion'
    },
    {
      id: 18,
      name: 'Factor Hr',
      value: false,
      keyValue: 'FactorHr'
    },
    {
      id:19,
      name: 'Hijo de miembro de fuerza publica',
      value: false,
      keyValue: 'HijoDeMiembroFuerzaPublica'
    },
    {
      id: 20,
      name: 'Número de veces que presento proceso',
      value: false,
      keyValue: 'NumeroDeVecesPresentoProceso'
    },
    {
      id: 21,
      name: 'Tattoo',
      value: false,
      keyValue: 'Tattoo'
    },
    {
      id: 22,
      name: 'Cantidad tattoo ubicación y descripción',
      value: false,
      keyValue: 'CantidadTattooUbicacionYDescripcion'
    },
    {
      id: 23,
      name: 'Nombre de la institución educativa secundaria',
      value: false,
      keyValue: 'NombreDeLaInstitucionEducativaSecundaria'
    },
    {
      id: 24,
      name: 'Día laboral',
      value: false,
      keyValue: 'DiaLaboral'
    },
    {
      id: 25,
      name: 'Horario',
      value: false,
      keyValue: 'Horario'
    },
    {
      id: 26,
      name: 'Naturaleza pública o privada',
      value: false,
      keyValue: 'NaturalezaPublicaOPrivada'
    },
    {
      id: 27,
      name: 'Metodología presencial o a distancia',
      value: false,
      keyValue: 'MetodologiaPresencialODistancia'
    },
    {
      id: 28,
      name: 'Tipo de escuela secundaria',
      value: false,
      keyValue: 'TipoDeEscuelaSecundaria'
    },
    {
      id: 29,
      name: 'Fecha aspirante titulo bachiller',
      value: false,
      keyValue: 'FechaAspiranteTituloBachiller'
    },
    {
      id: 30,
      name: 'Estudios complementarios',
      value: false,
      keyValue: 'EstudiosComplementarios'
    },
    {
      id: 31,
      name: 'Lateralidad',
      value: false,
      keyValue: 'Lateralidad'
    },
    {
      id: 32,
      name: 'Número de zapato',
      value: false,
      keyValue: 'NumeroDeZapato'
    },
    {
      id: 33,
      name: 'Talla de gorra',
      value: false,
      keyValue: 'TallaDeGorra'
    },
    {
      id: 34,
      name: 'Talla de camisa',
      value: false,
      keyValue: 'TallaDeCamisa'
    },
    {
      id: 35,
      name: 'Talla de pantalón',
      value: false,
      keyValue: 'TallaDePantalon'
    },
    {
      id: 36,
      name: 'Talla de camuflaje',
      value: false,
      keyValue: 'TallaDeCamuflaje'
    },
    {
      id: 37,
      name: 'Nombre de la madre',
      value: false,
      keyValue: 'NombreDeLaMadre'
    },
    {
      id: 38,
      name: 'Tarjeta de la madre',
      value: false,
      keyValue: 'TarjetaDeLaMadre'
    },
    {
      id: 39,
      name: 'Madre viva o finada',
      value: false,
      keyValue: 'MadreVivaOFinada'
    },
    {
      id: 40,
      name: 'Teléfono de la madre',
      value: false,
      keyValue: 'TelefonoDeLaMadre'
    },
    {
      id: 41,
      name: 'Ocupación de la madre',
      value: false,
      keyValue: 'OcupacionDeLaMadre'
    },
    {
      id: 42,
      name: 'Correo de la madre',
      value: false,
      keyValue: 'CorreoDeLaMadre'
    },
    {
      id: 43,
      name: 'Nombre del padre',
      value: false,
      keyValue: 'NombreDelPadre'
    },
    {
      id: 44,
      name: 'Certificado del padre',
      value: false,
      keyValue: 'CertificadoDelPadre'
    },
    {
      id: 45,
      name: 'Padre vivo o finado',
      value: false,
      keyValue: 'PadreVivoOFinado'
    },
    {
      id: 46,
      name: 'Teléfono del padre',
      value: false,
      keyValue: 'TelefonoDelPadre'
    },
    {
      id: 47,
      name: 'Ocupación del padre',
      value: false,
      keyValue: 'OcupacionDelPadre'
    },
    {
      id: 48,
      name: 'Correo del padre',
      value: false,
      keyValue: 'CorreoDelPadre'
    },
    {
      id: 49,
      name: 'Estado civil de los padres',
      value: false,
      keyValue: 'EstadoCivilDeLosPadres'
    },
    {
      id: 50,
      name: 'Víctima del conflicto',
      value: false,
      keyValue: 'VictimaDelConflicto'
    },
    {
      id: 51,
      name: 'Municipio donde fue víctima del conflicto',
      value: false,
      keyValue: 'MunicipioDondeFueVictimaDelConflicto'
    },
    {
      id: 52,
      name: 'Número de personas en el grupo familiar',
      value: false,
      keyValue: 'NumeroDePersonasEnElGrupoFamiliar'
    },
    {
      id: 53,
      name: 'Contribuyendo económicamente',
      value: false,
      keyValue: 'ContribuyendoEconomicamente'
    },
    {
      id: 54,
      name: 'Ingresos totales',
      value: false,
      keyValue: 'IngresosTotales'
    },
    {
      id: 55,
      name: 'Tipo de vivienda',
      value: false,
      keyValue: 'TipoDeVivienda'
    },
    {
      id: 56,
      name: 'Barrio',
      value: false,
      keyValue: 'Barrio'
    },
    {
      id: 57,
      name: 'No. Curso',
      value: false,
      keyValue: 'CourseNumber'
    },
    {
      id: 58,
      name: 'Tipo de Curso',
      value: false,
      keyValue: 'CourseType'
    },
    {
      id: 59,
      name: 'Fecha de Registro',
      value: false,
      keyValue: 'RegistrationDate'
    },
    {
      id: 60,
      name: 'Motivo de Selección de Programa',
      value: false,
      keyValue: 'ProgramSelectionReason'
    },
    {
      id: 61,
      name: 'Estado Actual',
      value: false,
      keyValue: 'CurrentStatus'
    },
    {
      id: 62,
      name: 'Número de Identificación Militar',
      value: false,
      keyValue: 'MilitaryIDNumber'
    },
    {
      id: 63,
      name: 'Servicio Militar',
      value: false,
      keyValue: 'MilitaryService'
    },
    {
      id: 64,
      name: 'Tipo de Fuerza del Soldado',
      value: false,
      keyValue: 'SoldierForceType'
    },
    {
      id: 65,
      name: 'Unidad de Servicio Militar',
      value: false,
      keyValue: 'UnitMilitaryService'
    },
    {
      id: 66,
      name: 'Distrito Militar',
      value: false,
      keyValue: 'MilitaryDistrict'
    },
    {
      id: 67,
      name: 'Contingente',
      value: false,
      keyValue: 'Contingent'
    },
    {
      id: 68,
      name: 'Año del Contingente',
      value: false,
      keyValue: 'ContingentYear'
    },
    {
      id: 69,
      name: 'Interés en Deportes',
      value: false,
      keyValue: 'SportsInterest'
    },
    {
      id: 70,
      name: 'Desplazado',
      value: false,
      keyValue: 'Displaced'
    },
    {
      id: 71,
      name: 'Municipio de Desplazamiento',
      value: false,
      keyValue: 'DisplacingMunicipality'
    },
    {
      id: 72,
      name: 'Especialidad de Educación Secundaria',
      value: false,
      keyValue: 'SecondaryEducationSpecialty'
    },
    {
      id: 73,
      name: 'Código de la Institución',
      value: false,
      keyValue: 'InstitutionCode'
    },
    {
      id: 74,
      name: 'Carácter de la Institución',
      value: false,
      keyValue: 'InstitutionCharacter'
    },
    {
      id: 75,
      name: 'Metodología',
      value: false,
      keyValue: 'Methodology'
    },
    {
      id: 76,
      name: 'Grado Obtenido',
      value: false,
      keyValue: 'ObtainedDegree'
    },
    {
      id: 77,
      name: 'Número de Registro de Graduación',
      value: false,
      keyValue: 'GraduationRecordNo'
    },
    {
      id: 78,
      name: 'Número de Folio de Diploma',
      value: false,
      keyValue: 'DiplomaFolioNo'
    },
    {
      id: 79,
      name: 'Tipo de Formación',
      value: false,
      keyValue: 'TrainingType'
    },
    {
      id: 80,
      name: 'Código ICFES',
      value: false,
      keyValue: 'ICFESCode'
    },
    {
      id: 81,
      name: 'Puntaje ICFES',
      value: false,
      keyValue: 'ICFESScore'
    },
    {
      id: 82,
      name: 'Fecha de Presentación del ICFES',
      value: false,
      keyValue: 'ICFESPresentationDate'
    },
    {
      id: 83,
      name: 'Año de Reporte de Pruebas Saber 11 del ICFES',
      value: false,
      keyValue: 'YearICFESPruebasSaberReport'
    },
    {
      id: 84,
      name: 'Resultado en el Área de Inglés',
      value: false,
      keyValue: 'EnglishAreaResult'
    },
    {
      id: 85,
      name: 'Resultado en Componente Flexible',
      value: false,
      keyValue: 'FlexibleComponentResult'
    },
    {
      id: 86,
      name: 'Ingreso Familiar',
      value: false,
      keyValue: 'FamilyIncome'
    },
    {
      id: 87,
      name: 'Número de Hermanos',
      value: false,
      keyValue: 'NumberSiblings'
    },
    {
      id: 88,
      name: 'Hermanos con Educación Superior',
      value: false,
      keyValue: 'SiblingsHigherEducation'
    },
    {
      id: 89,
      name: 'Posición entre los Hermanos',
      value: false,
      keyValue: 'SiblingPosition'
    },
    {
      id: 90,
      name: 'Familia en las Fuerzas Armadas',
      value: false,
      keyValue: 'ArmedForcesFamily'
    },
    {
      id: 91,
      name: 'Nombre de Familia en las Fuerzas Armadas',
      value: false,
      keyValue: 'ArmedForcesFamilyName'
    },
    {
      id: 92,
      name: 'Dirección del Padre',
      value: false,
      keyValue: 'FatherAddress'
    },
    {
      id: 93,
      name: 'Dirección de la Madre',
      value: false,
      keyValue: 'MotherAddress'
    },
    {
      id: 94,
      name: 'Nombre del Acudiente',
      value: false,
      keyValue: 'GuardianName'
    },
    {
      id: 95,
      name: 'Número de Identificación del Acudiente',
      value: false,
      keyValue: 'GuardianIDNumber'
    },
    {
      id: 96,
      name: 'Teléfono del Acudiente',
      value: false,
      keyValue: 'GuardianPhone'
    },
    {
      id: 97,
      name: 'Dirección del Acudiente',
      value: false,
      keyValue: 'GuardianAddress'
    },
    {
      id: 98,
      name: 'Segundo Idioma',
      value: false,
      keyValue: 'SecondLanguage'
    },
    {
      id: 99,
      name: 'Nombre del Segundo Idioma',
      value: false,
      keyValue: 'SecondLanguageName'
    },
    {
      id: 100,
      name: 'Método de Transporte',
      value: false,
      keyValue: 'TransportationMethod'
    },
    {
      id: 101,
      name: 'Subgrupo Sisbén',
      value: false,
      keyValue: 'SisbenSubgroup'
    },
    {
      id: 102,
      name: 'Lugar de Expedición del Documento',
      value: false,
      keyValue: 'DocumentIssuingPlace'
    },
    {
      id: 103,
      name: 'Número de Hijos',
      value: false,
      keyValue: 'NumberChildren'
    },
    {
      id: 104,
      name: 'ARS',
      value: false,
      keyValue: 'HealthInsurance'
    },
    {
      id: 105,
      name: 'Aseguradora',
      value: false,
      keyValue: 'InsuranceCompany'
    },
    {
      id: 106,
      name: 'Libreta Militar',
      value: false,
      keyValue: 'MilitaryID'
    },
    {
      id: 107,
      name: 'Fecha de Expedición del Documento',
      value: false,
      keyValue: 'DocumentIssuanceDate'
    },
    {
      id: 108,
      name: 'Ley para Veteranos',
      value: false,
      keyValue: 'VeteransLaw'
    },
    {
      id: 109,
      name: 'Zona de reclutamiento',
      value: false,
      keyValue: 'recruitingZone'
    },
  ],
  carnetData: [
    {
      id: 0,
      name: 'Asignar tipo carnet',
      value: 'false'
    },
    {
      id: 1,
      name: 'Asignar código',
      value: false
    },
    {
      id: 2,
      name: 'Asignar Fecha emisión carnet',
      value: false
    },
    {
      id: 3,
      name: 'Asignar Fecha expiración carnet',
      value: false
    },
    {
      id: 4,
      name: 'Asignar Fecha cambio carnet',
      value: false
    },
    {
      id: 5,
      name: 'Asignar No. de libro',
      value: false
    },
    {
      id: 6,
      name: 'Asignar No. diploma',
      value: false
    },
    {
      id: 7,
      name: 'Asignar No. acta',
      value: false
    },
    {
      id: 8,
      name: 'Asignar Folio',
      value: false
    }
  ]
}
export let columnsSkeleton = {
  firstData: [],
  personalData: [],
  academicData: [],
  clasificationData: [],
  carnetData: []
}

export const columnDefs__personalData__TEST: ColDef[] = [
  { /** 0 */
    headerName: 'ID',
    valueGetter: params => {
      return params.data.id
    }
  },
  { /** 21 */
  headerName: 'Asignar tipo carnet',
    minWidth: 175,
    cellRenderer: StateComponent
  },
  { /** 21 */
    headerName: 'Asignar código',
    minWidth: 175,
    cellRenderer: NumberCodeComponent
  },
  { /** 21 */
    headerName: 'Asignar Fecha emisión carnet',
    minWidth: 250,
    cellRenderer: DateIssueComponent
  },
  { /** 21 */
    headerName: 'Asignar Fecha expiración carnet',
    minWidth: 250,
    cellRenderer: DateExpiryComponent
  },
  { /** 21 */
    headerName: 'Asignar Fecha cambio carnet',
    minWidth: 250,
    cellRenderer: DateChangeComponent
  }
]
export const columnDefs__carnetData: ColDef[] = [
  { /** 0 */
    headerName: 'Asignar tipo carnet',
    minWidth: 175,
    cellRenderer: StateComponent
  },
  { /** 1 */
    headerName: 'Asignar código',
    minWidth: 175,
    cellRenderer: NumberCodeComponent
  },
  { /** 2 */
    headerName: 'Asignar Fecha emisión carnet',
    minWidth: 250,
    cellRenderer: DateIssueComponent
  },
  { /** 3 */
    headerName: 'Asignar Fecha expiración carnet',
    minWidth: 250,
    cellRenderer: DateExpiryComponent
  },
  { /** 4 */
    headerName: 'Asignar Fecha cambio carnet',
    minWidth: 250,
    cellRenderer: DateChangeComponent
  },
  { /** 5 */
    headerName: 'Asignar No. de libro',
    minWidth: 250,
    cellRenderer: BookNumberComponent
  },
  { /** 6 */
    headerName: 'Asignar No. diploma',
    minWidth: 250,
    cellRenderer: DiplomaNumberComponent
  },
  { /** 7 */
    headerName: 'Asignar No. acta',
    minWidth: 250,
    cellRenderer: ActaNumberComponent
  },
  { /** 8 */
    headerName: 'Asignar Folio',
    minWidth: 250,
    cellRenderer: FolioComponent
  }
];
export const columnDefs__personalData: ColDef[] = [
  { /** 0 */
    headerName: 'ID',
    valueGetter: params => {
      return params.data.id
    }
  },
  { /** 1 */
    headerName: 'Cédula Militar',
    valueGetter: params => {
      return params.data.CedulaMilitar
    }
  },
  { /** 2 */
    headerName: 'Documento',
    valueGetter: params => {
      return params.data.Documento
    }
  },
  { /** 3 */
    headerName: 'Fecha de expedición',
    valueGetter: params => {
      return params.data.FechaDeExpedicionDoc
    }
  },
  { /** 4 */
    headerName: 'Lugar de expedición',
    valueGetter: params => {
      return params.data.LugarDeExpedicionDoc
    }
  },
  { /** 5 */
    headerName: 'Tipo de Documento',
    valueGetter: params => {
      return params.data.TipoDocumento
    }
  },
  { /** 6 */
    headerName: 'Fecha de nacimiento',
    valueGetter: params => {
      return params.data.FechaDeNacimiento
    }
  },
  { /** 7 */
    headerName: 'Lugar de nacimiento',
    valueGetter: params => {
      return params.data.LugarDeNacimiento
    }
  },
  { /** 8 */
    headerName: 'Nombre Contacto de emergencia',
    valueGetter: params => {
      return params.data.ContactoDeEmergenciaNombre
    }
  },
  { /** 9 */
    headerName: 'Telefono Contacto de emergencia',
    valueGetter: params => {
      return params.data.ContactoDeEmergenciaNombre
    }
  },
  { /** 10 */
    headerName: 'E-mail personal',
    valueGetter: params => {
      return params.data.EmailPersonal
    }
  },
  { /** 11 */
    headerName: 'Nombre',
    valueGetter: params => {
      return params.data.Nombre
    }
  },
  { /** 12 */
    headerName: 'Apellido',
    valueGetter: params => {
      return params.data.Apellido
    }
  },
  { /** 13 */
    headerName: 'Teléfono',
    valueGetter: params => {
      return params.data.Telefono
    }
  },
  { /** 14 */
    headerName: 'Celular',
    valueGetter: params => {
      return params.data.TelefonoCel
    }
  },
  { /** 15 */
    headerName: 'Último acceso a la plataforma',
    valueGetter: params => {
      return params.data.UltimoAcceso
    }
  },
  { /** 16 */
    headerName: 'Dirección',
    valueGetter: params => {
      return params.data.Ubicacion
    }
  },
  { /** 17 */
    headerName: 'Fuerza',
    valueGetter: params => {
      return params.data.Fuerza
    }
  },
  { /** 18 */
    headerName: 'Grado',
    valueGetter: params => {
      return params.data.Grado
    }
  },
  { /** 19 */
    headerName: 'Ver documento',
    cellRenderer: params => {
      if (params.data.DocumentoLink != null)
        return `<a href="${environment.fileBaseUrl}${params.data.DocumentoLink}" target="_blank">Ver</a>`;
      else 
        return `<span></span>`
    }
  },
  { /** 20 */
    headerName: 'Es Militar',
    valueGetter: params => {
      return params.data.esMilitar ? 'Si' : 'No';
    }
  }
];
export const columnDefs__academicData: ColDef[] = [
  { /** 0 */
    headerName: 'E-mail',
    valueGetter: params => {
      return params.data.EmailCEDOC
    }
  },
  { /** 1 */
    headerName: 'Año de graduación del último estudio realizado',
    valueGetter: params => {
      return params.data.UltimoEstudio
    }
  },
  { /** 2 */
    headerName: 'Cargo que se encuentre desempeñando',
    valueGetter: params => {
      return params.data.CargoActual
    }
  },
  { /** 3 */
    headerName: 'Ciudad en que se encuentra ubicada la empresa',
    valueGetter: params => {
      return params.data.CiudadEmpresa
    }
  },
  { /** 4 */
    headerName: 'Correo electrónico de la empresa',
    valueGetter: params => {
      return params.data.EmailEmpresa
    }
  },
  { /** 5 */
    headerName: 'Ocupación Actual',
    valueGetter: params => {
      return params.data.SeEncuentraLaborando
    }
  },
  { /** 6 */
    headerName: 'Fecha graduación',
    valueGetter: params => {
      return params.data.FechaDeGraduacion
    }
  },
  { /** 7 */
    headerName: 'Empresa dónde trabaja',
    valueGetter: params => {
      return params.data.EmpresaDondeTrabaja
    }
  },
  { /** 8 */
    headerName: 'Sector Empresa',
    valueGetter: params => {
      return params.data.SectorEmpresa
    }
  },
  { /** 9 */
    headerName: 'Teléfono empresa',
    valueGetter: params => {
      return params.data.TelefonoEmpresa
    }
  },
  { /** 10 */
    headerName: 'Rango Salarial',
    valueGetter: params => {
      return params.data.RangoSalarial
    }
  },
  { /** 11 */
    headerName: 'Nivel Escolaridad',
    valueGetter: params => {
      return params.data.NivelEscolaridad
    }
  },
  { /** 12 */
    headerName: 'Perfil profesional',
    valueGetter: params => {
      return params.data.PerfilProfesional
    }
  },
  { /** 13 */
    headerName: 'Ver Icfes',
    cellRenderer: params => {
      if (params.data.IcfesURL != null)
        return `<a href="${environment.fileBaseUrl}${params.data.IcfesURL}" target="_blank">Ver</a>`;
      else 
        return `<span></span>`
    }
  },
  { /** 14 */
    headerName: 'Ver Saber Pro',
    cellRenderer: params => {
      if (params.data.SaberProUrl != null)
        return `<a href="${environment.fileBaseUrl}${params.data.SaberProUrl}" target="_blank">Ver</a>`;
      else 
        return `<span></span>`
    }
  },
  { /** 15 */
    headerName: 'Habilidades',
    valueGetter: params => {
      return params.data.Skills
    }
  },
  { /** 16 */
    headerName: 'Número Carnet',
    valueGetter: params => {
      return params.data.CarnetNum
    }
  },
  { /** 17 */
    headerName: 'Fecha Emisión Carnet',
    valueGetter: params => {
      return params.data.CarnetFecEmision
    }
  },
  { /** 18 */
    headerName: 'Fecha Vencimiento Carnet',
    valueGetter: params => {
      return params.data.CarnetFecVencimiento
    }
  },
  { /** 19 */
    headerName: 'Fecha de cambio Carnet',
    valueGetter: params => {
      return params.data.CarnetFecCambio
    }
  },
  { /** 20 */
    headerName: 'Tipo Carnet',
    valueGetter: params => {
      return params.data.CarnetTipo
    }
  },
  { /** 21 */
    headerName: 'Procesos de carnetización',
    cellRenderer: params => {
      return `<a href="/request-admin/${params.data.Documento}" target="_blank">Ver Proceso</a>`;
    }
  },
  { /** 22 */
    headerName: 'Ver Hoja de Vida',
    cellRenderer: params => {
      return `<a href="/Cv-Public/${params.data.EmailCEDOC}" target="_blank">Ver</a>`;
    }
  },
  { /** 23 */
    headerName: 'Aceptado',
    valueGetter: params => {
      return params.data.IsAcepted ? 'Si' : 'No'
    }
  },
  { /** 24 */
    headerName: 'Pago',
    valueGetter: params => {
      return params.data.IsPaied ? 'Si' : 'No'
    }
  },
  { /** 25 */
    headerName: 'Ver Recibo',
    cellRenderer: params => {
      if (params.data.urlReciboDePago != null)
        return `<a href="${environment.fileBaseUrl}${params.data.urlReciboDePago}" target="_blank">Ver</a>`;
      else 
        return `<span></span>`
    }
  },
  { /** 26 */
    headerName: 'Promedio',
    valueGetter: params => {
      return params.data.GPA
    }
  },
  { /** 27 */
    headerName: 'No. de libro',
    valueGetter: params => {
      return params.data.nLibro
    }
  },
  { /** 28 */
    headerName: 'No. diploma',
    valueGetter: params => {
      return params.data.nDiploma
    }
  },
  { /** 29 */
    headerName: 'No. acta',
    valueGetter: params => {
      return params.data.nActa
    }
  },
  { /** 30 */
    headerName: 'Folio',
    valueGetter: params => {
      return params.data.nfolio
    }
  },
  { /** 31 */
    headerName: 'Oferta',
    valueGetter: params => {
      return params.data.couseOfferName
    }
  }
];
export const columnDefs__clasificationData: ColDef[] = [
  { /**  */
    headerName: 'Sexo biologico',
    valueGetter: params => {
      return params.data.SexoBiologico
    }
  },
  { /**  */
    headerName: 'Estado civil',
    valueGetter: params => {
      return params.data.EstadoCivil
    }
  },
  { /**  */
    headerName: 'Grupo Etnico',
    valueGetter: params => {
      return params.data.GrupoEtnico
    }
  },
  { /**  */
    headerName: 'Comunidad indigena adscrita',
    valueGetter: params => {
      return params.data.ComunidadIndigenaAdscrita
    }
  },
  { /**  */
    headerName: 'Grupo étnico',
    valueGetter: params => {
      return params.data.ComunidadAdscrita
    }
  },
  { /**  */
    headerName: 'Discapacidad',
    valueGetter: params => {
      return params.data.Discapacidad
    }
  },
  { /**  */
    headerName: 'Capacidad excepcional',
    valueGetter: params => {
      return params.data.PoseeCapacidadExcepcional
    }
  },
  { /**  */
    headerName: 'Sisben',
    valueGetter: params => {
      return params.data.Sisben
    }
  },
  { /**  */
    headerName: 'Estrato',
    valueGetter: params => {
      return params.data.EstratoSocial
    }
  },
  { /**  */
    headerName: 'Zona residencia',
    valueGetter: params => {
      return params.data.zonaResidencia
    }
  },
  { /**  */
    headerName: 'RH',
    valueGetter: params => {
      return params.data.type_rh
    }
  },
  { /**  */
    headerName: 'EPS',
    valueGetter: params => {
      return params.data.EPS
    }
  },
  { /**  */
    headerName: 'Caja Compansación',
    valueGetter: params => {
      return params.data.CajaCompensacion
    }
  },
  { /**  */
    headerName: 'Edad',
    valueGetter: params => {
      return params.data.Edad
    }
  },
  { /**  */
    headerName: 'Reconocimiento especial',
    valueGetter: params => {
      return params.data.ReconocimientoEspecial
    }
  },
  { /**  */
    headerName: 'Proyectos de impacto Social',
    valueGetter: params => {
      return params.data.ProyectoImpactoSocial
    }
  },
  { /**  */
    headerName: 'Ponencias en el exterior',
    valueGetter: params => {
      return params.data.PonenciasExterior
    }
  },
  { /**  */
    headerName: 'Grupo de investigación',
    valueGetter: params => {
      return params.data.GrupoDeInvestigacion
    }
  },
  { /**  */
    headerName: 'Factor Hr',
    valueGetter: params => {
      return params.data.FactorHr
    }
  },
  { /**  */
    headerName: 'Hijo de miembro fuerza pública',
    valueGetter: params => {
      return params.data.HijoDeMiembroFuerzaPublica
    }
  },
  { /**  */
    headerName: 'Numero de veces que presentó proceso',
    valueGetter: params => {
      return params.data.NumeroDeVecesPresentoProceso
    }
  },
  { /**  */
    headerName: 'Tattoo',
    valueGetter: params => {
      return params.data.Tattoo
    }
  },
  { /**  */
    headerName: 'Cantidad Tattoo ubicación y descripción',
    valueGetter: params => {
      return params.data.CantidadTattooUbicacionYDescripcion
    }
  },
  { /**  */
    headerName: 'Nombre de la institución educativa secundaria',
    valueGetter: params => {
      return params.data.NombreDeLaInstitucionEducativaSecundaria
    }
  },
  { /**  */
    headerName: 'Día laboral',
    valueGetter: params => {
      return params.data.DiaLaboral
    }
  },
  { /**  */
    headerName: 'Horario',
    valueGetter: params => {
      return params.data.Horario
    }
  },
  { /**  */
    headerName: 'Naturaleza pública o privada',
    valueGetter: params => {
      return params.data.NaturalezaPublicaOPrivada
    }
  },
  { /**  */
    headerName: 'Metodología presencial o a distancia',
    valueGetter: params => {
      return params.data.MetodologiaPresencialODistancia
    }
  },
  { /**  */
    headerName: 'Tipo de escuela secundaria',
    valueGetter: params => {
      return params.data.TipoDeEscuelaSecundaria
    }
  },
  { /**  */
    headerName: 'Fecha aspirante título bachiller',
    valueGetter: params => {
      return params.data.FechaAspiranteTituloBachiller
    }
  },
  { /**  */
    headerName: 'Estudios complementarios',
    valueGetter: params => {
      return params.data.EstudiosComplementarios
    }
  },
  { /**  */
    headerName: 'Lateralidad',
    valueGetter: params => {
      return params.data.Lateralidad
    }
  },
  { /**  */
    headerName: 'Numero de zapato',
    valueGetter: params => {
      return params.data.NumeroDeZapato
    }
  },
  { /**  */
    headerName: 'Talla de gorra',
    valueGetter: params => {
      return params.data.TallaDeGorra
    }
  },
  { /**  */
    headerName: 'Talla de camisa',
    valueGetter: params => {
      return params.data.TallaDeCamisa
    }
  },
  { /**  */
    headerName: 'Talla de pantalón',
    valueGetter: params => {
      return params.data.TallaDePantalon
    }
  },
  { /**  */
    headerName: 'Talla de camuflaje',
    valueGetter: params => {
      return params.data.TallaDeCamuflaje
    }
  },
  { /**  */
    headerName: 'Nombre de la madre',
    valueGetter: params => {
      return params.data.NombreDeLaMadre
    }
  },
  { /**  */
    headerName: 'Tarjeta de la madre',
    valueGetter: params => {
      return params.data.TarjetaDeLaMadre
    }
  },
  { /**  */
    headerName: 'Madre viva o finada',
    valueGetter: params => {
      return params.data.MadreVivaOFinada
    }
  },
  { /**  */
    headerName: 'Teléfono de la madre',
    valueGetter: params => {
      return params.data.TelefonoDeLaMadre
    }
  },
  { /**  */
    headerName: 'Ocupación de la madre',
    valueGetter: params => {
      return params.data.OcupacionDeLaMadre
    }
  },
  { /**  */
    headerName: 'Correo de la madre',
    valueGetter: params => {
      return params.data.CorreoDeLaMadre
    }
  },
  { /**  */
    headerName: 'Nombre del padre',
    valueGetter: params => {
      return params.data.NombreDelPadre
    }
  },
  { /**  */
    headerName: 'Certificado del padre',
    valueGetter: params => {
      return params.data.CertificadoDelPadre
    }
  },
  { /**  */
    headerName: 'Padre vivo o finado',
    valueGetter: params => {
      return params.data.PadreVivoOFinado
    }
  },
  { /**  */
    headerName: 'Teléfono del padre',
    valueGetter: params => {
      return params.data.TelefonoDelPadre
    }
  },
  { /**  */
    headerName: 'Ocupación del padre',
    valueGetter: params => {
      return params.data.OcupacionDelPadre
    }
  },
  { /**  */
    headerName: 'Correo del padre',
    valueGetter: params => {
      return params.data.CorreoDelPadre
    }
  },
  { /**  */
    headerName: 'Estado civil de los padres',
    valueGetter: params => {
      return params.data.EstadoCivilDeLosPadres
    }
  },
  { /**  */
    headerName: 'Víctima del conflicto',
    valueGetter: params => {
      return params.data.VictimaDelConflicto
    }
  },
  { /**  */
    headerName: 'Municipio donde fue víctima del conflicto',
    valueGetter: params => {
      return params.data.MunicipioDondeFueVictimaDelConflicto
    }
  },
  { /**  */
    headerName: 'Número de personas en el grupo familiar',
    valueGetter: params => {
      return params.data.NumeroDePersonasEnElGrupoFamiliar
    }
  },
  { /**  */
    headerName: 'Contribuyendo económicamente',
    valueGetter: params => {
      return params.data.ContribuyendoEconomicamente
    }
  },
  { /**  */
    headerName: 'Ingresos totales',
    valueGetter: params => {
      return params.data.IngresosTotales
    }
  },
  { /**  */
    headerName: 'Tipo de vivienda',
    valueGetter: params => {
      return params.data.TipoDeVivienda
    }
  },
  { /**  */
    headerName: 'Barrio',
    valueGetter: params => {
      return params.data.Barrio
    }
  },
  { /**  */
    headerName: 'No. Curso',
    valueGetter: params => {
      return params.data.CourseNumber
    }
  },
  { /**  */
    headerName: 'Tipo de Curso',
    valueGetter: params => {
      return params.data.CourseType
    }
  },
  { /**  */
    headerName: 'Fecha de Registro',
    valueGetter: params => {
      return params.data.RegistrationDate
    }
  },
  { /**  */
    headerName: 'Motivo de Selección de Programa',
    valueGetter: params => {
      return params.data.ProgramSelectionReason
    }
  },
  { /**  */
    headerName: 'Estado Actual',
    valueGetter: params => {
      return params.data.CurrentStatus
    }
  },
  { /**  */
    headerName: 'Número de Identificación Militar',
    valueGetter: params => {
      return params.data.MilitaryIDNumber
    }
  },
  { /**  */
    headerName: 'Servicio Militar',
    valueGetter: params => {
      return params.data.MilitaryService
    }
  },
  { /**  */
    headerName: 'Tipo de Fuerza del Soldado',
    valueGetter: params => {
      return params.data.SoldierForceType
    }
  },
  { /**  */
    headerName: 'Unidad de Servicio Militar',
    valueGetter: params => {
      return params.data.UnitMilitaryService
    }
  },
  { /**  */
    headerName: 'Distrito Militar',
    valueGetter: params => {
      return params.data.MilitaryDistrict
    }
  },
  { /**  */
    headerName: 'Contingente',
    valueGetter: params => {
      return params.data.Contingent
    }
  },
  { /**  */
    headerName: 'Año del Contingente',
    valueGetter: params => {
      return params.data.ContingentYear
    }
  },
  { /**  */
    headerName: 'Interés en Deportes',
    valueGetter: params => {
      return params.data.SportsInterest
    }
  },
  { /**  */
    headerName: 'Desplazado',
    valueGetter: params => {
      return params.data.Displaced
    }
  },
  { /**  */
    headerName: 'Municipio de Desplazamiento',
    valueGetter: params => {
      return params.data.DisplacingMunicipality
    }
  },
  { /**  */
    headerName: 'Especialidad de Educación Secundaria',
    valueGetter: params => {
      return params.data.SecondaryEducationSpecialty
    }
  },
  { /**  */
    headerName: 'Código de la Institución',
    valueGetter: params => {
      return params.data.InstitutionCode
    }
  },
  { /**  */
    headerName: 'Carácter de la Institución',
    valueGetter: params => {
      return params.data.InstitutionCharacter
    }
  },
  { /**  */
    headerName: 'Metodología',
    valueGetter: params => {
      return params.data.Methodology
    }
  },
  { /**  */
    headerName: 'Grado Obtenido',
    valueGetter: params => {
      return params.data.ObtainedDegree
    }
  },
  { /**  */
    headerName: 'Número de Registro de Graduación',
    valueGetter: params => {
      return params.data.GraduationRecordNo
    }
  },
  { /**  */
    headerName: 'Número de Folio de Diploma',
    valueGetter: params => {
      return params.data.DiplomaFolioNo
    }
  },
  { /**  */
    headerName: 'Tipo de Formación',
    valueGetter: params => {
      return params.data.TrainingType
    }
  },
  { /**  */
    headerName: 'Código ICFES',
    valueGetter: params => {
      return params.data.ICFESCode
    }
  },
  { /**  */
    headerName: 'Puntaje ICFES',
    valueGetter: params => {
      return params.data.ICFESScore
    }
  },
  { /**  */
    headerName: 'Fecha de Presentación del ICFES',
    valueGetter: params => {
      return params.data.ICFESPresentationDate
    }
  },
  { /**  */
    headerName: 'Año de Reporte de Pruebas Saber 11 del ICFES',
    valueGetter: params => {
      return params.data.YearICFESPruebasSaberReport
    }
  },
  { /**  */
    headerName: 'Resultado en el Área de Inglés',
    valueGetter: params => {
      return params.data.EnglishAreaResult
    }
  },
  { /**  */
    headerName: 'Resultado en Componente Flexible',
    valueGetter: params => {
      return params.data.FlexibleComponentResult
    }
  },
  { /**  */
    headerName: 'Ingreso Familiar',
    valueGetter: params => {
      return params.data.FamilyIncome
    }
  },
  { /**  */
    headerName: 'Número de Hermanos',
    valueGetter: params => {
      return params.data.NumberSiblings
    }
  },
  { /**  */
    headerName: 'Hermanos con Educación Superior',
    valueGetter: params => {
      return params.data.SiblingsHigherEducation
    }
  },
  { /**  */
    headerName: 'Posición entre los Hermanos',
    valueGetter: params => {
      return params.data.SiblingPosition
    }
  },
  { /**  */
    headerName: 'Familia en las Fuerzas Armadas',
    valueGetter: params => {
      return params.data.ArmedForcesFamily
    }
  },
  { /**  */
    headerName: 'Nombre de Familia en las Fuerzas Armadas',
    valueGetter: params => {
      return params.data.ArmedForcesFamilyName
    }
  },
  { /**  */
    headerName: 'Dirección del Padre',
    valueGetter: params => {
      return params.data.FatherAddress
    }
  },
  { /**  */
    headerName: 'Dirección de la Madre',
    valueGetter: params => {
      return params.data.MotherAddress
    }
  },
  { /**  */
    headerName: 'Nombre del Acudiente',
    valueGetter: params => {
      return params.data.GuardianName
    }
  },
  { /**  */
    headerName: 'Número de Identificación del Acudiente',
    valueGetter: params => {
      return params.data.GuardianIDNumber
    }
  },
  { /**  */
    headerName: 'Teléfono del Acudiente',
    valueGetter: params => {
      return params.data.GuardianPhone
    }
  },
  { /**  */
    headerName: 'Dirección del Acudiente',
    valueGetter: params => {
      return params.data.GuardianAddress
    }
  },
  { /**  */
    headerName: 'Segundo Idioma',
    valueGetter: params => {
      return params.data.SecondLanguage
    }
  },
  { /**  */
    headerName: 'Nombre del Segundo Idioma',
    valueGetter: params => {
      return params.data.SecondLanguageName
    }
  },
  { /**  */
    headerName: 'Método de Transporte',
    valueGetter: params => {
      return params.data.TransportationMethod
    }
  },
  { /**  */
    headerName: 'Subgrupo Sisbén',
    valueGetter: params => {
      return params.data.SisbenSubgroup
    }
  },
  { /**  */
    headerName: 'Lugar de Expedición del Documento',
    valueGetter: params => {
      return params.data.DocumentIssuingPlace
    }
  },
  { /**  */
    headerName: 'Número de Hijos',
    valueGetter: params => {
      return params.data.NumberChildren
    }
  },
  { /**  */
    headerName: 'ARS',
    valueGetter: params => {
      return params.data.HealthInsurance
    }
  },
  { /**  */
    headerName: 'Aseguradora',
    valueGetter: params => {
      return params.data.InsuranceCompany
    }
  },
  { /**  */
    headerName: 'Libreta Militar',
    valueGetter: params => {
      return params.data.MilitaryID
    }
  },
  { /**  */
    headerName: 'Fecha de Expedición del Documento',
    valueGetter: params => {
      return params.data.DocumentIssuanceDate
    }
  },
  { /**  */
    headerName: 'Ley para Veteranos',
    valueGetter: params => {
      return params.data.VeteransLaw
    }
  },
  { /**  */
    headerName: 'Zona de reclutamiento',
    valueGetter: params => {
      return params.data.recruitingZone
    }
  },
];