import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { RoleMapping } from '../models/roleMapping';


@Injectable({
  providedIn: 'root'
})
export class RoleMappingService {

  constructor(
    private _httpService: HttpService
  ) { }

  getRoles(): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings?filter={"include": ["role"]}`
    return this._httpService.httpGet(url).pipe(take(1));
  }
  
}
