import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class sniesService {

  constructor(
    private _httpService: HttpService
  ) { }

 
  getParticipantes(SchoolID: number): Observable<any> {
    let  url = `/SniesParticipantes?filter={"where":{"SchoolID": ${SchoolID}}}`
    return this._httpService.httpGet(url);
  }
 
  getInscritos(SchoolID: number): Observable<any> {
    let  url = `/SniesInscritos?filter={"where":{"SchoolID": ${SchoolID}}}`
    return this._httpService.httpGet(url);
  }
 
  getContratoDocente(SchoolID: number): Observable<any> {
    let  url = `/SniesContratoDocente?filter={"where":{"SchoolID": ${SchoolID}}}`
    return this._httpService.httpGet(url);
  }
 
  getDocenteIES(SchoolID: number): Observable<any> {
    let  url = `/SniesDocenteIES?filter={"where":{"SchoolID": ${SchoolID}}}`
    return this._httpService.httpGet(url);
  }
 
  getSniesMilitar(SchoolID: number): Observable<any> {
    let  url = `/SniesMilitar?filter={"where":{"SchoolID": ${SchoolID}}}`
    return this._httpService.httpGet(url);
  }
 
  getPrograma(SchoolID: number,filter?:string): Observable<any> {
    if (!filter){
      filter = ""
    }
    let  url = `/SniesProgramas?filter={"where":{ "and":[{"SchoolID":${SchoolID}}${filter}] }}`
    return this._httpService.httpGet(url);
  }
 

}


