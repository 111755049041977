
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'general-section-pagination',
  templateUrl: './general-section-pagination.component.html',
  styleUrls: [ './general-section-pagination.component.css' ]
} )
export class GeneralSectionPaginationComponent implements OnInit {

  constructor ( ) {
    //
  }

  private _canScroll: boolean = true;

  //Sends a call with the id of the selected module: : e.g., in index 1 the id is 8
  @Output( 'onSetCurrentPage' ) onSetCurrentPage: EventEmitter<number> = new EventEmitter();

  //Receives the total pages to show 
  @Input('maxPagesToShow') maxPagesToShow:number = 2;

  /**
   * Receives a page index to scroll to when canScroll is true
   */
  @Input( 'pageToSelect' ) set pageToSelect( value: number ) {
    if ( this._canScroll ) {
      this.currentPage = value + 1;
    }
  }

  //Obtain a list with the ids of the created elements, this means that each element must have an id
  private _totalPages: number = 0;
  @Input( 'totalPages' ) set totalPages( value: any ) {
    this._totalPages = value;

    if ( this.currentPage > this._totalPages ) {
      this.currentPage = this._totalPages;
      this.onSetCurrentPage.emit( this.currentPage );
    } else {
      this.currentPage = Math.max( 1, this.currentPage );
    }
  }

  get totalPages() {
    return this._totalPages;
  }

  //Allows you to set whether paging is displayed or not.
  @Input( 'showPagination' ) showPagination: boolean = false;

  //Allows you to set whether paging is displayed or not.
  @Input( 'vOrientation' ) vOrientation: boolean = true;

  //Stores the selected index in the page
  public currentPage: number = 0;

  //Stores a time to wait for scroll.
  private _scrollTimeOut: any = undefined;

  ngOnInit(): void {
    //
  }

  /**
   *  It sets the value of selectedPageId to the value of pageId and emits the value of moduleId.
   * 
   * @param moduleId 
   * @param pageId 
   */
  public selectedPage( index: number ) {
    this.stopAutoSelect();
    this.currentPage = index;
    this.onSetCurrentPage.emit( index - 1 );
  }

  /**
   * This code is a function that is used to navigate to the next page.
   */
  public nextPage() {
    if ( this.currentPage < this._totalPages ) {
      this.currentPage += 1;
      this.stopAutoSelect();
      this.onSetCurrentPage.emit( this.currentPage - 1 );
    }
  }

  /**
   * This code is a function that is used to navigate to the previous page.
   */
  public previousPage() {
    if ( this.currentPage > 1 ) {
      this.currentPage -= 1;
      this.stopAutoSelect();
      this.onSetCurrentPage.emit( this.currentPage - 1 );
    }
  }

  /**
   * This function is used to control the scrolling behavior of an element while an automatic selection process is running. 
   * It stops scrolling for a short period of time and then allows it to resume after the timeout has elapsed.
   */
  private stopAutoSelect() {
    clearTimeout( this._scrollTimeOut );
    this._canScroll = false;
    this._scrollTimeOut = setTimeout( () => {
      this._canScroll = true;
    }, 1000 );
  }
}