import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HttpClientModule } from '@angular/common/http';
import { DirectivasComponent } from './components/directivas/directivas.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { NuevaDirectivaComponent } from './components/nuevaDirectiva/nuevaDirectiva.component';
import { PieChartComponent } from './components/shared/pieChart/pieChart.component';
import { GanttComponent } from './components/gantt/gantt.component';
import { MatrizComponent } from './components/matriz/matriz.component';

// Modules
import { NgChartjsModule } from 'ng-chartjs';
//import { ChartsModule } from 'ng2-charts';

import { MultipleBarChartComponent } from './components/shared/multipleBarChart/multipleBarChart.component';
import { DetailsCourseOferComponent } from './components/shared/detailsCourseOfer/detailsCourseOfer.component';
import { MenuBarComponent } from './components/shared/menu-bar/menu-bar.component';
import { ChartsModule } from 'ng2-charts';

// import { AgmCoreModule } from '@agm/core';
import { CourseCardComponent } from './components/matriz/course-card/course-card.component';
import { FilterBarComponent } from './components/shared/filter-bar/filter-bar.component';
import { MultiDirectivaComponent } from './components/multi-directiva/multi-directiva.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { FinancialReportComponent } from './components/financial-report/financial-report.component';
import { PaymentOrderHistoryComponent } from './components/payment-order-history/paymentOrderHistory.component';
import { StateComponent } from './components/shared/tableList/cardId/state/state.component';
import { CommonModule } from '@angular/common';
import { NumberCodeComponent } from './components/shared/tableList/cardId/number-code/number-code.component';
import { DateIssueComponent } from './components/shared/tableList/cardId/date-issue/date-issue.component';
import { DateExpiryComponent } from './components/shared/tableList/cardId/date-expiry/date-expiry.component';
import { DateChangeComponent } from './components/shared/tableList/cardId/date-change/date-change.component';
import { BookNumberComponent } from './components/shared/tableList/userCourse/book-number/book-number.component';
import { DiplomaNumberComponent } from './components/shared/tableList/userCourse/diploma-number/diploma-number.component';
import { ActaNumberComponent } from './components/shared/tableList/userCourse/acta-number/acta-number.component';
import { FolioComponent } from './components/shared/tableList/userCourse/folio/folio.component';
import { SearchPipe } from './pipes/search.pipe';
import { UserSearchModule } from './components/shared/user-search/user-search.module';
import { UploadFileBoxModule } from './components/shared/upload-file-box/upload-file-box.module';
import { AttachmentService } from './services/attachment.service';
import { EditPaymentOrderComponent } from './components/payment-order/edit-payment-order/EditPaymentOrder.component';
import { LoaderModule } from './components/shared/loader/loader.module';
import { CreatePaymentOrderComponent } from './components/payment-order/create-payment-order/CreatePaymentOrder.component';
import { InstrumentsModule } from './components/selfAssessment/instruments/instruments.module';
import { EvaluateModule } from './components/selfAssessment/evaluate/evaluate.module';
import { GeneralResultsModule } from './components/selfAssessment/general-results/general-results.module';
import { SelfEvaluationComponent } from './components/self-evaluation/self-evaluation.component';
import { SeHeaderDetailsComponent } from './components/shared/se-header-details/se-header-details.component';
import { SeHeaderColaboratorsComponent } from './components/shared/se-header-colaborators/se-header-colaborators.component';
import { SeHeaderUserSelectedComponent } from './components/shared/se-header-user-selected/se-header-user-selected.component';
import { SeStickyBarComponent } from './components/shared/se-sticky-bar/se-sticky-bar.component';
import { SeFormSectionComponent } from './components/shared/se-form-section/se-form-section.component';
import { SeQuestionFormSectionComponent } from './components/shared/se-question-form-section/se-question-form-section.component';
import { SeQuestionFormSectionComponentComponent } from './components/shared/se-question-form-section-component/se-question-form-section-component.component';
import { SeTableResultsComponent } from './components/shared/se-table-results/se-table-results.component';
import { SelfEvaluationJudgeComponent } from './components/self-evaluation-judge/self-evaluation-judge.component';
import { SeStickyBarJudgeComponent } from './components/shared/se-sticky-bar-judge/se-sticky-bar-judge.component';
import { NewProcessModule } from './components/selfAssessment/new-process/new-process.module';
import { EditProcessModule } from './components/selfAssessment/edit-process/edit-process.module';

import { RoleManagerComponent } from './components/role-manager/role-manager.component';
import { RoleButtonComponent } from './components/role-manager/role-button/role-role-button.component';

import { CookieService } from 'ngx-cookie-service'
import { PaymentOrderModule } from './components/upload-payment-order/payment-order.module';

// import { StickyBarSurveyComponent } from './components/selfAssessment/shared/sticky-bar-survey/sticky-bar-survey.component';
@NgModule( {
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    DirectivasComponent,
    NuevaDirectivaComponent,
    CreatePaymentOrderComponent,
    EditPaymentOrderComponent,
    PaymentOrderHistoryComponent,
    MenuBarComponent,
    PieChartComponent,
    MultipleBarChartComponent,
    DetailsCourseOferComponent,
    GanttComponent,
    FinancialReportComponent,
    MatrizComponent,
    CourseCardComponent,
    FilterBarComponent,
    MultiDirectivaComponent,
    UsersListComponent,
    StateComponent,
    NumberCodeComponent,
    DateIssueComponent,
    DateExpiryComponent,
    DateChangeComponent,
    BookNumberComponent,
    DiplomaNumberComponent,
    ActaNumberComponent,
    FolioComponent,
    SearchPipe,
    SelfEvaluationComponent,
    SeHeaderDetailsComponent,
    SeHeaderColaboratorsComponent,
    SeHeaderUserSelectedComponent,
    SeStickyBarComponent,
    SeFormSectionComponent,
    SeQuestionFormSectionComponent,
    SeQuestionFormSectionComponentComponent,
    SeTableResultsComponent,
    SelfEvaluationJudgeComponent,
    SeStickyBarJudgeComponent,
    RoleManagerComponent,
    RoleButtonComponent
    // StickyBarSurveyComponent,
  ],
  imports: [
    PaymentOrderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgSelectModule,
    ChartsModule,
    NgxChartsModule,
    NgChartjsModule,
    AgGridModule,
    CommonModule,
    UserSearchModule,
    UploadFileBoxModule,
    LoaderModule,

    InstrumentsModule,
    NewProcessModule,
    EditProcessModule,
    GeneralResultsModule,
    EvaluateModule,
    //   AgmCoreModule.forRoot({
    //     apiKey: 'AIzaSyDGwixTf36KrOmE_f58XqRl57xQeigCDGA'
    //  })
  ],
  // exports: [ChartsModule],
  providers: [ AttachmentService, CookieService ],
  bootstrap: [ AppComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
} )
export class AppModule { }
