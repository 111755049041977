
import { Component, OnInit } from '@angular/core';
import { CourseOffer } from 'src/app/models/courseOffer';
import { DirectivasService } from 'src/app/services/directivas.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { School } from 'src/app/models/school';
import { Category } from 'src/app/models/category';

import { Course } from 'src/app/models/course';

import { Modality } from 'src/app/models/modality';
import { Range } from 'src/app/models/range';
import { Force } from 'src/app/models/force';
import { TypesCourse } from 'src/app/models/typesCourse';
import { forkJoin, Subscription } from 'rxjs';
import { CourseService } from 'src/app/services/course.service';
import { OFERSTATES, TypeOferState } from 'src/app/models/oferState';
import { Departament } from 'src/app/models/department';
import { TypeTraining } from 'src/app/models/typeTraining';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'nueva-directiva',
  templateUrl: './nuevaDirectiva.component.html',
  styleUrls: ['./nuevaDirectiva.component.css', './nuevaDirectiva.component.sass']
})
export class NuevaDirectivaComponent implements OnInit {

  public APP_NAME: string = environment.principalAppName;  
  public isSubmit: boolean = false;
  public year: number;
  public newCourseOffer: CourseOffer = new CourseOffer();

  public isActiva: boolean = true;
  public isTraining: boolean = false;
  public courses: Course[] = [];
  public course: Course;
  public place: Departament;
  public schools: School[] = []

  public categories: Category[] = [new Category()];
  public typeCourses: TypesCourse[] = [new TypesCourse()];
  public typeCoursesAll: TypesCourse[] = [new TypesCourse()];
  public modalities: Modality[] = [new Modality()];
  public ranges: Range[] = [new Range()];
  public forces: Force[] = [new Force()];
  public deptos: Departament[];
  public typeTrainings: TypeTraining[];

  public categoriesSelected: Category[] = [];
  public typeCoursesSelected: TypesCourse[] = [];
  public modalitiesSelected: Modality[] = [];
  public rangesSelected: Range[] = [];
  public forcesSelected: Force[] = [];

  private subscriptionSchool: Subscription
  constructor(
    private _directivasService: DirectivasService,
    private courseService: CourseService,
    public sharedService: SharedService,
  ) {
    this.course = new Course()
    this.course.CourseOfer=[]
  }



  ngOnInit(): void {
    this.getFixedFilters();
    this.subscriptionSchool = this.sharedService.sharedyearSchoolFilter.subscribe(data => {
      this.schools = data.schools;
      this.year = data.year;
      if(this.schools[0])
      {
        this.newCourseOffer.SchoolID = this.schools[0]? this.schools[0].id : 0;
        this.getCourses();
      }
    });
  }
  
  ngOnDestroy() {
    this.subscriptionSchool.unsubscribe()
  }

  public getFixedFilters() {

    const filterRange = { where: {ForceID:1}}

    forkJoin([
      this.courseService.getCategories(),
      this.courseService.getModalities(),
      this.courseService.getForces(),
      this.courseService.getRanges(JSON.stringify(filterRange)),
      this.courseService.getDepartments(),
      this.courseService.getTypeTrainings()
    ]).subscribe(Val => {

      [this.categories, this.modalities, this.forces, this.ranges, this.deptos, this.typeTrainings] = Val;

      this.categoriesSelected = this.categories;

      this.typeCourses = this.categories.map(r => r.TypeCourses).reduce((acc, val) => acc.concat(val), []);
      this.typeCoursesAll = this.typeCourses;
      this.typeCoursesSelected = this.typeCourses;

      this.modalitiesSelected = this.modalities;

    });
  }

  public onCategoryChange() {
    this.typeCourses = this.typeCoursesAll.filter(r => {
      return !(this.categoriesSelected.findIndex(e => e.id == r.CategoryID) < 0);
    });
    this.typeCoursesSelected = this.typeCourses;
    this.onTypeCourseChange();
  }

  public onTypeCourseChange() {
    this.getCourses();
  }

  onCourseSelected(course:Course){
    this.newCourseOffer.CourseID = course.id;
    this.newCourseOffer.NameCourseOfer = course.NameCourse;
    this.newCourseOffer.TypeCourseID = course.TypeCourseID;
    this.newCourseOffer.IsDirectiva = true;
  }

  onPlaceSelected(){
    this.newCourseOffer.DepartamentID = this.place.id;
    this.newCourseOffer.Altitud = this.place.Latitude;
    this.newCourseOffer.Longitud = this.place.Longitude;
  }

  getCourses() {
    const filter = {
      where:{ and:[
        { "IsActive":this.isActiva },
        { "SchoolID": this.newCourseOffer.SchoolID },
        { "TypeCourse": {inq: this.typeCoursesSelected.map(e=>e.id)}}
      ]},
      include:{"CourseOfer":["oferState","TypeCourseOFer"]}
    }
    this._directivasService.getCourses(JSON.stringify(filter)).pipe(take(1)).subscribe(data => {
      this.courses = data;
      this.course = new Course()
      this.course.CourseOfer=[]
    })
  }


  cerateProgram(valid) {

    console.log(this.newCourseOffer)
    if (valid) {

      this.isSubmit=true

      this.newCourseOffer.RealSince = this.newCourseOffer.Since;
      this.newCourseOffer.RealUntil = this.newCourseOffer.Until;

      this.newCourseOffer.Profiles = this.rangesSelected.map(x => x.Sigla);
      this.newCourseOffer.Forces = this.forcesSelected.map(x => x.Sigla);

      if(this.newCourseOffer.IsDirectiva)
      {
        this.newCourseOffer.IsActive = false;
      }
      else{
        this.newCourseOffer.IsActive = true;
        this.newCourseOffer.OferState = TypeOferState.noCronograma;
        this.newCourseOffer.oferState = OFERSTATES[TypeOferState.noCronograma];
      }

      this.newCourseOffer.IsActive = !this.newCourseOffer.IsDirectiva;

      this._directivasService.newCourseOfer(this.newCourseOffer).pipe(take(1)).subscribe(data => {
        this.isSubmit=false
        this.sharedService.sleep(1000).then(() => {
          this.newCourseOffer = new CourseOffer();
          this.newCourseOffer.SchoolID = this.schools[0].id
          this.sharedService.swalSuccess("Programa Creado", "");
        })
      }, err => {
        this.isSubmit=false
        const message = err.error.error.message || `Details: Hubo un problema al guardar la información`;
        this.sharedService.swalError("Error De formulario", message.split("Details: ")[1]);
      })

    }
    else {
      this.sharedService.swalError("Error De formulario", "Todos los campos son obligatorios");
    }

  }


}
