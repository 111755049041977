import { CourseOffer } from './courseOffer';
import { TypesCourse } from './typesCourse';

export class Course {
    public id:number;
    public NameCourse:string;
    public TypeCourseID:number;
    public SchoolID: number;
    public DurationTotal:number;
    public Description:string;
    public NoRegisterMinE:number;
    public NoRegisterMiL: string;
    public IsMilitar:boolean;
    public IsSemestralize:boolean;
    public TeachingHoursmin: number;
    public totalSemester:number;
    public durationSemester:number;
    public IsActive: boolean;
    public requirements:string;
    public profiles:any[];
    public CourseID: number;
    public IsBase: boolean;

    public CourseOfer: CourseOffer[];
    public TypeCourse?: TypesCourse; 

}

