import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyBarSurveyComponent } from './sticky-bar-survey.component';



@NgModule({
  declarations: [
    StickyBarSurveyComponent
  ],
  imports: [
    CommonModule
  ], exports: [
    StickyBarSurveyComponent
  ]
})
export class StickyBarSurveyModule { }
