import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessIdEnum, ProcessNameEnum } from 'src/app/models/selfAssessment/enums/processEnum';
import { SectionNameEnum, SectionToShowEnum } from 'src/app/models/selfAssessment/enums/sectionEnum';
import { SelfAssessmentAlertsService } from 'src/app/services/selfAssessment/self-assessment-alerts.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';

@Component( {
  selector: 'new-process-component',
  templateUrl: './new-process.component.html',
  styleUrls: [ './new-process.component.css' ]
} )
export class NewProcessComponent implements OnInit {

  constructor (
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _selfAssessmentService: SelfAssessmentService,
    private _selfAssessmentAlertService: SelfAssessmentAlertsService,
  ) { }

  //Stores all active modules id for the pagination
  public modulesLength: number = 0;

  //Stores the selected module id for the pagination
  public selectedPage: number = 0;

  //Stores the id of the module where you are standing to send it to pagination.
  public pageToSelect: number = 0;

  //Stores the general section data
  public getGeneralData: any[] = [];

  //Stores the files section data
  public getFilesData: any[] = [];

  //Stores the main form data
  public getMainFormData: any = {};

  //It is used to store the reference to a specific section.
  public currentSection: SectionToShowEnum = SectionToShowEnum.generalSection;

  //Gets the SectionToShow enum data
  public sectionToShow: any = SectionToShowEnum;

  //Stores the type section id
  public typeSectionId: number = 0;

  //Stores the current url name
  public processName: string = '...';

  //Stores the current section name
  public sectionName: string = '...';

  //If true, the main form validations will be activated
  public checkMainFormErrors: boolean = false;

  //Stores the current user info
  public currentUserInfo: any = {};

  //Gets the url names enum
  private _processName: any = ProcessNameEnum;

  //Gets the section names enum
  private _sectionNameEnum: any = SectionNameEnum;

  //Gets the url ids enum
  private _processId: any = ProcessIdEnum;

  ngOnInit(): void {
    const processName = this._activatedRoute.snapshot.params.process;
    this.processName = this._processName[ processName ]; //sets the url name

    if ( this.processName === undefined ) {
      this._router.navigate( [ '/landing' ] );
      return;
    }

    this.sectionName = this._sectionNameEnum[ processName ]; //sets the section name
    this.typeSectionId = Number( this._processId[ processName ] );

    this._selfAssessmentAlertService.
      swalLoading( 'Cargando...', 'Estamos cargando el contenido, por favor, espera un momento.' );

    this._selfAssessmentService.getCurrentUser().subscribe( {
      next: ( resp: any ) => {
        this.currentUserInfo = resp;
        this._selfAssessmentAlertService.swalClose();
      },
      error: ( err ) => {
        this.onError();
        console.log( err );
      }
    } )
  }

  /**
   * Redirects to the dashboard
   */
  public goBack() {
    const processName = this._activatedRoute.snapshot.params.process;
    this._router.navigate( [ `/dashboard/${ processName }` ] );
  }

  /**
   * Shows an error message and redirects to the dashboard of the corresponding process.
   * @returns void
   */
  private onError() {
    const processName: string = this._activatedRoute.snapshot.params.process;
    this._selfAssessmentAlertService.
      swalError( 'Error', 'Se ha producido un error al procesar tu solicitud. Por favor, inténtalo de nuevo.',
        () => this._router.navigate( [ `dashboard/${ processName }` ] ) );
  }
}