import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { DirectivasService } from 'src/app/services/directivas.service';
import { take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { sniesService } from 'src/app/services/snies.service';
import { Header, XlsxService } from 'src/app/services/xlsx.service';



@Component({
  selector: 'menu-bar-component',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css', './menu-bar.component.sass']
})
export class MenuBarComponent implements OnInit, OnChanges {
  @Input() stateView: any;
  @Input() public currentStateView: any


  public isAdmin:boolean = false;
  public sniesHeaders: Header[][] = []
  public myInfo: { myRoles: any[], mySchoolID: number } = { myRoles: [], mySchoolID: 0 };

  public currentYear = (new Date()).getFullYear();
  public lastYear = this.currentYear + 1;
  public urlIconAssets = "https://storage.googleapis.com/assets_cedoc/Iconos/"
  public listStateView = [
    { name: "Estadistica", vsg: 'statistics.svg', stateView: '', url: '/dashboard' },
    { name: "Matriz", vsg: 'matrix.svg', stateView: 'matriz', url: '/dashboard/matriz' },
    { name: "Gantt", vsg: 'gantt.svg', stateView: 'gantt', url: '/dashboard/gantt' },
    { name: "Modificaciones", vsg: 'modifications.svg', stateView: 'modificaciones' },
    { name: "SNIES", vsg: 'export.svg', stateView: null },
    { name: "Reporte financiero", vsg: 'statistics.svg', stateView: 'financialReport', url: '/dashboard/financialReport' },
  ]

  constructor(
    private _directivasService: DirectivasService,
    private _sniesService: sniesService,
    private _xlsxService: XlsxService,
    public sharedService: SharedService,
    private _router: Router
  ) {
    if (!this.stateView)
      this.stateView = 'gantt'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes["currentStateView"]) {
      let pos = this.listStateView.findIndex(x => x.name == changes['currentStateView'].currentValue)
      if (pos >= 0)
        this.changeStateView(pos)
    }

  }

  ngOnInit(): void {
    this.getRoles()
    this.initHeaders()


  }

  getRoles() {

    let filter = `{"include":["role"]}`
    this._directivasService.getMyRoleMappings(filter).pipe(take(1)).subscribe(rolemapp => {

      this.myInfo.mySchoolID = rolemapp[0].SchoolID;

      rolemapp.forEach(role => {
        if (role.role.name == "Diedu") {
          this.myInfo.mySchoolID = role.SchoolID;
        }
        if (role.role.name == "Admin" || role.role.name == "Directiva") {
          this.myInfo.mySchoolID = role.SchoolID;
          this.isAdmin = true;
        }
      });
    });

  }

  initHeaders() {

    const thisyear = this.currentYear.toString();
    const thisSemster = (new Date()).getMonth() > 5 ? "2" : "1";
    //Participante.xlsx
    this.sniesHeaders[0] = [
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "FECHA_EXPEDICION", property: "DateExpedition" },
      { header: "PRIMER_NOMBRE", property: "Name1" },
      { header: "SEGUNDO_NOMBRE", property: "Name2" },
      { header: "PRIMER_APELLIDO", property: "LastName1" },
      { header: "SEGUNDO_APELLIDO", property: "LastName2" },
      { header: "ID_SEXO_BIOLOGICO", property: "biologicalSexID" },
      { header: "ID_ESTADO_CIVIL", property: "civilStatusID" },
      { header: "FECHA_NACIMIENTO", property: "birthDate" },
      { header: "ID_PAIS", property: "Nacionality" },
      { header: "ID_MUNICIPIO", property: "Ciudad" },
      { header: "TELEFONO_CONTACTO", property: "CellPhone" },
      { header: "EMAIL_PERSONAL", property: "Email" },
      { header: "EMAIL_INSTITUCIONAL", property: "Address" },
      { header: "DIRECCION_INSTITUCIONAL", property: "CedocEmail" },
    ]
    //Admitidos.xlsx
    this.sniesHeaders[1] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO", property: "", default: "0" },
      { header: "TELEFONO_CONTACTO", property: "CellPhone" },
      { header: "EMAIL_PERSONAL", property: "Email" },
      { header: "FECHA_NACIMIENTO", property: "birthDate" },
    ]
    //Inscrito_Programa.xlsx
    this.sniesHeaders[2] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO", property: "", default: "0" },
    ]
    //Inscritos_-_Relaci_n_de_Inscritos.xlsx**
    this.sniesHeaders[3] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRIMER_NOMBRE", property: "Name1" },
      { header: "SEGUNDO_NOMBRE", property: "Name2" },
      { header: "PRIMER_APELLIDO", property: "LastName1" },
      { header: "SEGUNDO_APELLIDO", property: "LastName2" },
      { header: "ID_SEXO_BIOLOGICO", property: "biologicalSexID" },
    ]
    //Estudiantes__de_primer_curso.xlsx
    this.sniesHeaders[4] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO_PROGRAMA", property: "", default: "0" },
      { header: "ID_TIPO_VINCULACION", property: "TypeVinculationID" },
      { header: "ID_GRUPO_ETNICO", property: "ethnicalGroupID" },
      { header: "ID_PUEBLO_INDIGENA", property: "indigenousPeopleID" },
      { header: "ID_COMUNIDAD_NEGRA", property: "blackCommunitiesID" },
      { header: "PERSONA_CON_DISCAPACIDAD", property: "", default: "N" },
      { header: "ID_TIPO_DISCAPACIDAD", property: "handicappedID" },
      { header: "ID_CAPACIDAD_EXCEPCIONAL", property: "exceptionalCapacityID" },
      { header: "COD_PRUEBA_SABER_11", property: "Saber11" },
    ]
    //Matriculado.xlsx
    this.sniesHeaders[5] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "CODIGO_ESTUDIANTE", property: "id" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO", property: "", default: "0" },
      { header: "FECHA_NACIMIENTO", property: "birthDate" },
      { header: "ID_PAIS_NACIMIENTO", property: "Nacionality" },
      { header: "ID_MUNICIPIO_NACIMIENTO", property: "Ciudad" },
      { header: "ID_ZONA_RESIDENCIA:", property: "zoneID" },
      { header: "ES_REINTEGRO_ESTUDIANTE_ANTES_DE_2007", property: "", default: "N" },
    ]

    //Graduado.xlsx
    this.sniesHeaders[6] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO", property: "", default: "0" },
      { header: "EMAIL_PERSONAL", property: "Email" },
      { header: "TELEFONO_CONTACTO", property: "CellPhone" },
      { header: "SPN_SABER_PRO", property: "SaberPro" },
      { header: "NUMERO_ACTA_GRADO", property: "Acta" },
      { header: "FECHA_GRADO:", property: "FinishDate" },
      { header: "NUM_FOLIO:", property: "FolderNumber" },
    ]
    //Docentes_IES.xlsx
    this.sniesHeaders[7] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "FECHA_NACIMIENTO", property: "birthDate" },
      { header: "ID_PAIS_NACIMIENTO", property: "Nacionality" },
      { header: "ID_MUNICIPIO_NACIMIENTO", property: "Ciudad" },
      { header: "EMAIL_INSTITUCIONAL", property: "CedocEmail" },
      { header: "ID_NIVEL_MAX_ESTUDIO", property: "MaxStudyTypeID" },
      { header: "TITULO_RECIBIDO", property: "Title" },
      { header: "FECHA_GRADO", property: "FinishDate" },
      { header: "ID_PAIS_INSTITUCION_ESTUDIO", property: "Country" },
      { header: "TITULO_CONVALIDADO", property: "", default: "" },
      { header: "ID_IES_ESTUDIO", property: "", default: "" },
      { header: "NOMBRE_INSTITUCION_ESTUDIO", property: "Institute" },
      { header: "ID_METODOLOGIA_PROGRAMA", property: "MetodologyID" },
      { header: "FECHA_INGRESO", property: "StartDate" },
    ]
    //Contrato_docente.xlsx
    this.sniesHeaders[8] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "ID_TIPO_CONTRATO", property: "TypeContractID" },
      { header: "ID_DEDICACION", property: "TypeDedicationID" },
      { header: "ID_METODOLOGIA:", property: "MetodologyID" },
      { header: "ID_NIVEL", property: "LevelID" },
      { header: "HORAS_DEDICACION_SEMESTRE", property: "ContractHours" },
      { header: "ASIGNACION_BASICA_MENSUAL", property: "Amount" },
      { header: "PORCENTAJE_DOCENCIA", property: "ProfesorPC" },
      { header: "PORCENTAJE_INVESTIGACION", property: "ResearchPC" },
      { header: "PORCENTAJE_ADMINISTRATIVA:", property: "AdmonPC" },
      { header: "PORCENTAJE_EXTENSION:", property: "ExtencionPC" }

    ]
    //Materias_Matriculado **
    this.sniesHeaders[9] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO", property: "", default: "0" },
      { header: "NUM_MATERIAS_INSCRITAS", property: "", default: "0" },
      { header: "NUM_MATERIAS_APROBADAS:", property: "", default: "0" },
    ]
    //Apoyo_financiero **
    this.sniesHeaders[10] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO_PROGRAMA", property: "", default: "0" },
      { header: "RECIBIO_APOYO_FINANCIERO", property: "", default: "N" },
      { header: "RECIBIO_APOYO_ACADEMICO", property: "", default: "S" },
      { header: "RECIBIO_OTROS_APOYOS", property: "", default: "S" },
    ]
    //Retiros_Estudiantiles.xlsx
    this.sniesHeaders[11] = [
      { header: "AÑO", property: "", default: thisyear },
      { header: "SEMESTRE", property: "", default: thisSemster },
      { header: "ID_TIPO_DOCUMENTO", property: "TypeDocumentID" },
      { header: "NUM_DOCUMENTO", property: "Document" },
      { header: "PRO_CONSECUTIVO", property: "NoRegisterMinE" },
      { header: "ID_MUNICIPIO_PROGRAMA", property: "", default: "0" },
      { header: "ID_TIPO_RETIRO", property: "", default: "" },
      { header: "NUM_ACTO_ADMINISTRATIVO", property: "", default: "S" },
      { header: "FECHA_ACTO_ADMINISTRATIVO", property: "", default: "S" },
    ]

  }

  changeStateView(fromIndex: number, gotoURL: boolean = false): void {

    var element = this.listStateView[fromIndex];
    if (element.stateView == null) {
      //download csv
      this.showSniesOptions()
    }
    else {

      this.listStateView.splice(fromIndex, 1);
      this.listStateView.splice(0, 0, element);
      this.stateView = element.stateView
      if (element.url && gotoURL) {
        this._router.navigate([element.url], { state: { enableToLoad: true } });
      }


    }

  }

  gotoNuevaDirectiva() {
    this._router.navigate(['/dashboard/nueva'], { state: { enableToLoad: true } });
  }

  gotoPaymentOrder() {
    this._router.navigate(['/dashboard/paymentOrder'], { state: { enableToLoad: true } });
  }

  gotoUploadPaymentOrder() {
    console.log("lafkjsdlkjf asdfasdfasdf");
    
    this._router.navigate(['/dashboard/uploadPaymentOrder'], { state: { enableToLoad: true } });
  }

  gotoMultiDirectiva() {
    this._router.navigate(['/dashboard/multi'], { state: { enableToLoad: true } });
  }

  addYear() {
    this.currentYear == this.lastYear ? '' : this.currentYear = this.currentYear + 1
  }

  subtractYear() {
    this.currentYear == 2016 ? '' : this.currentYear = this.currentYear - 1
  }

  async showSniesOptions() {

    const { value: template } = await Swal.fire({
      title: 'Plantillas SNIES Disponibles para descargar',
      input: 'select',
      inputOptions: {
        'Participante.xlsx': 'Participante.xlsx',
        'Admitidos.xlsx': 'Admitidos.xlsx',
        'Inscrito_Programa.xlsx': 'Inscrito_Programa.xlsx',
        'Inscritos_-_Relaci_n_de_Inscritos.xlsx': 'Inscritos_-_Relaci_n_de_Inscritos.xlsx*',
        'Estudiantes__de_primer_curso.xlsx': 'Estudiantes__de_primer_curso.xlsx',
        'Matriculados.xlsx': 'Matriculados.xlsx',
        'Graduados.xlsx': 'Graduados.xlsx',
        'Docentes_IES.xlsx': 'Docentes_IES.xlsx',
        'Contrato_docente.xlsx': 'Contrato_docente.xlsx',
        'Materias_Matriculado': 'Materias_Matriculado',
        'Apoyo_financiero': 'Apoyo_financiero',
        'Retiros_Estudiantiles.xlsx': 'Retiros_Estudiantiles.xlsx',
      },
      inputPlaceholder: 'Seleccione una Plantilla',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise<string | undefined>((resolve) => {

          let filter = '';

          if (!!value) {

            switch (value) {
              case 'Participante.xlsx':
                this._sniesService.getParticipantes(1).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Participante.xlsx', this.sniesHeaders[0], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga');
                });
                break;

              case 'Admitidos.xlsx':
                filter = ',{"IsAcepted":1}'
                this._sniesService.getPrograma(this.myInfo.mySchoolID, filter).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Admitidos.xlsx', this.sniesHeaders[1], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga');
                });
                break;

              case 'Inscrito_Programa.xlsx':
                this._sniesService.getPrograma(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Inscrito_Programa.xlsx', this.sniesHeaders[2], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga');
                });
                break;

              case 'Inscritos_-_Relaci_n_de_Inscritos.xlsx'://*TODO: check */
                this._sniesService.getInscritos(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Inscritos_-_Relaci_n_de_Inscritos.xlsx', this.sniesHeaders[3], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga');
                });
                break;

              case 'Estudiantes__de_primer_curso.xlsx'://*TODO: check */
                filter = ',{"IsAcepted":1}';
                this._sniesService.getPrograma(this.myInfo.mySchoolID, filter).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Estudiantes__de_primer_curso.xlsx', this.sniesHeaders[4], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga');
                });
                break;

              case 'Matriculados.xlsx':
                filter = ',{"IsPaied":1}';
                this._sniesService.getPrograma(this.myInfo.mySchoolID, filter).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Matriculados.xlsx', this.sniesHeaders[5], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;

              case 'Graduados.xlsx':
                filter = ',{"IsAproved":1}';
                this._sniesService.getPrograma(this.myInfo.mySchoolID, filter).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Graduados.xlsx', this.sniesHeaders[6], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;

              case 'Docentes_IES.xlsx':
                this._sniesService.getDocenteIES(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Docentes_IES.xlsx', this.sniesHeaders[7], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;

              case 'Contrato_docente.xlsx':
                this._sniesService.getContratoDocente(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Contrato_docente.xlsx', this.sniesHeaders[8], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;


              case 'Materias_Matriculado':
                this._sniesService.getPrograma(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Materias_Matriculado.xlsx', this.sniesHeaders[9], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;


              case 'Apoyo_financiero':
                this._sniesService.getPrograma(this.myInfo.mySchoolID).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Apoyo_financiero.xlsx', this.sniesHeaders[10], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;


              case 'Retiros_Estudiantiles.xlsx':
                filter = ',{"IsCancel":1}';
                this._sniesService.getPrograma(this.myInfo.mySchoolID, filter).pipe(take(1)).subscribe(data => {
                  this._xlsxService.exportToFile('Retiros_Estudiantiles.xlsx', this.sniesHeaders[11], data);
                  resolve(undefined);
                }, err => {
                  resolve('Error en descarga')
                });
                break;
            }


          } else {
            resolve('Debe Seleccionar una plantilla para descargar')
          }

        })
      }
    })

    if (template) {
      Swal.fire(`Descargando: ${template}`)
    }

  }

}
