import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { DirectivasComponent } from './components/directivas/directivas.component';
import { MatrizComponent } from './components/matriz/matriz.component';
import { GanttComponent } from './components/gantt/gantt.component';
import { NuevaDirectivaComponent } from './components/nuevaDirectiva/nuevaDirectiva.component';
import { MultiDirectivaComponent } from './components/multi-directiva/multi-directiva.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { FinancialReportComponent } from './components/financial-report/financial-report.component';
import { PaymentOrderHistoryComponent } from './components/payment-order-history/paymentOrderHistory.component';
import { UploadPaymentOrderComponent } from './components/upload-payment-order/upload-payment-order.component';
import { EditPaymentOrderComponent } from './components/payment-order/edit-payment-order/EditPaymentOrder.component';
import { CreatePaymentOrderComponent } from './components/payment-order/create-payment-order/CreatePaymentOrder.component';
import { InstrumentsComponent } from './components/selfAssessment/instruments/instruments.component';
import { EvaluateComponent } from './components/selfAssessment/evaluate/evaluate.component';
import { GeneralResultsComponent } from './components/selfAssessment/general-results/general-results.component';
import { SelfEvaluationComponent } from './components/self-evaluation/self-evaluation.component';
import { SelfEvaluationJudgeComponent } from './components/self-evaluation-judge/self-evaluation-judge.component';
import { NewProcessComponent } from './components/selfAssessment/new-process/new-process.component';
import { EditProcessComponent } from './components/selfAssessment/edit-process/edit-process.component';
import { RoleManagerComponent } from './components/role-manager/role-manager.component';

const routes: Routes = [
  
  { path: 'dashboard', component: DirectivasComponent },

  { path: 'dashboard/role-manager', component: RoleManagerComponent, },

  { path: 'dashboard/matriz', component: MatrizComponent },
  { path: 'dashboard/matriz/:id', component: MatrizComponent },
  { path: 'dashboard/gantt', component: GanttComponent },
  { path: 'dashboard/nueva', component: NuevaDirectivaComponent },
  { path: 'dashboard/multi', component: MultiDirectivaComponent },
  { path: 'dashboard/userList', component: UsersListComponent },
  { path: 'dashboard/financialReport', component: FinancialReportComponent },
  { path: 'dashboard/paymentOrder', component: CreatePaymentOrderComponent },
  { path: 'dashboard/paymentOrder/edit/:id', component: EditPaymentOrderComponent, },
  { path: 'dashboard/paymentOrderHistory', component: PaymentOrderHistoryComponent, },

  { path: 'dashboard/uploadPaymentOrder', component: UploadPaymentOrderComponent, },

  { path: 'dashboard/:process', component: InstrumentsComponent },
  { path: 'dashboard/new-process/:process', component: NewProcessComponent, },
  { path: 'dashboard/edit-process/:process/:id', component: EditProcessComponent, },
  { path: 'dashboard/general-results/:process/:id', component: GeneralResultsComponent, },
  
  { path: 'dashboard/evaluating/:id/:type', component: EvaluateComponent, },
  // { path: 'dashboard/evaluating', component: EvaluateComponent, },
  // { path: 'dashboard/selfEvaluating/:id/:type', component: SelfEvaluationComponent, },
  { path: 'dashboard/selfEvaluating/user/:id', component: SelfEvaluationComponent, },
  { path: 'dashboard/selfEvaluating/judge/:id', component: SelfEvaluationJudgeComponent, },

  { path: '**', component: EmptyRouteComponent },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes, { relativeLinkResolution: 'legacy'} ) ],
  exports: [ RouterModule ],
  providers: [ { provide: APP_BASE_HREF, useValue: '/' } ],
} )
export class AppRoutingModule { }