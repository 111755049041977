export enum SectionToShowEnum {
  filesSection,
  generalSection,
  publishSection
}

export enum SectionNameEnum {
  selfAssessment = 'Factores', 
  accreditation = 'Condición',
  calls = 'Módulo'
}