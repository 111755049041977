import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { take } from 'rxjs/operators';
import { NotificationQueue } from 'src/app/models/notificationQueue';
import { CardIdService } from 'src/app/services/card-id.service';
import { NotificationQueuesService } from 'src/app/services/notificationQueues.service';

@Component({
  selector: 'directiva-date-expiry',
  templateUrl: './date-expiry.component.html',
  styleUrls: ['./date-expiry.component.css']
})
export class DateExpiryComponent implements ICellRendererAngularComp {
  public cardID: number;
  public userID: number;
  public CarnetFecVencimiento: string;

  public editData: boolean = false;
  public dataToUpdate: string;

  constructor(
    public _cardIdService: CardIdService,
    public _notificationQueuesService: NotificationQueuesService,
  ) { }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.initAllData(params);
  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    // this.cellValue = this.getValueToDisplay(params);
    console.log('refresh params',params);
    this.initAllData(params);
    return true;
  }

  initAllData(params: ICellRendererParams) {
    this.cardID = params.data.CarnetID;
    this.userID = params.data.id;
    this.CarnetFecVencimiento = params.data.CarnetFecVencimiento;
    this.dataToUpdate = params.data.CarnetFecVencimiento;
  }

  updateData() {
    if (this.dataToUpdate == undefined) return;
    console.log('dataToUpdate',this.dataToUpdate);
    //this.postNotificationQueue('Su Carné esta proximo a vencer');
    if (this.cardID) this.patchData();
    else this.postData();
  }
  patchData() {
    console.log('patch');
    let data = {
      dateExpiry: this.dataToUpdate
    }
    this._cardIdService.patchCardIdByUserID(this.cardID, data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetFecVencimiento = this.dataToUpdate;
    });
  }
  postData() {
    console.log('post');
    let data = {
      userID: this.userID,
      dateExpiry: this.dataToUpdate
    }
    this._cardIdService.postCardId(data).subscribe(response => {
      console.log('response',response);
      this.editData = false;
      this.CarnetFecVencimiento = this.dataToUpdate;
    });
  }

  postNotificationQueue(text: string): void {
    let newDate: Date = new Date(new Date(this.dataToUpdate).getTime() + 60*24*(-30)*60000); // Add -30 días
    let data: NotificationQueue = {
      //emailsTo: `["${this.requestData.requestUserRequester?.CedocEmail}"]`,
      emailsTo: `[""]`,
      namesTO: `[""]`,
      msg: `[{"message":"${text}"}]`,
      timeToSend: newDate,
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email'
    }
    this._notificationQueuesService.createNotificationQueue(data).pipe(take(1)).subscribe({
      next: data => {
        console.log('postNotificationQueue success', data);
      },
      error: err => {
        console.log('postNotificationQueue fail', err);
      }
    });
  }
}