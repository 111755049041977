import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldCardComponent } from '../field-card/field-card.component';

@Component( {
  selector: 'new-module',
  templateUrl: './new-module.component.html',
  styleUrls: [ './new-module.component.css' ]
} )
export class NewModuleComponent implements OnInit {

  constructor () {
    //
  }

  @ViewChild( 'fieldCardContainer', { read: ViewContainerRef } ) fieldCardContainer: ViewContainerRef;

  //sends a callback when delete button is clicked
  @Output( 'deleteModule' ) deleteModule: EventEmitter<number> = new EventEmitter();

  //receives the card id
  @Input( 'moduleId' ) moduleId: number = 0;

  //receives the module name
  @Input( 'sectionName' ) sectionName: string = '...';

  //stores the card module values
  public intCallModule: any = {};

  //stores a reference to the instances of the cardFields for later deleting them or getting the value of their fields
  private _fieldCardInstances: any[] = [];

  //creates a new id when creating a new cardField
  private _fieldCardInstanceId: number = 0;

  //If true, the validations will be activated
  public checkErrors: boolean = false;

  ngOnInit(): void {
    //
  }

  /**
  * This function adds a new FieldCardComponent to the view and handles its deletion when the user clicks on the delete button.
  */
  public addFieldCard() {
    this._fieldCardInstanceId++;
    const fieldCardInstance = this.fieldCardContainer.createComponent( FieldCardComponent );
    fieldCardInstance.setInput( 'fieldCarId', this._fieldCardInstanceId );

    this._fieldCardInstances.push( fieldCardInstance );

    //subscribes to the deleteCard event of the FieldCardComponent instance, which is emitted when the user clicks on the delete button.
    fieldCardInstance.instance.deleteField.subscribe( fieldCarId => {
      const cardToDelete = this._fieldCardInstances.find( e => e.instance.fieldCarId === fieldCarId );
      cardToDelete ? cardToDelete.destroy() : console.log( 'No card found with the given id' );
      this._fieldCardInstances = this._fieldCardInstances.filter( e => e.instance.fieldCarId !== fieldCarId );
    } )
  }

  /**
   * Gets the module data.
   * @returns An object containing the module information and the data from each field card instance.
   */
  public getModuleData() {
    // Check if int call module is valid
    const intcallValid = this.isIntcallValid();
    if ( Object.keys( intcallValid ).length > 0 )
      return intcallValid;

    // Get data from each field card instance
    const fieldsCardData = this._fieldCardInstances.map( e => e.instance.getIntcallData() );

    // Create object containing module information and field card data
    const moduleData = {
      moduleInfo: this.intCallModule,
      moduleFields: fieldsCardData
    };

    return moduleData;
  }

  /**
   * Validates the int call module data and returns an object with error flags.
   * @returns An object with error flags.
   */
  private isIntcallValid(): any {
    this.checkErrors = true;

    const { nameModule, percentageModule, descript } = this.intCallModule;
    const errors: any = {};

    // Check for missing or invalid name, percentage, or description fields
    if ( !nameModule || !percentageModule || !descript || percentageModule === 0 ) 
      errors.invalidIntcall = true;

    // Check for missing int call fields
    if ( this._fieldCardInstances.length === 0 ) {
      errors.noIntcallFields = true;
    } else {
      // Check if any int call field has undefined data
      const hasUndefinedData = this._fieldCardInstances.some( e => e.instance.getIntcallData() === undefined );
      if ( hasUndefinedData ) 
        errors.invalidIntcallFields = true;

      // Check for invalid int call weighting
      const isWeightingValid = this.isIntcallWeightingValid() === 100;
      if ( !isWeightingValid ) {
        errors.invalidWeighting = true;
      }
    }

    return errors;
  }

  /**
   * Checks if the int call weighting is valid.
   * @returns The sum of the percentages of all int call fields.
   */
  private isIntcallWeightingValid() {
    let weightingCounter = 0;

    // Add up the percentageField property of each field card instance's int call data
    for ( const fieldCard of this._fieldCardInstances ) {
      const fieldCardData = fieldCard.instance.getIntcallData();
      weightingCounter += fieldCardData?.percentageField || 0;
    }

    return weightingCounter;
  }
}
