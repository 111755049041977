import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';

@Component({
  selector: 'se-header-user-selected',
  templateUrl: './se-header-user-selected.component.html',
  styleUrls: ['./se-header-user-selected.component.css']
})
export class SeHeaderUserSelectedComponent implements OnInit {

  @Input() intcall: IntcallI;
  @Output('userSelectedEvent') userSelectedEvent: EventEmitter<number> = new EventEmitter();
  @Output('cancelView') cancelView: EventEmitter<number> = new EventEmitter();

  public showList: boolean = false;
  public userSelected: UserIntcallAnswer;
  public isUserSeleted: boolean = false;

  constructor() {/** */}

  ngOnInit(): void {
    /** */
  }

  evaluateUser(userSelected: UserIntcallAnswer): void {
    console.log('userIdSelected',userSelected);
    this.userSelected = userSelected
    this.isUserSeleted = true;
    this.showList = false;
    this.userSelectedEvent.emit( this.userSelected.userID );
  }
  cancelViewMethod(): void {
    this.isUserSeleted = false
    this.cancelView.emit( this.userSelected.userID );
  }

}
