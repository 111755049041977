import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debug, log } from 'console';

@Component( {
  selector: 'weighting-input',
  templateUrl: './weighting-input.component.html',
  styleUrls: [ './weighting-input.component.css' ]
} )
export class WeightingInputComponent implements OnInit {

  @Input('isInvalid') isInvalid: boolean = false;
  @Output( 'weightingValue' ) weightingValue: EventEmitter<number> = new EventEmitter();

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

  /**
  * Clamps the value of an input element between 0 and 100.
  *
  * @param event - The input event that triggered the function.
  */
  public clampValue( event ): void {
    const value = event.target.value;

    if(value > 100){
      event.target.value = 100;
    }else if(value < 1 ){
      event.target.value = 1;
    }
 
    this.weightingValue.emit( Number(event.target.value) );
  }
}


