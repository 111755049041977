import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { PieData } from 'src/app/models/pieData';


@Component({
  selector: 'pieChart-component',
  templateUrl: './pieChart.component.html',
  styleUrls: ['./pieChart.component.css', './pieChart.component.sass']
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() data: PieData;

  title: any;
  totalTemp: any;
  dataTemp = [];


  dataset: Chart.ChartDataSets[] = [
    {
      label: 'Dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 0.2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, 0],
    },
  ];
  pieChartLabels: Label[] = ['Capacitación A', 'Capacitación B'];
  charOptions: ChartOptions = {
    responsive: true,
    aspectRatio: 1,
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return '';
        },
      },
    },
    tooltips: {
      bodyFontSize: 12,
      displayColors: false,
      callbacks: {
        label: ((tooltipItem) => {
          let index = tooltipItem.index;
          let label = this.pieChartLabels[index];
          return label;
        })
      }
    }

  };
  typeChart: ChartType = 'pie';
  public pieChartPlugins = [pluginDataLabels];
  public pieChartLegend = false;
  // chartColors=["#ffba49","#20a39e","#ef5b5b","#ff88dc","#91a6ff","#edd4b2","#4d243d","#04724d","#c7aa74"]
  chartColors =
    [
      { backgroundColor: ["#ffba49", "#20a39e", "#ef5b5b", "#ff88dc", "#91a6ff", "#edd4b2", "#4d243d", "#04724d", "#c7aa74"], borderColor: '#FFF', borderWidth: 0 },

    ];
  total = 0;
  constructor(
    public sharedService: SharedService,
  ) {

    this.prepareData(this.data)

  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['data'])) {
      if (changes['data'].firstChange) {
        this.prepareData(changes['data'].currentValue)
      }
    }
  }
  ngOnInit(): void {
  }

  prepareData(data) {
    if (data) {
      this.dataset[0].data = []
      this.pieChartLabels = []
      this.dataTemp = [],
        this.totalTemp = 0;
      this.title = data.title
      data.data.forEach(element => {
        // console.log("element",element)
        let value = element.value ? element.value : 0;
        this.totalTemp += value
        this.dataset[0].data.push(value)
        this.pieChartLabels.push(element.name)
        this.dataTemp.push({ name: element.name, value: value })
      })
    }
  }

  onCilckLavel(data){
    this.sharedService.nextcategoryNameFilter({filterName:"TypeCourse",value:data})
  
  }

  onCilckTitle(data){
    this.sharedService.nextcategoryNameFilter({filterName:"Category",value:data})
  }

}
