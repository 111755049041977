import { Component, Input, OnInit } from '@angular/core';
import { CommentsIntcallFieldAnswer } from 'src/app/models/selfAssessment/commentsIntcallFieldAnswer';

@Component({
  selector: 'se-question-form-section-component',
  templateUrl: './se-question-form-section-component.component.html',
  styleUrls: ['./se-question-form-section-component.component.css']
})
export class SeQuestionFormSectionComponentComponent implements OnInit {

  @Input() CommentsIntcallFieldAnswer: CommentsIntcallFieldAnswer;
  constructor() {/** */}

  ngOnInit(): void {
    /** */
  }

}
