import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IntcallI } from 'src/app/models/selfAssessment/intCall';
import { UserIntcallAnswer } from 'src/app/models/selfAssessment/userIntcallAnswer';
import { UserIntcallFieldDocuments } from 'src/app/models/selfAssessment/userIntcallFieldDocuments';
import { AuthService } from 'src/app/services/auth.service';
import { SelfAssessmentService } from 'src/app/services/selfAssessment/self-assessment.service';
import * as allEnums from '../../models/allTypes/types';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'directiva-self-evaluation',
  templateUrl: './self-evaluation.component.html',
  styleUrls: ['./self-evaluation.component.css']
})
export class SelfEvaluationComponent implements OnInit {
  /** ****************************** View Data ****************************** */
  readonly ALL_TYPES_OF_VIEW = allEnums.enumTypeOfView;
  public showData: boolean = false;
  public dataLoaded: boolean = false;
  public disabledEdit: boolean = false;
  public allBtnViewSelected = allEnums.enumTypeGeneralView;
  public btnViewSelected = this.allBtnViewSelected.formulario;
  /** ****************************** ********** ****************************** */
  public loguedUserID: number;
  public intcall: IntcallI;
  public myUserIntcallAnswer: UserIntcallAnswer;
  public showModalSave: boolean = false;
  public showModalFiles: boolean = false;
  private subscriptionSavePartialData: Subscription;
  private subscriptionSaveFinishData: Subscription;
  public containerCertsName: string = environment.containerCertsName;

  constructor(
    public _selfAssessmentService: SelfAssessmentService,
    public _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    public _router: Router,
  ) {
    const LOGUED_USER_ID = this._authService.getCurrentUserID();
    console.log('LOGUED_USER_ID',LOGUED_USER_ID);
    if (LOGUED_USER_ID == null) this._router.navigate(['/register']);
    else this.loguedUserID = +LOGUED_USER_ID;
    this.subscriptionSaveDataMethod();
    this.initializeData();
  }

  ngOnInit(): void {
    /** */
  }

  ngOnDestroy() {
    this.subscriptionSavePartialData.unsubscribe();
    this.subscriptionSaveFinishData.unsubscribe();
  }

  subscriptionSaveDataMethod():void {
    this.subscriptionSavePartialData = this._selfAssessmentService.sharedSaveEvent.subscribe(data => {
      if (data) this.patchUserIntcallAnswer(false);
    });
    this.subscriptionSaveFinishData = this._selfAssessmentService.sharedSaveFinishEvent.subscribe(data => {
      if (data) this.patchUserIntcallAnswer(true);
    });
  }

  initializeData(): void {
    this._activatedRoute.params.subscribe(params => {
      if (params["id"]) {
        const idIntcall = params["id"];
        this.getAllIntcallData(idIntcall);
      } else this._router.navigate(['/user/my-account']);
    });
  }

  getAllIntcallData(inIncall:number): void {
    this._selfAssessmentService.getIntcallById(inIncall).pipe(take(1)).subscribe(
      data => {
        console.log('getAllIntcallData:',data);
        this.intcall = data;
        this.intcall.tagsDecoded = JSON.parse(this.intcall.tags);
        this.getUserAnswers();
      }, err => {
        console.log('getAllIntcallData err:',err);
      }
    )
  }

  getUserAnswers(): void {
    this.intcall.userIntcallAnswers.forEach(element => {
      if (element.userID == this.loguedUserID) {
        this.myUserIntcallAnswer = element;
        if (this.myUserIntcallAnswer.isFinalAnswer) this.disabledEdit = true;
        this.showFormData();
      }
    });
    this.showDataLoaded();
  }

  showFormData(): void {
    this.showData = true;
  }

  showDataLoaded(): void {
    this.dataLoaded = true;
  }

  patchUserIntcallAnswer(isFinished: boolean): void {
    let data = {
      id: this.myUserIntcallAnswer.id,
      updatedAt: new Date()
    }
    if (isFinished) data['isFinalAnswer'] = true;
    this._selfAssessmentService.patchUserIntcallAnswer(data).pipe(take(1)).subscribe(
      data => {
        console.log('patchUserIntcallAnswer:',data);
        this.showModalSave = true;
      }, err => {
        console.log('patchUserIntcallAnswer err:',err);
      }
    )
  }
  
  userParticipate(data): void {
    window.location.reload();
  }

  showModalFilesMethod() {
    this.showModalFiles = true;
  }

  changeViewNavLeft(view): void {
    this.btnViewSelected = view;
  }

  /** TODO: Revisar */
  public fileUrl: string = '';
  public nameFile: string = '';
  public descript: string = '';
  onFileInput( event: any ) {
    console.log('event',event);
    
    this.fileUrl = event.urlFile;
    this.nameFile = event.nameFile;
    this.descript = event.descript;
    this.getDataFromUploadFileComponent();
  }

  public fileReqTemp: UserIntcallFieldDocuments;
  getDataFromUploadFileComponent(): void {
    let data = {
      intcallID: this.myUserIntcallAnswer.intcallID,
      UserIntcallAnswerID: this.intcall.userIntcallAnswers[0].id,
      documentLink: this.fileUrl,
      name: this.nameFile,
      description: this.descript,
    }
    this.createUserIntcallFieldDocuments(data);
  }
  createUserIntcallFieldDocuments(data: object): void {
    console.log('data',data);
    
    this._selfAssessmentService.postUserIntcallFieldDocuments(data).pipe(take(1)).subscribe(
      data => {
        console.log('createUserIntcallFieldDocuments success', data);
      },
      err => {
        console.log('createUserIntcallFieldDocuments fail', err);
      }
    );
  }

  /**
   * Obtiene una URL completa para descargar un archivo PDF específico.
   *
   * @param urlComplement - Una cadena que representa la URL base o parcial.
   * @returns Una cadena que representa la URL completa del archivo PDF.
   */
  getCompleteUrlFile(urlComplement: string = ''): string {
    let url = `${environment.fileBaseUrl}/${urlComplement}`;
    return url;
  }

}
