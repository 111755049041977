import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SelfAssessmentAlertsService {

  constructor() {
    //
  }

  public swalError( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'error',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    });
  }

  public success( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'success',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    })
  }

  public swalLoading( title?: string, message?: string ) {
    Swal.fire( {
      title: title,
      text: message,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } );
    Swal.showLoading()
  }

  public swalWarning( title?: string, message?: string, callback?: any ) {
    Swal.fire( {
      icon: 'warning',
      title: title,
      text: message,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
    } ).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
      }
    })
  }

  public swalClose( ) {
    Swal.close()
  }
}
